import { useEffect, useState } from "react";
import { IoCamera } from "react-icons/io5";
import api from "../../../config/connection/axios";
import Avatar from "../Avatar";
import EasyCrop from "../EasyCrop";
import Modal from "../../Modal";
import ImageChangedMessage from "../../Containers/RegistrationContainer/layouts/ImageChangedMessage";
import RegCheck from "../../Containers/RegistrationContainer/layouts/RegCheck";
//import Spinner from "../../../Spinner";
import './styles.css'


function RegAvatar({ height = '80px', width = '80px', hide = false, content }: { height?: string, width?: string, hide?: boolean, content?: any }) {

    const [avatar, setAvatar] = useState<string>('')
    const [visible, setVisible] = useState<boolean>(false);
    const [spinner, setSpinner] = useState<boolean>(false);
    const [sent, setSent] = useState<boolean>(false);
    const [removed, setRemoved] = useState<boolean>(false);
    const [showMessage, setShowMessage] = useState<boolean>(false);

    const setFile = (e: any) => {
        setAvatar(URL.createObjectURL(e.target.files[0]))
        setVisible(true)
    }

    const clearFile = (e: any) => {
        e.target.value = null
    }

    const handleSubmit = async (croppedImage: any, alertfy: (text: string) => void) => {

        setAvatar(croppedImage)

        setSpinner(true)

        const u = JSON.parse(window.localStorage.getItem('serss-user') || '{}')

        let blob = await fetch(croppedImage).then(r => r.blob());

        var formData = new FormData();
        {/*@ts-ignore*/ }
        formData.append('uploadedAvatar', blob, `${avatar}`)

        api.post(
            '/firebase/avatar/update',
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${u.token}`
                }
            }
        )
            .then(() => {
                setVisible(false)
                setSpinner(false)
                setSent(true)
                setShowMessage(true)
            })
            .catch(() => {
                setVisible(false)
                setSpinner(false)
                alertfy('Falha ao alterar foto, por favor tente novamente mais tarde')
            })

    }

    useEffect(() => { }, [avatar])

    const handleRemoveAvatar = async (e: Event) => {

        e.preventDefault();

        if (removed) return
        else {
            const u = JSON.parse(window.localStorage.getItem('serss-user') || '{}')
            await api.get(
                '/firebase/avatar/delete',
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${u.token}`
                    }
                }
            )
                .then(() => {
                    setVisible(false)
                    setSpinner(false)
                    setRemoved(true)
                    setSent(true)
                    setShowMessage(true)
                    setAvatar('')
                })
                .catch(() => {
                    setVisible(false)
                    setRemoved(true)
                    setAvatar('')
                    setSpinner(false)
                })
        }
    }

    return (
        <form style={{ position: 'relative' }}>
            <div style={{ display: 'flex', height, alignItems: 'flex-end' }}>
                <div style={{ width: 'fit-content' }}>
                    <div className='center' style={{ width, height, position: 'relative' }}>
                        {(avatar.length && sent)
                            ? (
                                <ShowRegAvatar
                                    width={width}
                                    height={height}
                                    avatar={avatar}
                                />
                            )
                            : (
                                <Avatar
                                    borderRadius='30px'
                                    size={30}
                                    uid={JSON.parse(window.localStorage.getItem('serss-user') || '{}').uid}
                                />
                            )

                        }

                        {
                            <div className="reg-avatar-hover-cam reg-avatar-hover-cam-wrap">
                                <OpenFiles
                                    className='reg-avatar-hover-cam'
                                    setFile={setFile}
                                    clearFile={clearFile}
                                />
                                <div className="reg-avatar-hover-cam-bottom center">
                                    <IoCamera
                                        size={20}
                                        color='white'
                                    />
                                </div>
                            </div>
                        }

                    </div>
                </div>
                {hide
                    ? (<></>)
                    : (
                        <div style={{ marginLeft: '30px' }}>
                            <OpenFiles
                                setFile={setFile}
                                clearFile={clearFile}
                                children={
                                    content
                                        ? content
                                        : <p className='reg-avatar-pick-image'>Alterar foto de perfil</p>
                                }
                            />
                            <p className="reg-avatar-remove-image" onClick={(e: any) => handleRemoveAvatar(e)}>Remover</p>
                        </div>
                    )
                }
            </div>
            <Modal
                visible={visible}
                setVisible={setVisible}
                padding={'0'}
                closeBtn={false}
                children={
                    <EasyCrop
                        spinner={spinner}
                        avatar={avatar}
                        setVisible={setVisible}
                        handleSubmit={handleSubmit}
                    />
                }
            />
            {(sent && !hide) && (
                <div style={{ position: 'absolute', right: '-26px', bottom: '-26px' }}>
                    <RegCheck />
                </div>
            )}
            {showMessage &&
                <ImageChangedMessage
                    visible={showMessage}
                    setVisible={setShowMessage}
                    avatar={<ShowRegAvatar width="180px" height="180px" avatar={avatar} />}
                />
            }
        </form>
    )
}
const ShowRegAvatar = ({ width = '80px', height = '80px', avatar = '' }) => (
    <div className='center' style={{ width, height }}>
        <img
            src={avatar}
            className='center'
            style={{
                width,
                height,
                borderRadius: '20px'
            }}
        />
    </div>
)

const OpenFiles = (
    { setFile,
        clearFile,
        children,
        className = ''
    }:
        {
            setFile: (e: any) => void,
            clearFile: (e: any) => void,
            children?: JSX.Element,
            className?: string
        }
) => (
    <label className={className}>
        <input
            style={{ opacity: 0 }}
            multiple={false}
            type="file"
            accept="image/*"
            onChange={setFile}
            onClick={clearFile}
        />
        {children}
    </label>
)

export default RegAvatar