import ThinHeader from "../components/Headers/ThinHeader"
import NavigationBar from "../components/mobile/NavigationBar"
import SafeDiv from "../components/SafeDiv"
import CatList from "../components/SideBar/layouts/CatList"


function Categories() {
    window.scrollTo(0, 0);
    return (
        <>
            <ThinHeader />
            <SafeDiv>
                <CatList type='categories' />
                <div style={{ height: '40px' }} />
            </SafeDiv>
            <NavigationBar />
        </>
    )
}

export default Categories