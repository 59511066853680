import { useState } from "react";

import { Header_ } from "../headers.interfaces";
import HeaderWithoutChat from "../HeaderWithoutChat";
import ModalChat from "../../modals/ModalChat";
import isMobile from "../../../functions/isMobile";
import { useNavigate } from "react-router-dom";


function Header({
    home = false,
    switcher,
    setSwitcher
}: Header_) {
    const [chat, setChat] = useState<boolean>(false);
    const navigate = useNavigate();
    const handleSetChat = (boo: boolean) => {
        return isMobile()
          ? navigate('/chat')
          : setChat(boo)
    
      }
    return (
        <>
            <HeaderWithoutChat
                setChat={handleSetChat}
                Chat={chat &&
                    <ModalChat
                        visible={chat}
                        setVisible={setChat}
                    />
                }
                home={home}
                switcher={switcher}
                setSwitcher={setSwitcher}
            />
        </>
    )
}

export default Header