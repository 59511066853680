import { useState } from "react";
import RegAvatar from "../../../../Images/RegAvatar/RegAvatar";
import Modal from "../../../../Modal";
import './styles.css';
import NextButton from "../../../../Buttons/NextButton";
import Album from "../../../../Album";

function ImageChangedMessage(
    {
        visible,
        setVisible,
        premium,
        banners,
        addBanner,
        removeBanner
    }: {
        visible: boolean,
        setVisible: any,
        premium: Date | null,
        banners: any,
        addBanner: any,
        removeBanner: any
    }
) {

    const [idx, setIdx] = useState<number>(0);
    const handleClick = () => {
        idx < 1 && setIdx(idx + 1);
        idx === 1 && setVisible(false)
    }

    const text = [
        {
            strong: 'Foto de perfil',
            p: <p>Adcione uma foto <strong>real</strong> sua ou da sua marca para que seus clientes possam te conhecer.</p>
        },
        {
            strong: 'Imagens do Serviço',
            p: <p>Adicione imagens da  <strong>execução</strong> do seu serviço.</p>
        }
    ]

    return (
        <Modal
            visible={visible}
            setVisible={setVisible}
            closeBtn={true}
            padding={'50px  60px'}
            children={
                <div
                    style={{
                        textAlign: 'center',
                        maxWidth: '400px',
                        padding: '0 40px',
                        maxHeight: '480px'
                    }}
                    className='center column'
                >
                    {
                        idx === 0 && <RegAvatar
                            hide
                            width="200px"
                            height="200px"
                        />
                    }
                    {
                        idx === 1 && <Album
                            premium={premium}
                            banners={banners}
                            addBanner={addBanner}
                            removeBanner={removeBanner}
                        />
                    }
                    <strong style={{ marginTop: '20px', fontSize: '1.2em' }}>{text[idx].strong}</strong>
                    <p style={{ color: '#00000090', margin: '0 0 10px 0' }}>{text[idx].p}</p>
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                        <NextButton
                            onClick={handleClick}
                            done={idx === 1 ? true : false}
                        />
                    </div>
                </div>
            }
        />
    )
}

export default ImageChangedMessage