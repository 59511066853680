import { useState } from 'react'
import { IoBookmark, IoDiamond, IoIdCard, IoStar, IoVideocam } from 'react-icons/io5';
import { Link } from 'react-router-dom';
import './styles.css';

interface Bookmarkers_ {
    remote: any,
    partner: any,
    verified: any,
    premium: any,

    user_name: string | undefined,

    profile?: boolean
}

function Bookmarkers({ remote, partner, verified, premium, user_name, profile = false }: Bookmarkers_) {

    const [showFull, setShowFull] = useState<0 | 1 | 2 | 3 | 4>(0);

    return (
        <ul id={profile ? 'bookmarked-no-point' : ''} className='bookmarked-grid' style={{ position: 'relative' }}>
            <div className={`bookmarked-absolute bookmarked-absolute--${partner ? 'partner' : 'main'}`} />
            {
                remote && (
                    <BookMarked
                        icon={<IoVideocam size={14} color='white' />}
                        color='#696977'
                        title='Remoto'
                        text={<p><strong>Serviço remoto:</strong> Pode ser realizado por videochamada.</p>}
                        showFull={showFull}
                        setShowFull={setShowFull}
                        fullN={1}
                    />
                )
            }

            {
                (partner && !(verified && remote && premium)) && (
                    <BookMarked
                        icon={<IoStar size={14} color='white' />}
                        color='#00ae74'
                        title='Parceiro'
                        text={<p><strong>Profissional parceiro: </strong> <u>{user_name}</u> apoia a comunidade.</p>}
                        showFull={showFull}
                        setShowFull={setShowFull}
                        fullN={2}
                    />
                )
            }
            {
                verified && (
                    <BookMarked
                        icon={<IoIdCard size={14} color='white' />}
                        color='dodgerblue'
                        title='Verificado'
                        text={<p><strong>Perfil verificado: </strong>Informou RG, CPF e comprovante de residência.</p>}
                        showFull={showFull}
                        setShowFull={setShowFull}
                        fullN={3}
                    />
                )
            }
            {
                premium && (
                    <Link to='/subscription'>
                        <BookMarked
                            icon={<IoDiamond size={14} color='white' />}
                            color='#222D36'
                            title='Premium'
                            text={<p><strong>Perfil Premium: </strong><u>{user_name}</u> aderiu às vantagens de ser Premium.</p>}
                            showFull={showFull}
                            setShowFull={setShowFull}
                            fullN={4}
                        />
                    </Link>
                )
            }
        </ul>
    )
}

const BookMarked = (
    { title, text, icon, color, showFull, setShowFull, fullN }:
        {
            title: string,
            text: JSX.Element,
            icon: any,
            color: string,
            showFull: 0 | 1 | 2 | 3 | 4,
            setShowFull: any,
            fullN: 0 | 1 | 2 | 3 | 4,
        }
) => {

    return (<li onClick={() => {
        if (window.innerWidth > 790) return;
        if (showFull === fullN) setShowFull(0)
        else setShowFull(fullN);
    }}>
        <div className='bookmarked bookmarked-column center'>
            <span className='center'>
                <span
                    className='center'
                    style={{ position: 'absolute', paddingTop: '8px' }}

                >
                    <IoBookmark size={38} color={color} />
                </span>
                <span
                    className='center'
                    style={{ position: 'absolute' }}

                >
                    {icon}
                </span>
            </span>
            <span className='bookmarked-title'>
                {title}
            </span>
        </div>
        <div className='bookmarked-text'>
            <p
                style={{

                    color: 'rgba(74, 74, 74, 1)',
                    fontSize: '15px'
                }}
            >
                {text}
            </p>
        </div>
        {(showFull === fullN) && <div className='bookmarked-full-text'>
            {text}
        </div>}
    </li>)
}

export default Bookmarkers