import Spinner from "../../../Spinner";
import { Select_ } from "../inputs.interfaces";

import '../styles.css';

export default function Select({ label, list, space, value, onChange, disabled, style, spinner, placeholder, required }: Select_) {
    return (
        <label
            style={{
                marginTop: `${space !== undefined ? space : 18}px`,
            }}
            className='Label'
        >
            <p>{label}&nbsp;
                {required && <strong style={{ color: 'tomato' }}>*</strong>}
            </p>
            <select
                disabled={disabled}
                value={value}
                onChange={(e) => onChange && onChange(e.target.value)}
                className={`Select ${disabled && 'untouchable'}`}
                style={{
                    borderRadius: '8px',
                    height: '44px',
                    backgroundColor: '#fff',
                    textIndent: '13px',
                    border: '1px solid #c9c9c9',
                    cursor: 'pointer',
                    ...style,
                    color: list && placeholder === value ? '#757575' : 'black'
                }}
            >
                {(list !== null && list?.length) &&
                    (
                        list.map((element: any, idx: number) => (
                            <option
                                key={idx}
                                value={element}
                            >
                                {element}
                            </option>
                        ))
                    )

                }
            </select>
            {(disabled && spinner) &&
                <div
                    className='center'
                    style={{
                        position: 'absolute',
                        width: '100%',
                        height: '42px',
                        bottom: 0,
                        left: 0
                    }}>
                    <Spinner
                        color={'black'}
                        size={10}
                        visible={spinner}
                        border={2}
                    />
                </div>
            }
        </label>
    );
}