import ServiceOrderContainer from "../components/Containers/ServiceOrderContainer";
import ThinHeader from "../components/Headers/ThinHeader";

function ServiceOrder() {
  return (
    <>
      <ThinHeader />
      <ServiceOrderContainer />
    </>
  )
}

export default ServiceOrder