const Corner = ({ direction }: { direction: 'left' | 'right' }) => (
    <div
        className='corner'
        style={{
            height: '40px',
            width: '40px',
            backgroundColor: 'white',
            position: 'absolute',
            right: direction === 'right' ? '-40px' : 'auto',
            left: direction === 'left' ? '-40px' : 'auto',
            bottom: '20px'
        }}
    >
        <div
            style={{
                height: '40px',
                width: '40px',
                backgroundColor: '#d1d0d6',
                borderRadius: direction === 'left'
                    ? '0 0 20px 0'
                    : '0 0 0 20px'
            }}
        />
    </div>
)

export default Corner;