import { IoCalendarOutline, IoChatboxOutline, IoLocationOutline, IoPersonOutline } from "react-icons/io5"
import Input from "../../../Form/Inputs/layouts/Input"
import { TextArea } from "../../../Form/Inputs/layouts/TextArea"
import { useState } from "react"
import { useLocation, useNavigate } from "react-router-dom";
import { SocList_ } from "../interface";
import api from "../../../../config/connection/axios";

function SocServiceOrdersListResponse() {

  const location = useLocation();
  const state: any = location.state;
  const list: SocList_ = state.list;

  const [price, setPrice] = useState<number>()
  const [response, setResponse] = useState<string>('')
  const [pixKey, setPixKey] = useState<string>()

  const navigate = useNavigate();

  const handleSOResponseSubmit = (e: Event) => {

    if (!price || price < 15) return alert('Preço deve ser no mínimo R$ 15,00')

    const u = JSON.parse(window.localStorage.getItem('serss-user') || '{}')

    api.post(
      '/service/orders/response',
      {
        so_unique_id: list.so_unique_id,
        price: price,
        pix: pixKey,
        ser_response: response

      },
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${u.token}`
        }
      }
    )
      .then(() => navigate('/service_orders/list'))
      .catch(() => { })

  }

  return (
    <div className="center column">
      <div className="card156" style={{ width: '90%', padding: '20px 0' }}>
        <div className="center">
          <div style={{ width: 'calc(100% - 20px)' }}>
            <div className='soc-w-bottom soc-w-bottom--bigger-font' style={{ marginTop: '0', paddingTop: '5px', borderTop: 'none', flexDirection: 'column' }}>
              <div style={{ display: 'flex' }}>
                <span className="center" style={{ marginRight: '4px' }}>
                  <IoPersonOutline />
                </span>
                <p>{list.client_name}</p>
              </div>
            </div>
            <div className='soc-w-bottom soc-w-bottom--bigger-font' style={{ marginTop: '14px', paddingTop: '14px' }}>
              <div className="center">
                <span className="center" style={{ marginRight: '4px' }}>
                  <IoCalendarOutline />
                </span>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <div style={{ display: 'flex' }}>
                    <p>{list.date_week}</p>
                    <p>{list.date_day}</p>
                  </div>
                  <p style={{ fontWeight: 'bold' }}><i>{list.date_hours}</i></p>
                </div>
              </div>
            </div>
            <div className='soc-w-bottom soc-w-bottom--bigger-font' style={{ marginTop: '14px', paddingTop: '14px' }}>
              <div className="center">
                <span className="center" style={{ marginRight: '4px' }}>
                  <IoLocationOutline />
                </span>
                <div style={{ display: 'flex', flexDirection: 'column' }}>

                  <p>{list.loc_place}</p>

                  <p style={{ fontWeight: 'bold' }}><i>{list.loc_location}</i></p>
                </div>
              </div>
            </div>
            <div className='soc-w-bottom soc-w-bottom--bigger-font' style={{ marginTop: '14px', paddingTop: '14px', width: '100%', flexDirection: 'column' }}>
              <div style={{ width: '100%' }}>
                <div style={{ width: '100%', display: 'flex', marginBottom: '18px' }}>
                  <div className="center">
                    <span className="center" style={{ marginRight: '4px' }}>
                      <IoChatboxOutline />
                    </span>
                    <p>Descrição do serviço:</p>
                  </div>
                </div>
                <div style={{ marginTop: '10px', backgroundColor: '#00000005', borderRadius: '8px', padding: '0', display: 'flex', alignItems: 'center', flexDirection: 'column', border: '1px solid var(--border)', overflow: 'hidden' }}>
                  <div style={{ padding: '10px' }}>
                    <p style={{ textAlign: 'start' }}>
                      {list.ser_description}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      {!list.price && <div style={{ width: '90%' }}>
        <div style={{ margin: '30px 0 0 0' }}>
          <span className="center">

          </span>
          <strong style={{ color: 'var(--dark)', fontSize: '20px' }}>
            Fazer orçamento:
          </strong>
        </div>
        <div>
          <form>
            <Input
              required
              label="Sua chave PIX para receber o pagamento"
              onChange={setPixKey}
              value={pixKey}
              placeholder="Informe sua chave PIX para receber o pagamento"
            />

            <Input
              required
              label="Valor do serviço"
              onChange={setPrice}
              value={price}
              type="number"
              placeholder="Mínimo R$ 15,00"
            />
            <TextArea
              label="Reposta explicativa"
              onChange={setResponse}
              value={response}
              placeholder="Reposta explicando as condições para executar o serviço."
            />
          </form>
        </div>
      </div>}

      <div style={{ height: '10px' }} />

      <div className='soc-pro-btn'>
        <div className='center'>
          {!list.price
            ? <button onClick={(e: Event | any) => handleSOResponseSubmit(e)} type="submit">
              Finalizar orçamento
            </button>
            : <button onClick={() => navigate(-1)} type="submit">
              Voltar
            </button>}
        </div>
      </div>
    </div>

  )
}

export default SocServiceOrdersListResponse