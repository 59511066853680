import GoogleAd from '../GoogleAd';
import '../styles.css';

function AdsSearchRight_Wild() {
    return (
        <div
            className="AdsSearchRight"
            style={{
                width: '11.5vw',
                height: 'calc(62vh + 2px)',
                position: 'fixed',
                right: '1vw',
                top: '110px'
            }}
        >
            <GoogleAd googleAdId="ca-pub-1196789424751315" slot='4250039141' />
        </div>
    )
}

export default AdsSearchRight_Wild;