import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import SearchContainer from '../components/Containers/SearchContainer';
import Header from '../components/Headers/Header';
import NavigationBar from '../components/mobile/NavigationBar';
import Page from '../components/Page';

function Search() {
  window.scrollTo(0, 0);

  const [searchParams] = useSearchParams();
  const search = searchParams.get('search');

  const [switcher, setSwitcher] = useState<boolean>(false);

  const [seed, setSeed] = useState(1);

  useEffect(() => {
    const reset = () => {
      setSeed(Math.random());
    }
    reset()
  }, [window.localStorage.getItem('serss-location'), switcher, search])

  return (
    <Page
      title={
        search
          ? '"' + search?.toString().charAt(0).toUpperCase() + search?.toString().slice(1) + '"' + ' na sua região - Servicess'
          : 'Melhores profissionais da sua região - Servicess'
      }
    >
      <>
        <Header
          switcher={switcher}
          setSwitcher={setSwitcher}
        />
        <SearchContainer
          key={seed}
          search={search || ''}
          switcher={switcher}
          setSwitcher={setSwitcher}
        />
        <NavigationBar />
      </>
    </Page>
  )
}

export default Search