import { useEffect, useState } from 'react'
import { IoPersonCircleOutline } from 'react-icons/io5'
import { useNavigate } from 'react-router-dom';
import api from '../../config/connection/axios';
import ContactUsCannot from '../ContactUs/ContactUsConnot';
import ContactUsOnError from '../ContactUs/ContactUsOnError';
import FormButton from '../Form/Inputs/layouts/FormButton';
import Modal from '../Modal';

import './styles.css'


function ValidationCode({ data }: any) {

  const navigate = useNavigate();

  const [blink, makeBlink] = useState('blink_me')
  const [code, setCode] = useState('')
  const [spinner, setSpinner] = useState<boolean>(false);
  const [haveError, sethaveError] = useState<string | boolean>(false);

  const [a, setA] = useState('')
  const [b, setB] = useState('')
  const [c, setC] = useState('')
  const [d, setD] = useState('')

  useEffect(
    () => {
      makeBlink(code.length === 0 ? 'blink_me' : '')
      setA(code.slice(0, 1))
      setB(code.slice(1, 2))
      setC(code.slice(2, 3))
      setD(code.slice(3, 4))
    },
    [code]
  );

  const handleSubmit = async (e: any, alertfy: any) => {

    setSpinner(true)

    await api.post(
      '/users/create',
      { ...data, tocompare: code.slice(0, 4).trim() }
    )
      .then(r => {
        const result = r.data;
        window.localStorage.setItem('serss-user', JSON.stringify(result))
        navigate('/')
      })
      .catch((err: any) => {
        setSpinner(false)
        // Se tiver retorno de erro pelo server:
        if (
          err.response &&
          err.response.data
        ) {
          // Se esse retorno for que já existe um usuário com esse email,
          // manda para a tela de login:
          if (err.response.data.err && err.response.data.err === 'err_email409')
            navigate('/login')
          // Se não exibe a mensagem definida pelo server:
          else if (typeof err.response.data.message === 'string')
            alertfy(err.response.data.message)
        }
        else sethaveError(err.response.data);
      })
  }

  return (
    <div id='ValidationCode'>
      <Modal
        visible={Boolean(haveError)}
        setVisible={sethaveError}
        children={<ContactUsOnError />}
      />
      {(data && data.email) && <div className='ValidC-email'>
        <div>
          <IoPersonCircleOutline />
        </div>
        {data.email}
      </div>}
      <span>Digite o código que enviamos por e-mail</span>
      <p>Enviamos um código no seu e-mail para que possa acessar sua conta. Caso não o encontre, verifique a caixa de spam.</p>


      <form id='MagicInput'>
        <input
          value={code}
          onChange={e => setCode(e.target.value.toUpperCase())}
          className='MagicI-protect'
          maxLength={4}
          autoFocus
          spellCheck={false}
          onFocus={() => { if (code.length === 0) makeBlink('blink_me') }}
          onBlur={() => makeBlink('')}
        />

        <input
          defaultValue={a}
          className={`MagicI ${blink}`}
          placeholder=" "
        />
        <input
          defaultValue={b}
          className='MagicI'
          placeholder=" "
        />
        <input
          defaultValue={c}
          className='MagicI'
          placeholder=" "
        />
        <input
          defaultValue={d}
          className='MagicI'
          placeholder=" "
        />
      </form>
      <ContactUsCannot>
        <FormButton
          style={{ backgroundColor: '#00ae74', border: '1px solid #00ae74' }}
          text='Validar'
          onClick={handleSubmit}
          spinner={spinner}
        //untouchable={code.length >= 4 ? false : true}
        />
      </ContactUsCannot>
    </div>
  )
}

export default ValidationCode