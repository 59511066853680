import { Link } from 'react-router-dom'
import './styles.css'

function MiniBanners() {
    return (
        <div id='MiniBanners'>
            <Link to={window.localStorage.getItem('serss-user') ? '/work/create' : '/login'}>
                <img

                    src={require('../../assets/moneyupf.png')} alt='Card para adicionar conteudo'
                />
            </Link>
            <Link to={window.localStorage.getItem('serss-user') ? '/' : '/signin'} target='_blank'>
                <img
                    style={{ marginLeft: '21px' }}
                    src={require('../../assets/workerf.png')} alt='card para se inscrever'
                />
            </Link>
        </div>
    )
}

export default MiniBanners