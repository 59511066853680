import { IoBuild, IoHome, IoMagnet, IoSparkles, IoTrashBinOutline, IoWifi } from "react-icons/io5";
import SocAdbBottom from "./SocAdbBottom";
import Input from "../../../Form/Inputs/layouts/Input";
import { useContext } from "react";
import ServiceOrdersContext from "../context";
import { SocLocList_ } from "../interface";

const SocLocList: SocLocList_[] = [
  {
    icon: <IoHome />,
    t: 'À domicílio',
    alias: 'home'
  },
  {
    icon: <IoBuild />,
    t: 'Endereço comercial do profissional',
    alias: 'business_address'
  },
  {
    icon: <IoWifi />,
    t: 'Remoto',
    alias: 'remote'
  },
  {
    icon: <IoSparkles />,
    t: 'Outro',
    alias: 'other'
  },
  {
    icon: <IoMagnet />,
    t: 'Combinar após pagamento',
    alias: 'dont_know'
  }
]


function SocLocation() {
  const {
    socLoc,
    setSocLoc,
    socPlace,
    setSocPlace
  } = useContext(ServiceOrdersContext).socLoc1

  const handleClearSocLoc = () => {
    setSocLoc('')
    setSocPlace(SocLocList[SocLocList.length - 1])
  }

  return (
    <div className='soc'>
      <div style={{ height: '90px' }} />
      <div className="center column">
        <div style={{ width: '95vw', display: 'flex', overflowX: 'scroll', paddingBottom: '20px' }}>
          {
            SocLocList.map((it, idx) =>
              <SocLocX
                classname={socPlace.alias === it.alias && 'selected'}
                icon={it.icon}
                idx={idx}
                t={it.t}
                onClick={() => setSocPlace(it)}
              />
            )
          }
        </div>
        <div style={{ width: '95vw' }}>
          <Input
            style={{ height: '60px' }}
            label='Endereço completo'
            placeholder="Informe o endereço completo"
            value={socLoc}
            onChange={setSocLoc}
          />
        </div>
      </div>
      <div style={{ width: '100%', marginTop: '40px' }} className="center">
        <button
          onClick={handleClearSocLoc}
          className="center"
        >
          <IoTrashBinOutline />
          <p style={{ marginLeft: '8px' }}>Limpar localização</p>
        </button>
      </div>
      <SocAdbBottom
        txt={
          <div style={{ display: 'flex', alignItems: 'center', height: '100%', marginLeft: '15px', color: 'white' }}>
            {socPlace.icon}
            <div className="ellipsis" style={{ maxWidth: '120px', marginLeft: '6px' }}>
              {socLoc}
            </div>
          </div>
        }
      />
    </div>
  )
}
interface SocLocX_ { idx: any, icon: any, t: any, classname?: string | false, onClick: any }
const SocLocX = ({ idx, icon, t, classname = '', onClick }: SocLocX_) =>
  <button
    onClick={onClick}
    key={idx}
    className={`soc-loc-x soc-loc-x--${classname}`}
  >
    <div className="center" style={{ width: '100%' }}>
      <span className='center'
      >
        {icon}
      </span>
    </div>
    <div className="center" style={{ width: '100%', padding: '20px 0' }}>
      {t}
    </div>
  </button>


export default SocLocation