import ThinHeader from '../components/Headers/ThinHeader'
import { IoArrowBack } from 'react-icons/io5'
import { Link } from 'react-router-dom'

function P404() {
    return (
        <>
            <ThinHeader />
            <div style={{ ...styles.container, ...styles.center }}>
                <img style={styles.img} src={require('../assets/404.png')} alt='Pagina não encontrada' />
                <Link to='/' style={{ ...styles.btn, ...styles.center }}>
                    <span style={styles.center}>
                        <IoArrowBack />
                    </span>
                    Home
                </Link>
            </div>
        </>

    )
}

const styles = {
    center: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    container: {
        width: '100vw',
        height: '100vh',
        flexDirection: 'column',
    },
    img: {
        borderRadius: '12px 12px 0 0',
        maxWidth: '80%',
        maxHeight: '80%'
    }, btn: {
        width: '600px',
        height: '60px',
        backgroundColor: '#bbd35a',
        borderRadius: '0 0 12px 12px',
        color: '#4d4358',
        fontSize: '20px',
        maxWidth: '80%',
        maxHeight: '80%'
    }
}
export default P404