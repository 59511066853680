import { useEffect, useState } from 'react';
import { IoChatboxOutline } from 'react-icons/io5'

//import api from '../../../../config/connection/axios';
import './styles.css';
import socket from '../../../config/connection/socket';

function Bubble({ uid, type = 1 }: { uid?: string, type?: 1 | 2 }) {

    const [inBox, setInBox] = useState<boolean>(false);
    useEffect(() => {
        socket.emit('inBox', uid);
        socket.on('inBoxHave', (have: boolean) => {
            return setInBox(have);
        })

    }, [socket]);
    return (
        <span id='Bubble'>
            <IoChatboxOutline size={type === 2 ? 19 : ''} />
            {inBox && <aside className={`bubble-aside-${type}`} />}
        </span>
    )
}



export default Bubble;