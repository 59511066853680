import { useState } from 'react'
//import { IoChatbubbleSharp, IoChevronDownOutline, IoCloseSharp } from 'react-icons/io5'
//import api from '../../config/connection/axios'
//import Beta from '../Beta'
//import Spinner from '../Spinner'
import './styles.css'
import Chatting from './chat-components/Chatting'
import socket from "../config/connection/socket";
import List from './chat-components/List'
import Chat_ from './chat-interfaces/chat.interface';
import ChatHeader from './chat-components/ChatHeader';
import ChatChatTop from './chat-components/ChatChatTop';
import receiver_ from './chat-interfaces/receivers.interface';
import inBox_ from './chat-interfaces/inBox.interface';
import postMessageUserLocalStorage from '../post/user.postMessage';
import { screenName_ } from './chat-types/screenName.types';

function Chat(
    {
        // Quando esta no pc(wildscreen) o "Chat"(esse mesmo componente) estará em um modal e por isso precisa fechar
        // Já se for um celular ele tem que voltar.
        close,
        simpleReceiver
    }: Chat_
) {

    postMessageUserLocalStorage();

    const [receivers, setReceivers] = useState<Array<receiver_>>([]);
    const [inBox, setInBox] = useState<Array<inBox_>>([]);

    //const [closed, setClosed] = useState<boolean>(false);
    const u = JSON.parse(window.localStorage.getItem('serss-user') || '{}').uid
    const [screen, setScreen] = useState<screenName_>(simpleReceiver ? 'chatting' : 'list');
    const [current, setCurrent] = useState<Chat_['simpleReceiver']>(simpleReceiver && simpleReceiver);

    const handleScreen = (r: Chat_['simpleReceiver'], screenName: screenName_) => {
        setScreen(screenName)
        setCurrent(r && {
            name: r.name,
            uid: r.uid
        })
    }

    return (
        <div className='center column'>
            {screen === 'list' && <ChatHeader close={close} uid={u} />}
            {
                screen === 'chatting' &&
                <ChatChatTop
                    receiver={current && current.uid}
                    handleScreen={handleScreen}
                    name={current?.name}
                />
            }
            <main className='chat-main'>
                {
                    screen === 'chatting' &&
                    <Chatting
                        socket={socket}
                        name={current && current.name}
                        //profession={''}
                        receiver={current && current.uid}
                        uid={u}
                        handleScreen={handleScreen}
                    />
                }

                {
                    screen === 'list' &&
                    <List
                        receivers={receivers}
                        setReceivers={setReceivers}
                        inBox={inBox}
                        setInBox={setInBox}
                        uid={u}
                        socket={socket}
                        handleScreen={handleScreen}
                    />
                }
            </main>
        </div>
    )
}

export default Chat