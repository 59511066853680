import { IoBusiness, IoDiamond, IoTrophy } from 'react-icons/io5';
import Corner from './Corner';
import Beta from '../../../Beta';

export const SubB0 = ({
    switcher,
    setSwitcher
}:
    {
        switcher: 0 | 1 | 2,
        setSwitcher?: React.Dispatch<React.SetStateAction<0 | 1 | 2>>
    }
) => (
    <SubCButtonWrap
        idx={0}
        switcher={switcher}
        title='Premium'
        text='Seu anúncio em primeiro'
        setSwitcher={setSwitcher}
        background='linear-gradient(to right, #9b6a6a, #ea7f63, #ffa94e)'
        icon={<IoDiamond />}
    />
)

export const SubB1 = ({
    switcher,
    setSwitcher
}:
    {
        switcher: 0 | 1 | 2,
        setSwitcher?: React.Dispatch<React.SetStateAction<0 | 1 | 2>>
    }
) => (
    <SubCButtonWrap
        idx={1}
        switcher={switcher}
        title='Destaque'
        text='Seja o destaque da sua cidade'
        setSwitcher={setSwitcher}
        background='#222D36'
        icon={<IoTrophy />}
    />

)

export const SubB2 = ({
    switcher,
    setSwitcher
}:
    {
        switcher: 0 | 1 | 2,
        setSwitcher?: React.Dispatch<React.SetStateAction<0 | 1 | 2>>
    }
) => (
    <SubCButtonWrap
        idx={2}
        switcher={switcher}
        title='Propagandas'
        text='Sua marca vista por 10.000+ clientes'
        setSwitcher={setSwitcher}
        background='linear-gradient(135deg, #1C6DBC, dodgerblue, dodgerblue)'
        icon={<IoBusiness />}
    />
)

const SubCButtonWrap = (
    {
        switcher,
        title,
        text,
        idx,
        setSwitcher,
        background,
        icon
    }: {
        icon: any,
        background: string,
        switcher: 0 | 1 | 2,
        idx: 0 | 1 | 2,
        text: string,
        title: string,
        setSwitcher?: React.Dispatch<React.SetStateAction<0 | 1 | 2>>
    }
) => (
    <div className={`SubCButtonWrap ${idx === switcher && 'SubCButtonWrap--white'} SubCButtonWrap--${idx} center`}>
        { //Remover esse bloco e a importação do Beta && e colocor o setSwitcher nos parametros
            (idx === 1 || idx === 2) && <Beta
                text='EM BREVE'
                top={12}
                right={50}
            />
        }
        {
            (
                (switcher === idx) &&
                (switcher === 1 || switcher === 2)
            ) &&
            <Corner direction='left' />
        }
        <button
            disabled={!setSwitcher}
            onClick={
                !!setSwitcher
                    ? () => setSwitcher(idx)
                    : () => { }
            }
            style={{ background }}
        >
            <div className='center'>
                <div style={{ marginRight: '8px' }} className='center'>
                    {icon}
                </div>
                <strong>{title}</strong>
            </div>
            <p
                style={{
                    fontWeight: 300,
                    margin: '7px 0 0 0',
                    fontSize: '14px',
                    opacity: .9
                }}
            >
                {text}
            </p>
        </button>
        {
            (
                (switcher === idx) &&
                (switcher === 0 || switcher === 1)
            ) &&
            <Corner direction='right'
            />
        }
    </div>
)