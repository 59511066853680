import { IoCamera, IoClose, IoCreateOutline } from 'react-icons/io5'
import './styles.css'
import { waMethods_ } from '../../../interfaces/windowAlert.interface'
//import { useContext } from 'react'
//import WindowAlertContext from '../../../context/windowAlert.context'

interface IimagePicker {
    banner: string,
    addBanner: (url: string) => void,
    removeBanner: (idx: number) => void
    idx: number,
    alias?: string,
    dashed?: boolean,
    width?: number,
    height?: number,
    windowAlert: waMethods_['confirm'] | undefined
}

function ImagePicker({
    banner,
    idx,
    alias,
    dashed,
    width = 140,
    height = 140,
    addBanner,
    removeBanner,
    windowAlert
}: IimagePicker) {
    //const windowAlert = useContext(WindowAlertContext);

    const handleAddbanner = (e: any) => {
        const file = e.target.files[0];
        const fileType = file['type'];
        const validImageTypes = ['image/jpeg', 'image/png', 'image/jpg'];

        if (validImageTypes.includes(fileType)) {
            addBanner(URL.createObjectURL(file))
        } else windowAlert && windowAlert({
            strong: 'Arquivo',
            shake: 'O arquive deve ser uma imagem, se possível envie um “print” do mesmo.'
        })

    }

    const handleRemoveBanner = () => {
        removeBanner(idx)
    }

    return (
        <div
            className='image-picker-wrapper center'
        >
            <div className='center' style={{ position: 'relative' }} >

                {(banner) &&
                    <>

                        <div className='imgPk-black image-picker-close center' onClick={handleRemoveBanner}>
                            <IoClose size={12} />
                        </div>

                        <img
                        alt='Imagem carregada'
                            className='image-picker-banner'
                            src={banner}
                            style={{
                                width: width + 'px',
                                height: height + 'px',
                            }}
                        />
                    </>
                }
                <label
                    id='ImagePicker'
                    className={`${(banner) && 'haveImage'}`}
                    style={{
                        backgroundColor: dashed ? 'transparent' : '#696969',
                        width: width + 'px',
                        height: height + 'px'
                    }}
                >
                    <input
                        multiple={false}
                        type="file"
                        accept="image/*"
                        onChange={handleAddbanner}
                    />
                    <div className='image-picker-absolute center column' style={dashed ? { border: 'dashed 2px #696969' } : {}}>
                        <h6 style={{
                            borderRadius: '8px 0 0 0',
                            margin: 0,
                            position: 'absolute',
                            bottom: 0,
                            right: 0,
                            padding: '8px 12px',
                            color: 'white',
                            backgroundColor: dashed ? '#696969' : '#00000020',
                            fontSize: '14px',
                            letterSpacing: '1px'
                        }}>
                            {alias ? alias : (idx + 1)}
                        </h6>
                        <span className='image-picker-icon center'>
                            {(banner)
                                ? <div className='imgPk-black image-picker-edit center'>
                                    <IoCreateOutline />
                                </div>
                                : <span className='center'>
                                    <IoCamera size={38} color={dashed ? '#696969' : 'white'} />
                                </span>
                            }
                        </span>
                    </div>
                </label>
            </div>
        </div>
    )
}

export default ImagePicker