import { SocList_ } from '../interface'


function SocDateCalendar({ list, size = 70, font = 25 }: { list: SocList_, size?: number, font?: number }) {
    return (
        <>
            <div
                style={{
                    height: size + 'px',
                    width: size + 'px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    borderRadius: '8px',
                    overflow: 'hidden'
                }}
            >
                <div
                    style={{
                        backgroundColor: 'var(--dark)',
                        width: '100%',
                        height: '13px'
                    }}
                />
                <div
                    className='center column'
                    style={{
                        backgroundColor: 'white',
                        height: '54px',
                        color: 'var(--dark)'
                    }}
                >
                    <strong style={{ fontSize: font + 'px', margin: '-5px 0 0 0', }}>{list.date_day}</strong>
                    <p style={{ margin: '-5px 0 0 0', fontSize: '15px' }}>{list.date_month && list.date_month.slice(0, 3) + '.'}</p>
                </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', color: 'var(--dark)', marginLeft: '10px' }}>
                <i><strong style={{ fontSize: '18px' }}>{list.date_week}</strong></i>
                <i style={{ margin: '5px 0 0 0', fontSize: '16px' }}>{list.date_hours}</i>
            </div>
        </>
    )
}

export default SocDateCalendar