import './styles.css'
import '../../../css/card156.css'
import { useState } from 'react'
import ServiceOrdersContext from './context'
import { dateSelected_, handleOutlet_, socLoc_, SocLocList_ } from './interface'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { IoMagnet } from 'react-icons/io5'
import api from '../../../config/connection/axios'

interface prof_ {
  prof_uid: string,
  work_ref: string,
  prof_name: string,
  work_title: string
}


function ServiceOrderContainer() {
  const [prof, setProf] = useState<prof_>()
  const [description, setDesc] = useState<string>('')
  const [message, setMessage] = useState<string>('')
  const [dateSelected, setDateSelected] = useState<dateSelected_>({} as dateSelected_)
  const [hourSelected, setHourSelected] = useState('')
  const [socLoc, setSocLoc] = useState<socLoc_['fullLoc']>('')
  const [socPlace, setSocPlace] = useState<SocLocList_>({
    icon: <IoMagnet />,
    t: 'Combinar após pagamento',
    alias: 'dont_know'
  })
  const location = useLocation();
  useState(() => {
    const prof_state: prof_ | any = location.state;

    setProf(prof_state)
  })

  const navigate = useNavigate()
  const handleRRouterOutlet: handleOutlet_ = (page) => {
    navigate(page)
  }

  const handleHiringSubmit = (e: Event) => {
    e.preventDefault();

    const u = JSON.parse(window.localStorage.getItem('serss-user') || '{}')

    api.post(
      '/service/orders/intention',
      {
        client_name: u.name,
        prof_uid: prof?.prof_uid,
        prof_name: prof?.prof_name,
        work_ref: prof?.work_ref,
        work_title: prof?.work_title,
        ser_description: `${message} ${description}`.trim(),
        loc_place: (socPlace && socPlace.alias) && socPlace.alias,
        loc_location: (socLoc && socLoc.length) ? socLoc : undefined,
        date_hours: (hourSelected && hourSelected.length) ? hourSelected : undefined,
        date_month: (dateSelected && dateSelected.month) && dateSelected.month,
        date_day: (dateSelected && dateSelected.dnumber) && dateSelected.dnumber,
        date_week: (dateSelected && dateSelected.dname) && dateSelected.dname
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${u.token}`
        }
      }
    )
      .then(() => navigate('/service_orders/budgets'))
      .catch(() => { })

  }


  return (
    <ServiceOrdersContext.Provider
      value={{
        description: description,
        setDesc: setDesc,
        message: message,
        setMessage: setMessage,
        changeOutlet: handleRRouterOutlet,
        socLoc1: {
          socLoc: socLoc,
          setSocLoc: setSocLoc,
          socPlace: socPlace,
          setSocPlace: setSocPlace,
        },
        socDateAndHour1: {
          dateSelected: dateSelected,
          setDateSelected: setDateSelected,
          hourSelected: hourSelected,
          setHourSelected: setHourSelected
        },
        submit: handleHiringSubmit
      }}
    >
      <Outlet />
    </ServiceOrdersContext.Provider>
  )
}

export default ServiceOrderContainer