interface Icon_ {
    children: React.ReactElement,
    style?: React.CSSProperties,
    right?:number
}

function Icon({ children, style, right }: Icon_) {
    return (
        <span
            style={{...style, marginRight:right+'px'}}
            className="center"
        >
            {children}
        </span>
    )
}

export default Icon