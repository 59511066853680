import { SocList_ } from "./interface";

const x: SocList_[] = [
   {
      client_name: 'test',
      prof_name: 'test',
      so_unique_id: 'x',
      client_uid: "a1-e9174b25-54",
      date_day: 27,
      date_hours: "07-00",
      date_month: "novembro",
      date_week: "qua.",
      loc_location: "27",
      loc_place: "business_address",
      prof_uid: "9cda8056-e",
      ser_description: "Bsibs",
      unique_id: "1732667241234dfbe",
      work_ref: "15749",
      status: "waitingforbudget",
      ser_response: 'string',
      work_title: 'string',
   }
]

export default x;