import React from 'react'
import Screen from '../components/screens/Screen'
import VerifyProfileContainer from '../components/Containers/VerifyProfileContainer/VerifyProfile'

function VerifyProfile() {
    return (
        <Screen>
            <VerifyProfileContainer />
        </Screen>
    )
}

export default VerifyProfile