
import Header from "../../components/Headers/Header"
import PerfilContainer from "../../components/rlx-components/Containers/PerfilContainer"
import { Self_EditProfileMenu } from "../../components/EditProfileMenu"
import { useEffect, useState } from "react"
import api from "../../config/connection/axios"
import RegAvatar from "../../components/Images/RegAvatar/RegAvatar"
import Footer from "../../components/Footer"
import NavigationBar from "../../components/mobile/NavigationBar"


function SelfProfile() {
    window.scrollTo(0, 0);

    const [works, setWorks] = useState<Array<Object>>([])
    const [user, setUser] = useState<object>({})
    const [spinner, setSpinner] = useState<boolean>(true)

    useEffect(() => {
        const u = JSON.parse(window.localStorage.getItem('serss-user') || '{}')
        setUser(u)
        fetchData(u.uid)
    }, [])

    const fetchData = async (uid: string) => {
        if (uid) {
            await api.get(`/cards/belongs/${uid}`)
                .then(r => {
                    const result = r.data;
                    setWorks(result || [])
                    setSpinner(false)
                })
                .catch(() => setSpinner(false))
        }
    }

    return (
        <>
            <Header />
            <PerfilContainer
                spinner={spinner}
                user={user}
                works={works}
                Self_EditProfileMenu={<Self_EditProfileMenu />}
                Self_EditProfileAvatar={<RegAvatar width="180px" height="180px" hide={true} />}
                Self_EditDeleteWork={true}
            />
            <Footer />
            <NavigationBar />
        </>
    )
}



export default SelfProfile