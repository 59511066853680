import { Link } from 'react-router-dom';
import './styles.css';
//import { useEffect } from 'react';
import works_ from '../../../interfaces/works.interface';
import { IoChevronForward } from 'react-icons/io5';
//import searchhandleSubmit_ from '../Containers/SearchContainer/searchContainer.interface';


function CardsWithoutImagesWrapper({ emptyWorks }: { emptyWorks: Array<works_> }) {
  return (
    <div id='CardsWithoutImagesWrapper' style={{ marginTop: '80px' }}>
      <ul>
        {emptyWorks.map((it, idx) => (
          <Link to={'/details/' + it.id} key={idx}>
            <li>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <h6 style={{ opacity: it.price ? 1 : 0 }}>{it.price ? 'R$ ' + it.price : '-'}</h6>
              </div>
              <div className='cwiw-texts-w'>
                <h1 className='ellipsis'>{it.title}</h1>
                <p className='ellipsis'>{it.description}</p>
              </div>
              <span className='cwiw-absolute center'>
                  <IoChevronForward />
              </span>
            </li>
          </Link>
        ))}
      </ul>
    </div>
  )
}

export default CardsWithoutImagesWrapper;