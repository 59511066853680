import { useEffect, useState } from "react"
import DoubtsButton from "../../components/Buttons/DoubtsButton"
import WorkCreateContainer from "../../components/Containers/WorkCreateContainer"
import ThinHeader from "../../components/Headers/ThinHeader"
import Spinner from "../../components/Spinner"
import VerifyYourAcount from "../../components/VerifyYourAcount"
import api from "../../config/connection/axios"
import Screen from "../../components/screens/Screen"

interface VerifiedResponse_ { count: any, verified: any, allowed: any }

function Create() {

  const [spinner, setSpinner] = useState<boolean>(true)
  const [data, setData] = useState<VerifiedResponse_>({} as VerifiedResponse_)

  useEffect(() => {
    const fetchData = async () => {

      const u = JSON.parse(window.localStorage.getItem('serss-user') || '{}');

      await api.get(
        `/mixed/works/limit/${u.uid}`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${u.token}`
          }
        }
      )
        .then(r => {
          const result = r.data;
          setData(result || {})
          setSpinner(false)
        })
        .catch(() => setSpinner(false))

    }

    fetchData()
  }, [])

  return (
    <Screen>
      <ThinHeader
        button={<DoubtsButton />}
      />

      {
        spinner
          ? <Spinner visible={spinner} />
          : <>
            {
              data.allowed
                ? <WorkCreateContainer />
                : <VerifyYourAcount />
            }
          </>
      }
    </Screen>
  )
}

export default Create