import { useEffect, useState } from "react"
import api from "../../../config/connection/axios"
import CardPartner from "../../Cards/CardPartner";
import { Link } from "react-router-dom";
import './styles.css'

function HomePartner({ title }: { title: JSX.Element }) {

    const [partners, setPartners] = useState<Array<any>>([]);
    const [spinner, setSpinner] = useState<boolean>(true);

    useEffect(() => {
        const callPartners = () => {
            api.get('/users/list/partner').then(r => {
                const result = r.data;
                setPartners(result);
            }).then(() => setSpinner(false))
        }

        callPartners();
    }, [])

    return (
        <>
            {!spinner && <div>
                {title}
                <div className='home-partner'>

                    {
                        partners.map((it, idx) =>
                            <Link to={'/profile/' + it.uid} key={idx}>
                                <CardPartner partner={it} />
                            </Link>
                        )
                    }
                </div>
            </div>}
        </>
    )
}

export default HomePartner
