import './styles.css'

import { IoCreateOutline, IoDuplicate } from 'react-icons/io5'
import { Link } from 'react-router-dom';
import Icon from '../Icon';



export function Self_EditProfileMenu() {
    return (
        <div className='EPM-wrapper'>
            <Link
                className='editprof-li'
                to='/registration'
            >
                <div id='EditProfileMenu' className='center'>

                    <span>
                        <IoCreateOutline />
                    </span>
                    <p>Dados Pessoais</p>

                </div>
            </Link>
            <Link
                to='/work/create'
            >
                <div className='EPM-add-work'>
                    <Icon>
                        <IoDuplicate size={36} />
                    </Icon>
                </div>
            </Link>
        </div>
    )
}