import React, { useContext } from 'react'
import ServiceOrdersContext from '../context'

function SocAdbBottom({ txt }: { txt: any }) {
    const { changeOutlet } = useContext(ServiceOrdersContext)
    return (
        <div
            className="center"
            style={{
                position: 'fixed',
                bottom: 0,
                left: 0,
                backgroundColor: 'white',
                height: '76px',
                width: '100vw',
            }}
        >
            <div style={{
                backgroundColor: 'var(--dark)',
                padding: '3px',
                width: 'calc(95% - 6px)',
                borderRadius: '8px',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
            }}
            >
                <div>
                    {txt}
                </div>

                <button
                    onClick={() => changeOutlet && changeOutlet('')}
                    className='card156 soc-description-main-btn'
                    style={{
                        backgroundColor: 'white',
                        color: 'var(--dark)',
                        height: '40px'
                    }}
                >
                    <strong>Confirmar</strong>
                </button>
            </div>

        </div>
    )
}

export default SocAdbBottom