import { IoCheckmarkCircle, IoPeopleOutline, IoThumbsDown, IoThumbsUp } from "react-icons/io5";
import SimpleDarkButton from "../Buttons/SimpleDarkButton";
//import './styles.css';

const mr_texts = {
    customer_found: {
        title_top: 'Cliente encontado!',
        desc_top: 'Parabens! Você se conectou com mais um potencial cliente.',
        desc_bottom: <p>Um App bem avaliado gera <strong>mais cliente para você mesmo</strong>, já pensou?</p>
    },

    free_premium_now: {
        title_top: 'Agora você é Premium!',
        desc_top: 'Agora você pode desfrutar das vantagens do Perfil Premium.',
        desc_bottom: <p>Como esta a sua experiencia?</p>
    },


    already_free_premium: {
        title_top: 'Você já é Premium!',
        desc_top: 'Confira a data de expiração nos "dados pessoais" do seu perfil.',
        desc_bottom: <p>Como esta a sua experiencia?</p>
    }
}

// Criar uma função para conferir se o usuaŕio já avaliou na loja:
const _handle_check_if_the_user_has_already_evaluated = () => true;
// Conferir se a plataforma é "mobile":
const check_if_is_mobile = false;
// 1) Se for "mobile": 
//      conferir se o usuário já avaliou ou não. 
// 2) Se não for "mobile":
//      definir como não direto.
const check_if_the_user_has_already_evaluated =
    !check_if_is_mobile
        ? false
        : _handle_check_if_the_user_has_already_evaluated();

function ModalReward(
    { type }:
        {
            type:
            'customer_found' |
            'free_premium_now' |
            'already_free_premium'
        }) {
    return (
        <div className="center column" style={{ textAlign: 'center' }}>
            <div
                className="center column"
                style={{
                    //backgroundColor: 'var(--border)',
                    width: '100%',
                    minHeight: '160px',
                    marginBottom: '50px',
                    padding: '20px 0'
                }}>

                <div
                    className="center"
                    style={{
                        backgroundColor: 'white',
                        borderRadius: '16px',
                        color: '#696969',
                        width: '90%',
                        border: '2px solid var(--border)',
                        height: '70px'
                    }}
                >
                    <span className="center" style={{ marginRight: '12px' }}>
                        <IoCheckmarkCircle size={40} color="#00AE7499" />
                    </span>
                    <h3>{mr_texts[type].title_top}</h3>
                </div>
                <div style={{ width: '90%' }}>
                    <p style={{ fontWeight: 'bold', color: '#696969' }}>
                        {mr_texts[type].desc_top}
                    </p>
                </div>
                {
                    type === 'free_premium_now' &&
                    <div
                        style={{
                            width: '90%',
                            display: 'flex',
                            justifyContent: 'space-around',
                            color: 'var(--dark)',
                            backgroundColor: '#00000010',
                            borderRadius: '6px',
                            marginTop: '34px'
                        }}
                    >
                        <div style={{ display: 'flex' }}>
                            <span className="center" style={{ marginRight: '8px' }}>
                                <IoPeopleOutline />
                            </span>
                            <p>Saldo de contatos:</p>
                        </div>
                        <div className="center">
                            <p style={{ fontWeight: 'bolder' }}>2</p>
                        </div>
                    </div>
                }
            </div>
            {
                !check_if_is_mobile &&

                <SimpleDarkButton
                    onClick={() => { }}
                    height="60px"
                >
                    Confirmar
                </SimpleDarkButton>

            }
            {
                (
                    check_if_is_mobile &&
                    !check_if_the_user_has_already_evaluated
                ) &&
                <div style={{ width: '100%' }}>
                    <div style={{ color: 'var(--dark)' }}>
                        <strong style={{ fontSize: '26px' }}>Queremos sua opnião</strong>
                        {mr_texts[type].desc_bottom}
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-around', width: '100%' }}>
                        <ThumbsColors
                            icon={<IoThumbsDown />}
                            text={<strong>Precisa<br />Melhorar</strong>}
                            backgroundColor="tomato"
                        />
                        <ThumbsColors
                            icon={<IoThumbsUp />}
                            text={<strong>Estou<br />Gostando</strong>}
                            backgroundColor="var(--green)"
                        />
                    </div>
                </div>
            }
        </div>
    )
}

const ThumbsColors = ({ icon, text, backgroundColor }: { icon: JSX.Element, text: JSX.Element, backgroundColor: string }) => (
    <button
        onClick={() => { }}
        className="center column"
        style={{
            border: '2px solid var(--border)',
            borderRadius: '30px',
            padding: '20px 12px'
        }}
    >
        <div
            className="center"
            style={{
                border: '2px solid ' + backgroundColor,

                borderRadius: '100%',
                height: '80px',
                width: '80px',
                color: backgroundColor,
                fontSize: '32px'
            }}
        >
            <span className="center">
                {icon}
            </span>
        </div>
        <div
            style={{
                textAlign: 'center',
                color: '#696969',
                width: '80px',


                padding: '10px 24px',
                marginTop: '4px'
            }}
        >
            {text}
        </div>
    </button>
)
export default ModalReward