import { useEffect, useRef, useState } from 'react';
import './styles.css'
import ContatButtons from '../../Buttons/ContactButtons';
import A from './layouts/A';
import B from './layouts/B';
import C from './layouts/C';
import D from './layouts/D';
import api from '../../../config/connection/axios';
import ServiceButtons from '../../Buttons/ServiceButtons';
import Spinner from '../../Spinner';
import DetailsUser from '../../DetailsUser';
import SeeButton from '../../Buttons/SeeButton';
import E from './layouts/E';
import F from './layouts/F';
import ImagesDetailsSlider from '../../Images/ImagesDetailsSlider';
/*import H from './layouts/H';*/ //preimum button

//import CardOnlyAvatar from '../../Cards/CardOnlyAvatar';

/*import { Link } from 'react-router-dom';*/
import PPublicidade from './layouts/PPublicidade';


function DetailsContainer(
  {
    work_id,
    setReceiver,
    spinner,
    setSpinner,
    extraContent,
    setChat
  }:
    {
      work_id: string | undefined,
      setReceiver: (state: any) => void,
      spinner: boolean,
      setSpinner: any,
      extraContent: React.MutableRefObject<null>

      setChat: any
    }
) {
  const containerRef = useRef(null);

  /*const [scrolling, setScrolling] = useState<number>(148);*/
  const [data, setData] = useState<any>({});
  const [phone, setPhone] = useState<string>('');

  /*const [spinnerSeeMore, setSpinnerSeeMore] = useState<boolean>(true);*/
  /*const [seeMore, setSeeMore] = useState<Array<Array<any>>>([[{}], [{}]]);*/

  const mySelf: boolean =
    JSON.parse(window.localStorage.getItem('serss-user') || '{}').uid
    ===
    data.user_uid;

  /*
const [selfWorks, setSelfWorks] = useState<any>([])
const fetchSelfWorks = async (uid: string, wid: number) => {
  if (uid && wid) {
    await api.get(`/cards/belongs/${uid}?wid=${wid}`)
      .then(r => {
        const result = r.data;
        setSelfWorks(result || [])
      })
  }
}*/


  useEffect(() => {
    const fetchData = async () => {
      if (!isNaN(Number(work_id))) {

        setSpinner(true);

        await api.get(`/works/load/${work_id}`)
          .then(r => {
            const result = r.data

            setData(result || {})
            setReceiver({
              uid: result.user_uid,
              name: result.user_name
            })
            setPhone(result.phone)
            /*fetchSelfWorks(result.user_uid, result.id)*/
            setSpinner(false)
          })
          .catch(() => setSpinner(false));
      }
    }


    /*
    const seeMore = async () => {
      const serssLocation = JSON.parse(window.localStorage.getItem('serss-location') || '{}')
      const serssSearch = window.sessionStorage.getItem('serss-search');

      if (!isNaN(Number(work_id))) {

        setSpinner(true);

        await api.get(`/cards/list/see/more?search=${serssSearch ? serssSearch : ''}&location=${serssLocation}`)
          .then(r => {
            const result = r.data

            const midpoint = Math.ceil(result.length / 2);

            const firstHalf = result.slice(0, midpoint);

            const secondHalf = result.slice(-midpoint);

            const bouthHalf = [firstHalf, secondHalf]

            setSeeMore(bouthHalf || [[{}], [{}]])
            setSpinnerSeeMore(false)
          })
          .catch(() => setSpinnerSeeMore(false));
      }
    }*/

    fetchData();

    /*seeMore();*/
  }, [work_id, setReceiver, setSpinner])



  const handleNewPhone = (n: string) => {
    setPhone(n)
  }

  return (
    <>
      <div ref={containerRef} className='center' style={{ paddingBottom: '90px' }}>
        {/*<h6 className='DC-bread'>
        <p>{data.state}</p>
        <p style={{ padding: '0 10px' }}>{' > '}</p>
        <p>{data.city}</p>
        <p style={{ padding: '0 10px' }}>{' > '}</p>
        <p>{data.title}</p>
  </h6>*/}
        {
          spinner
            ? <Spinner
              color={'#00ae74'}
              visible={spinner}
            />
            : <>
              <div id='DetailsContainer' style={{ position: 'relative', display: 'flex', flexDirection: 'column' }}>
                {
                  (data.banner)
                    ? <div>
                      <ImagesDetailsSlider
                        premium={data.premium}
                        banners={data.banner}
                        uid={data.user_uid}
                        wid={data.id}
                      />
                    </div>
                    : <></>
                }
                <main
                  id='details-main'
                  className={`d-main-top--${(data.banner) ? 'negative' : 'positive'}`}
                >
                  <A
                    user_profession={data.user_profession && data.title && data.user_profession.toString().trim() === data.title.toString().trim() ? '' : data.user_profession}
                    user_name={data.user_name}
                    user_description={data.user_description}

                    partner={data.partner}
                    verified={data.verified}
                    avatar={data.avatar}
                    premium={data.premium}

                    title={data.title}
                    description={data.description}

                    price={data.price}
                    discount={data.discount}
                    per={data.per}

                    remote={data.remote}
                    user_uid={data.user_uid}
                    id={data.id}

                    phone={phone}
                    handleNewPhone={handleNewPhone}

                    updated_at={data.updated_at}
                  />
                  {data.hours && JSON.parse(data.hours) && <B
                    availability={JSON.parse(data.hours)}
                  />}
                  {(data.details && JSON.parse(data.details).length > 0) &&
                    <C details={JSON.parse(data.details)} />
                  }
                  {
                    window.innerWidth <= 960 && <PPublicidade />
                  }
                  <D
                    address={data.address}
                    city={data.city}
                    district={data.district}
                    number={data.number}
                    state={data.state}
                    zipcode={data.zipcode}
                  />

                  {(data.checklist && JSON.parse(data.checklist).filter((n: string) => n).length) ? <E
                    checklist={JSON.parse(data.checklist)}
                  /> : <></>}

                  {/*
                (data.socialmedia && (JSON.parse(data.socialmedia).instagram || JSON.parse(data.socialmedia).facebook || JSON.parse(data.socialmedia).linkedin)) && <F
                  socialmedia={data.socialmedia && JSON.parse(data.socialmedia)}
                />
                */}
                  {
                    (data.socialmedia && (JSON.parse(data.socialmedia).instagram)) && <F
                      mySelf={mySelf}
                      socialmedia={data.socialmedia && JSON.parse(data.socialmedia)}
                    />
                  }
                  {/*(mySelf && window.innerWidth < 960 && !data.premium) && <H wid={data.id} />*/}
                </main>
                <DetailsUserAside premium={data.premium} mySelf={mySelf} data={data} containerRef={containerRef} extraContent={extraContent} setChat={setChat}/>
              </div>
              {
                (mySelf)
                  ? <ServiceButtons
                    _style='fixed'
                    id={data.id}
                  />
                  : <ContatButtons
                    setChat={setChat}
                    height='58px'
                    type='fixed'
                    data={data}
                  />

              }
            </>
        }
      </div >
      {/*
        (!spinner && !spinnerSeeMore && seeMore[0].length) ?
          <>
            {/*
            <div style={{ width: '100%' }} className='center'>
              <div style={{ width: '94%' }}>
                <div className='DC-Title' style={{ border: 'none' }}>
                  <h3 style={{ fontSize: '26px' }}>Descubra</h3>
                </div>
                <ul className='details-see-more-bottom-grid'>
                  {seeMore[0].map((it, idx) => <li key={idx}><CardOnlyAvatar work={it} /></li>)}
                </ul>
              </div>
            </div>

            <div style={{ width: '100%', margin: '80px 0' }} className='center'>
              <div style={{ width: '94%' }}>
                <ul className='details-see-more-bottom-grid'>
                  {seeMore[1].map((it, idx) => <li key={idx}><CardOnlyAvatar work={it} /></li>)}
                </ul>
              </div>
            </div>
          }

            <div style={{ width: '100%' }} className='center DBottomLi'>
              <div style={{ width: '94%' }}>
                <div className='DC-Title' style={{ border: 'none' }}>
                  <h3 style={{ fontSize: '26px' }}>Outros Serviços</h3>
                </div>
                <ul className='details-see-more-bottom-grid'>
                  {listHaa[0].map((it, idx) => <DBottomLi key={idx} name={it} />)}
                </ul>
                <ul className='details-see-more-bottom-grid'>
                  {listHaa[1].map((it, idx) => <DBottomLi margin key={idx} name={it} />)}
                </ul>
              </div>
            </div>
          </> : <></>
        */}
    </>
  )
}
/*
const listHaa = [
  [
    'pedreiro',
    'diarista',
    'eletricista',
    'encanador',
    'maquiadora',
  ],
  [
    'marido de aluguel',
    'designer',
    'frete',
    'montador',
    'técnico',
  ]
]*/

/*const DBottomLi = ({ name, margin }: { name: string, margin?: boolean }) =>
  <li className='center' style={{ width: '90%', border: '1px solid #696969', borderRadius: '4px', color: '#696969', padding: '14px 0', margin: margin ? '40px 0 80px 0' : 0 }}><Link to={`/search?search=${name}`}><span style={{ width: '100%', height: '100%' }}>{name}</span></Link></li>*/

const DetailsUserAside = (
  {
    data,
    setChat,
    containerRef,
    extraContent,
    mySelf,
    premium
  }:
    {
      data: any,
      setChat: any,
      extraContent: React.MutableRefObject<null>,
      containerRef: React.MutableRefObject<null>,
      mySelf: boolean,
      premium: boolean
    }
) => {

  const [Y, setY] = useState<React.CSSProperties>({ position: 'absolute', top: '260px' });

  useEffect(() => {
    const handleY = () => {
      if (extraContent && containerRef && extraContent.current && containerRef.current) {
        /*const extra: any = extraContent.current;*/
        const container: any = containerRef.current;

        //const extraHeight = extra.getBoundingClientRect();

        let breakPoint = {};

        if (window.scrollY < 314)
          breakPoint = { position: 'absolute', top: '260px' };
        else if (((window.innerHeight + window.scrollY) + 160) > container.scrollHeight)
          breakPoint = { position: 'absolute', bottom: 0 }
        else
          breakPoint = { position: 'fixed', top: '-40px' };

        return setY(breakPoint);
      }
    }

    (data.banner) &&
      window.addEventListener('scroll', handleY);

    //return window.removeEventListener('scroll', handleY);
  }, [containerRef, data.banner, extraContent])

  return (
    <aside className={'DC-aside-fixed'} style={Y}>
      <DetailsUser
        DUHaveBanner={(data.banner)}
        premium={premium}
        mySelf={mySelf}
        data={{
          id: data.id,
          user_uid: data.user_uid,
          user_profession: data.user_profession && data.title && data.user_profession.toString().trim() === data.title.toString().trim() ? '' : data.user_profession,
          user_name: data.user_name,
          user_description: data.user_description,
          verified: data.verified,
          avatar: data.avatar
        }}
      >
        {
          (mySelf)
            ? <div style={{ width: '100%' }} className='center column'>
              <ServiceButtons id={data.id} />
            </div>
            : <div style={{ width: '90%' }}>
              <ContatButtons
                setChat={setChat}
                height={'44px'}
                data={data}
              />
              <SeeButton
                text='Perfil profissional'
                to={`/profile/${data.user_uid}`}
                type={2}
              />
            </div>
        }
      </DetailsUser>
    </aside>
  )
}

export default DetailsContainer