import { useContext, useState } from "react";
import SafeDiv from "../../../components/SafeDiv"
import ThinHeader from "../../../components/Headers/ThinHeader";
import cpf from "../../../functions/cpf";
import api from "../../../config/connection/axios";
import WcMessage from "../../../components/Containers/WorkCreateContainer/layouts/WcMessage";
import { useNavigate } from "react-router-dom";
import './styles.css'
import VPFromThree from "./layouts/VPFromThree";
import FormButton from "../../../components/Form/Inputs/layouts/FormButton";
import ImagePicker from "../../../components/Form/ImagePicker";
import Input from "../../../components/Form/Inputs/layouts/Input";
import { IoCheckmarkCircle } from "react-icons/io5";
import LabelTitle from "../../../components/Form/Inputs/layouts/LabelTitle";
import Checkbox from "../../../components/Form/Inputs/layouts/Checkbox";
//import LanguageButtom from "../../../components/LanguageButtom";
import { Slide } from "../../../components/Form/Inputs/layouts/Slide";
/*import { WindowAlert, windowAlert_ } from "../../../components/WindowAlert";*/
import WindowAlertContext from "../../../context/windowAlert.context";

function VerifyProfileContainer() {

    const windowAlert = useContext(WindowAlertContext);

    /*const [windowAlert, setWindowAlert] = useState<windowAlert_>({ visible: false });*/

    const [doc, setDoc] = useState<string>('');
    const [birthDate, SetBirthDate] = useState<string>('');
    const [fullName, SetFullName] = useState<string>('');
    const [fullAddress, SetFullAddress] = useState<string>('');

    //const [cnpj, setCnpj] = useState<string>('')
    const [both, setBoth] = useState<boolean>(false);
    const [validCpf, setValidCpf] = useState<boolean>(false);
    const [spinner, setSpinner] = useState<boolean>(false);
    const [screen, setScreen] = useState<1 | 2>(1);
    //const [pfpj, setPfpj] = useState<'PF' | 'PJ'>('PF');
    const [liveforrent, setLiveforrent] = useState<boolean>(false);
    const [terms, setTerms] = useState<boolean>(false);

    const [frontImg, setFrontImg] = useState('');
    const [backImg, setBackImg] = useState('');
    const [addressProof, setAddressProof] = useState('');

    const [showMessage, setShowMessage] = useState<boolean>(false);

    const removeImg = (idx: number) => {
        idx ? setBackImg('') : setFrontImg('');
    }

    const handleDoc = (value: string) => {
        setValidCpf(cpf(value));
        setDoc(value);
    }

    const handleVerifyProfileSubmit = async (e: Event) => {
        e.preventDefault();

        if (!doc || !validCpf) return windowAlert?.confirm({ shake: 'Informe um CPF valido.' });
        if (!addressProof && !liveforrent) return windowAlert?.confirm({ shake: 'Foto do comprovante de residência.' });
        if (
            (!both && (!frontImg || !backImg)) ||
            (both && !frontImg)
        ) return windowAlert?.confirm({ shake: 'Foto frente e verso do RG/CNH.' });
        if (!document || !fullAddress || !fullName || !birthDate) return windowAlert?.confirm({ shake: 'Por favor preencha todos os dados.' });
        if (!terms) return windowAlert?.confirm({ shake: 'Por favor leia e confirme os termos.' });

        const u = JSON.parse(window.localStorage.getItem('serss-user') || '{}');

        setSpinner(true);

        if (u && u.token) api.post(
            '/verifications/create',
            {
                document: doc,
                birth_date: birthDate,
                full_address: fullAddress,
                full_name: fullName
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${u.token}`
                }
            }
        )
            .then(
                () => {
                    setShowMessage(true);
                    setSpinner(false);
                }
            )
            .catch((e) => {
                setSpinner(false);
                if (e && e.response && e.response.status && e.response.status === 409) {
                    /*setWindowAlert({ visible: true })*/
                    windowAlert?.confirm(
                        {
                            strong: 'Documentos em análise',
                            p: 'Seus documentos estão em análise, por favor aguarde.',
                            alertOnly: true
                        },
                        () => navigate('/profile')
                    )


                }
            });
    }

    const navigate = useNavigate()

    const handleNext = () => {
        navigate('/profile')
    }

    const toggleScreens = (e: Event, n: 1 | 2) => {
        e.preventDefault();
        setScreen(n);

    }

    return (
        <>
            {showMessage && <WcMessage
                visible={showMessage}
                setVisible={handleNext}
                customTexts={{
                    check: 'Perfil verificado com sucesso!',
                    title: '',
                    content: 'As informações fornecidas serão submetidas a uma análise de veracidade. Se os dados estiverem corretos, seu perfil verificado será mantido. Caso contrário, será removido.',
                    button: 'Entendi'
                }}
            />}
            <div
                style={{
                    width: '100%',
                    height: '380px',
                    backgroundColor: '#00ae74',
                    position: 'absolute',
                    zIndex: 0

                }}
            />
            <ThinHeader />
            <SafeDiv>
                <div id='WorkCreate' className='center column'>

                    {
                        screen === 1 &&
                        <form className='vpFrom'>
                            {/*<h2>{`Perfil verificado: ${pfpj === 'PF' ? 'Pessoas físicas' : 'Pessoas jurídicas'}`}</h2>*/}
                            <h2>{'Perfil verificado: Pessoas físicas'}</h2>
                            <LabelTitle
                                label='Foto do RG/CNH'
                                required
                            />
                            <div className='vp-both'>

                                <ImagePicker
                                    windowAlert={windowAlert?.confirm}
                                    width={both ? 380 : 220}
                                    height={160}
                                    addBanner={setFrontImg}
                                    banner={frontImg}
                                    removeBanner={removeImg}
                                    idx={0}
                                    dashed
                                    alias={both ?
                                        'Frente e verso'
                                        : 'Frente'
                                    }
                                />
                                <div style={{ marginTop: '20px' }} />
                                {
                                    !both &&
                                    <ImagePicker
                                        windowAlert={windowAlert?.confirm}
                                        width={220}
                                        height={160}
                                        addBanner={setBackImg}
                                        banner={backImg}
                                        removeBanner={removeImg}
                                        idx={1}
                                        dashed
                                        alias={'Verso'}
                                    />
                                }
                            </div>
                            <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                                <div style={{ width: '90%' }} >
                                    <Input
                                        label='Nome completo'
                                        placeholder='Nome e sobrenome'
                                        value={fullName}
                                        onChange={SetFullName}
                                        required
                                    />
                                    <Input
                                        label='CPF (apenas números)'
                                        placeholder='EX: 12312312312'
                                        min={11}
                                        max={11}
                                        value={doc}
                                        onChange={handleDoc}
                                        required
                                    />
                                </div>
                                <div style={{ width: '10%', height: '50px' }} className="center">
                                    <IoCheckmarkCircle size={30} color={validCpf ? '#00ae74' : '#c4c4c4'} />
                                </div>
                            </div>
                            <div style={{ width: '90%' }} >
                                <Input
                                    label='Data de Nascimento (apenas números)'
                                    placeholder='Ex: 25121970'
                                    maxlength={10}
                                    value={birthDate.replace(/^(\d{2})(\d{2})/, "$1/$2/")}
                                    onChange={SetBirthDate}
                                    required
                                />

                                <Input
                                    label='Seu endereço completo'
                                    placeholder='Ex: R. Brasilia, 1000, Ap. 10 B. 1 - Brasília, DF, 00000-000'
                                    min={11}
                                    max={11}
                                    value={fullAddress}
                                    onChange={SetFullAddress}
                                    required
                                />
                            </div>

                            <div className={liveforrent ? 'liveforrent' : ''}>
                                <LabelTitle
                                    label='Foto do comprovante de residência'
                                    required
                                />
                                <div>
                                    <ImagePicker
                                        windowAlert={windowAlert?.confirm}
                                        width={380}
                                        height={160}
                                        addBanner={setAddressProof}
                                        banner={addressProof}
                                        removeBanner={() => setAddressProof('')}
                                        idx={3}
                                        dashed
                                        alias={'comprovante de residência'}
                                    />
                                </div>
                            </div>
                            <Slide
                                value={liveforrent}
                                text='Não possuo comprovante ou moro em uma residência alugada.'
                                onChange={setLiveforrent}
                            />
                            <div style={{ width: '100%', borderBottom: '1px solid #c4c4c4', margin: '40px 0 -20px 0' }} />
                            <div style={{ width: '90%' }} >

                                <Checkbox
                                    label=' '
                                    space={0}
                                    text={
                                        <p style={{ color: '#696969' }}>
                                            Aceito que meus dados passem pelo <strong onClick={(e: any) => toggleScreens(e, 2)} className='hover-agreement'>processo de validação e armazenamento</strong>, e confirmo a veracidade das informações fornecidas, as quais são de minha autoria.
                                        </p>
                                    }
                                    value={terms}
                                    onChange={setTerms}
                                />
                            </div>
                            <div style={{ width: '100%', borderBottom: '1px solid #c4c4c4', marginTop: '40px' }} />
                            <LabelTitle
                                label='Informações:'
                            />
                            <ol>
                                <li style={{ color: '#696969', margin: '-16px 0 20px 0' }}>
                                    <p onClick={(e: any) => toggleScreens(e, 2)} className='hover-agreement'>Saiba por que pedimos esses dados e o que acontece com eles.</p>
                                </li>
                                <li style={{ color: '#696969' }}>
                                    <p onClick={(e: any) => toggleScreens(e, 2)} className='hover-agreement'>O que fazer caso queira registrar sua empresa.</p>
                                </li>
                            </ol>

                            <div className='done-button center'>
                                <FormButton
                                    onClick={handleVerifyProfileSubmit}
                                    text={'Confirmar'}
                                    space={0}
                                    spinner={spinner}
                                    style={{
                                        borderRadius: '100px',
                                        height: '48px',
                                        width: '90%',
                                        backgroundColor: 'var(--dark)',
                                        border: '1px solid transparent',
                                        color: '#eee'
                                    }}

                                />
                            </div>
                            {/*<div className='VPFrom-scAlert'>
                                <ScAlert
                                    title='Informações'
                                    content={
                                        <>
                                            <p style={{ margin: 0 }}>Saiba por que pedimos esses dados e o que acontece com eles.</p>
                                            <p style={{ color: '#696969', margin: '10px 0', width: '100%', textAlign: 'center' }}>ou</p>
                                            <p style={{ margin: '0 0 16px 0' }}>O que fazer caso queira registrar sua <strong>empresa</strong>.</p>
                                        </>
                                    }

                                    custom={{
                                        blueMore: true,
                                        extendContent: <VPFromText />,
                                    }}
                                />
                                </div>*/}

                        </form>
                    }

                    {screen === 2 && <VPFromThree toggleScreens={toggleScreens} />}

                    <div style={{ height: '100px' }} />
                </div>
            </SafeDiv>
        </>
    )
}

export default VerifyProfileContainer;