import { useEffect, useState } from 'react'
import api from '../../../../config/connection/axios';
import Spinner from '../../../Spinner';
import RegIsV from './RegIsV';
import RegBtnI from './RegBtnI';
import { Link } from 'react-router-dom';
import RegBorderBut from './RegBorderBut';
import { IoDiamond } from 'react-icons/io5';

interface pData_ { expiration: string | null, credit: number | null }
interface plan_ {
    plan: 'free' | 'test' | 'full' | 'expired' | ''
}

function RegPremiumProfile() {

    //const [isVerified, setIsVerified] = useState<boolean>(false);
    const [spinner, setSpinner] = useState<boolean>(false);
    const [plan, setPlan] = useState<plan_['plan']>('');
    const [pData, setPDATA] = useState<pData_>({} as pData_);

    useEffect(() => {
        const fetchData = async () => {

            const u = JSON.parse(window.localStorage.getItem('serss-user') || '{}');

            await api.get(`/premium/is/active/${u.uid}`)
                .then(r => {
                    const result = r.data;
                    setPlan(result.plan)
                    setPDATA({
                        expiration: Boolean(new Date(result.expiration))
                            ? new Date(result.expiration).toLocaleString('pt-br')
                            : result.expiration,
                        credit: result.credit
                    })
                    //setIsVerified(result.verified)
                    setSpinner(false)
                })
                .catch(() => setSpinner(false))

        }

        fetchData()
    }, [])
    return (
        <>
            {spinner && <div className='RegC-card'><Spinner visible color='#00ae74' /></div>}
            {(!spinner && plan !== 'full' && plan !== '') && <RegPremiumProfileContent plan={plan} pData={pData} />}
            {
                (!spinner && plan === 'full') &&
                <RegIsV children={
                    <div className='center column'>
                        <h3>Plano Premium: <strong style={{ color: 'dodgerblue' }}> Ativo</strong></h3>

                        <PPShowWhatLeft
                            ppleft='Data:'
                            ppright={pData.expiration}
                        />
                    </div>
                }
                />
            }
        </>
    )
}

const PPShowWhatLeft = ({ ppleft, ppright }: { ppleft: string, ppright: number | string | null }) => (
    <div
        style={{
            width: '70%',
            display: 'flex',
            justifyContent: 'space-between',
            backgroundColor: 'var(--border)',
            color: '#696969',
            borderRadius: '16px',
            padding: '30px 40px',
            marginTop: '30px'
        }}
    >
        <strong>{ppleft}</strong>
        <strong>
            {ppright}
        </strong>
    </div>
)


const RegPremiumProfileContent = ({ plan, pData }: { plan: plan_['plan'], pData: pData_ }) => (
    <div className='RegC-card'>
        <div style={{ display: 'flex' }}>
            <span className='center' style={{ backgroundColor: 'var(--dark)', height: '80px', width: '80px', borderRadius: '100%', position: 'relative' }}>
                <IoDiamond size={40} color='white' />
            </span>
        </div>
        <div className='RegC-title'>
            <h4>Faça upgrade do seu plano</h4>
            <br />
            <p>
                No plano <strong>Premium</strong> seus anúncios ficam entre os primeiros das pesquisas:
                <ul>
                    <br />
                    <li><strong>•</strong> Se destaque da concorrência.</li>
                    <br />
                    <li><strong>•</strong> Você no topo das pesquisas.</li>
                    <br />
                    <li><strong>•</strong> Seus anúncios ficam entre os primeiros.</li>

                </ul>
            </p>
        </div>
        <RegBorderBut
            title='Plano Premium'
            icon={<IoDiamond />}
            text='R$ 19,90'
            button={
                <Link to='/subscription' style={{ width: '100%' }}>
                    <RegBtnI
                        text={
                            plan === 'free'
                                ? 'Teste gratuito'
                                : plan === 'expired'
                                    ? 'Renovar'
                                    : 'Comprar'
                        }
                        color='var(--dark)'
                    />
                </Link>
            }
        />
        <div
            style={{
                color: 'var(--dark)',
                borderRadius: '14px',
                border: '2px solid #696969',
                width: '100%',
                marginTop: '30px',


            }}
        >
            <div style={{ padding: '20px 20px 20px 40px' }}>
                <strong>Plano atual:
                    <strong style={{ color: ' dodgerblue' }}>
                        {plan === 'test' && ' Premium'}
                        {plan === 'expired' && ' Expirou'}
                        {plan === 'free' && ' Free'}
                    </strong>
                </strong>
                <p style={{ margin: '4px 0 0 0', color: '#696969' }}>
                    {plan === 'test' && 'Em período de teste gratuito'}
                    {plan === 'expired' && 'Seu plano expirou, renove agora'}
                    {plan === 'free' && 'Sem custos financeiros'}
                </p>
                {plan === 'test' && <PPShowWhatLeft
                    ppleft='Saldo de contatos:'
                    ppright={pData.credit}
                />}
            </div>
        </div>
    </div>
)


export default RegPremiumProfile