import { useContext, useState } from "react"
import { IoAlertCircleOutline } from "react-icons/io5"
import { useNavigate } from "react-router-dom"
import api from "../../../../config/connection/axios"
import TrashButton from "../../../Buttons/TrashButton"
import Input from "../../../Form/Inputs/layouts/Input"
import WindowAlertContext from "../../../../context/windowAlert.context"


function RegDelete() {
    const [password, setPassword] = useState<string>('');

    const navigate = useNavigate();
    const windowAlert = useContext(WindowAlertContext);

    const handleAccountDelete = () => {
        const u = JSON.parse(window.localStorage.getItem('serss-user') || '{}')

        if (password.length < 6) return windowAlert?.confirm({ shake: 'Por favor, informe sua senha.' });

        api.post(
            '/users/delete',
            {
                password
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${u.token}`
                }
            }
        )
            .then(() => {
                var formData = new FormData();
                api.post(
                    '/firebase/delete/all',
                    formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'Authorization': `Bearer ${u.token}`
                        }
                    }
                );
                window.localStorage.removeItem('serss-user');
                navigate('/');
            })
            .catch((err) =>
                windowAlert?.confirm({ shake: 'Por favor, remova seus anúncios antes de deletar sua conta.' })
            )

    }

    return (
        <form>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <span className="center" style={{ color: 'tomato', fontSize: '30px' }}>
                    <IoAlertCircleOutline />
                </span>
                <p className="reg-avatar-pick-image" style={{ margin: '0 0 0 10px' }}>Remover minha conta</p>
            </div>
            <div style={{ marginTop: '-40px', display: 'flex', alignItems: 'flex-end' }}>
                <Input
                    placeholder="Informe sua senha"
                    space={0}
                    type='password'
                    value={password}
                    onChange={setPassword}
                />

                <TrashButton funk={(e) => {
                    e.preventDefault();
                    windowAlert?.confirm(
                        {
                            strong: "Remover minha conta",
                            p: "Deseja deletar sua conta?"
                        },
                        handleAccountDelete
                    )
                }} />
            </div>
        </form>
    )
}

export default RegDelete