import { useEffect } from "react"

function Page({ title, children }: { title: string, children: JSX.Element }) {

    useEffect(() => {
        document.title = title;
    }, [])
    return (
        <>
            {children}
        </>
    )
}

export default Page