import { useEffect, useState } from 'react';
import week from '../../../../constants/week.json'
import DcTitle from './DcTitle';
import '../../../../css/card156.css'

function B({ availability = [] }: any) {
    let x = 0;

    const [available, setAvailable] = useState<boolean>(false);

    useEffect(() => {


        const date = new Date();

        /* 
        Na ordem normal o Domingo esta na posição 0 e na ordem 
        do availability na posição 6 portando subtrair 1 
        não funciona nos domingos:
        */
        const normalOrder = date.getDay();
        const day = normalOrder > 0 ? (normalOrder - 1) : 6;
        const hrs = `${date.getHours()}${date.getMinutes()}`;

        

        const similar = availability[day].to;

        const boo = (hrs < similar.replace(':', '') && !availability[day].closed);

        boo && setAvailable(true);
    }, []);

    if (Array.isArray(availability) && Array.isArray(availability[0])) {
        let y = availability.filter((it: Array<string> | null, idx: number) => {
            if (!it) return false;
            if (idx > 4) return false;
            return it[0] === availability[0][0] && it[1] === availability[0][1]
        })

        x = y.length;
    }


    return (
        <div className='DC-card DC-card-B card156' style={{ position: 'relative' }}>
            <DcTitle
                text='Horários'
            />
            {available && <div
                style={{
                    position: 'absolute',
                    top: 14,
                    right: 40,
                    color: 'var(--hover)',
                    border: '1px solid var(--hover)',
                    borderRadius: '12px',
                    padding: '8px 30px'
                }}
            >
                • Disponível agora
            </div>}
            <div className='DC-card-D-content'>
                {
                    Array.isArray(availability) &&
                    <ul>

                        {
                            availability.map((hours: any, idx: number) => {
                                if (x === 5 && idx === 4) return (<Bli text='Segunda à Sexta' hours={hours} idx={idx} />)
                                else if (x === 5 && idx <= 4) return (<></>)
                                else return (<Bli text={week[idx]} hours={hours} idx={idx} />)
                            })
                        }

                    </ul>
                }
            </div>
        </div>
    );
}

const Bli = ({ idx, text, hours }: { idx: number, text: string, hours: { from: string, to: string, closed: boolean } | null }) => (
    <li key={idx}>
        <p>{text}</p>
        {(hours && hours.from && hours.to && !hours.closed) ? <h4>{`${hours.from} - ${hours.to}`}</h4> : <h4>fechado</h4>}
    </li>
)

export default B