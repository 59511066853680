import { Link } from 'react-router-dom'
import ShLi from '../ShLi'
import './styles.css';
function CatList({ type }: { type: string }) {

    const itens = ['Todos os serviços', 'Diarista', 'Engenheiro', 'Estética', 'Desenvolvedor', 'Motoboy', 'Dentista', 'Consultoria', 'Designer', 'Pintor', 'Faxineira'];
    return (

        <ul className={`SB-list-scroll SB-list-scroll--${type}`}>
            {
                itens.map((cat: string, idx: number) => (
                    <Link to={`/search?search=${cat === 'Todas os serviços' ? '' : cat}`} key={idx}>
                        <ShLi text={cat} />
                    </Link>
                ))
            }
        </ul>

    )
}

export default CatList