import { useRef, useState } from 'react';
import Banner from '../Banner';
import './styles.css'
import DirectionButtons from '../../Buttons/DirectionButtons';
import nextPositionX from '../../../functions/nextPositionX';

interface ImagesDetailsSlider_ {
    banners: number | undefined,
    uid: string,
    wid: number,
    premium: number
}

function ImagesDetailsSlider(
    {
        banners,
        uid,
        wid,
        premium
    }: ImagesDetailsSlider_
) {

    const sliderxRef = useRef(null);
    const [currentPercent, setCurrentPercent] = useState<number>(0);

    const logX = () => {
        if (sliderxRef.current) {
            const slideX: any = sliderxRef.current;


            const fullSize = slideX.scrollWidth - slideX.offsetWidth;
            const currentPosition = slideX.scrollLeft;

            setCurrentPercent((currentPosition * 100) / fullSize);
        }
    }

    return (
        <>
            {
                (banners || window.innerWidth > 960) &&
                <div style={{ position: 'relative' }}>
                    <div className='s-x-wrap'>
                        {banners && banners > 2 && <DirectionButtons sliderxRef={sliderxRef} />}
                        <div className='slider-x'>
                            <div className='s-x-center'>
                                {banners && banners > 1 && <div className='s-x-fill' style={{ width: currentPercent + '%' }} />}
                            </div>
                        </div>
                    </div>

                    <div
                        className='ImagesDetailsSlider'
                        ref={sliderxRef}
                        onScroll={logX}
                        style={{
                            justifyContent: banners && banners === 1 ? 'center' : 'flex-start',
                            backgroundColor: banners
                                ? '#222D36'
                                : '#222D3620'
                        }}
                    >
                        {
                            banners &&
                            <>
                                {banners > 0 && <II uid={uid} wid={wid} />}
                                {banners > 1 && <II uid={uid} wid={wid} idx={1} />}
                                {banners > 2 && <II uid={uid} wid={wid} idx={2} />}
                                {banners > 3 && <II uid={uid} wid={wid} idx={3} />}
                                {banners > 4 && <II uid={uid} wid={wid} idx={4} />}
                                {banners > 5 && <II uid={uid} wid={wid} idx={5} />}
                            </>
                        }
                    </div>
                    <div style={{
                        position: 'absolute',
                        width: '100%',
                        bottom: 0,
                        height: '20%',
                        background: 'linear-gradient(0deg, #222d36 0%, transparent 100%)',
                        zIndex: 2

                    }} />
                </div>
            }

            {(!banners && window.innerWidth < 960) && <div style={{ backgroundColor: 'white', height: '86px', width: '100%' }} />}
        </>
    )
}

const II = ({ idx, uid, wid }: { idx?: any, uid: string, wid: number }) => {
    const [hideOnError, setHideOnError] = useState<boolean>(true);
    return (
        <>
            {
                hideOnError &&
                <span className='s-x-banners center'>
                    <Banner
                        uid={uid}
                        wid={wid}
                        type='details'
                        idx={idx ? idx : null}
                        handleOnBannerError={() => setHideOnError(false)}
                    />
                </span>
            }
        </>
    )
}

export default ImagesDetailsSlider;

/*
<div style={{
                position: 'absolute',
                left: '94vw',
                height: '100%',
                top: 0
            }}>
                <span className='s-x-arrow'>
                    <IoArrowForward size={24} />
                </span>
            </div>
            
 */