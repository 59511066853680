import {  IoLogoFacebook, IoLogoInstagram } from "react-icons/io5"
import Icon from "../../../Icon"
import DcTitle from "./DcTitle"
import '../styles.css';
import '../../../../css/card156.css'

function F(
    {
        socialmedia,
        mySelf
    }:
        {
            socialmedia: {
                instagram: string,
                facebook: string,
                linkedin: string,
                site: string
            },
            mySelf: boolean
        }
) {

    return (
        <div className='DC-card DC-card-B card156' id='dc-social-media'>
            <DcTitle
                text='Redes sociais'
            />
            <ul className='social-media-logos'>
                {/*socialmedia.facebook && <a href={`https://facebook.com/${socialmedia.facebook}`} target='_blank'>
                    <li
                        className="center"
                        style={{
                            overflow: 'hidden',
                            width: '76px',
                            height: '76px',
                            borderRadius: '100%',
                            color: 'white',
                            fontSize: '44px',



                            backgroundColor: '#1773ea'
                        }}
                    >
                        <Icon >
                            <IoLogoFacebook />
                        </Icon>
                    </li>
                </a>
                */}
                {socialmedia.facebook && <a href={`https://www.facebook.com/${socialmedia.facebook}`} target='_blank'>
                    <li
                        className="center"
                        style={{
                            overflow: 'hidden',

                            height: '70px',
                            borderRadius: '14px',
                            color: 'white',
                            padding: '0 60px',




                            backgroundColor: mySelf ? '#222d36' : '#1773ea'
                        }}
                    >
                        <Icon>
                            <IoLogoFacebook size={38} />
                        </Icon>
                        <strong style={{ marginLeft: '16px' }}>Facebook</strong>
                    </li>
                </a>}
                {socialmedia.instagram && <a href={`https://www.instagram.com/${socialmedia.instagram}`} target='_blank'>
                    <li
                        className="center"
                        style={{
                            overflow: 'hidden',

                            height: '70px',
                            borderRadius: '14px',
                            color: 'white',
                            padding: '0 60px',




                            background: mySelf ? '#222d36' : 'linear-gradient(45deg, #405de6,#5851db,#c13585,#e1305c,#fd1d1d)'
                        }}
                    >
                        <Icon>
                            <IoLogoInstagram size={38} />
                        </Icon>
                        <strong style={{ marginLeft: '16px' }}>Instagram</strong>
                    </li>
                </a>}
                {/*socialmedia.linkedin && <a href={`https://www.linkedin.com/in/${socialmedia.linkedin}`} target='_blank'>
                    <li
                        className="center"
                        style={{
                            overflow: 'hidden',
                            width: '76px',
                            height: '76px',
                            borderRadius: '100%',
                            color: 'white',
                            fontSize: '44px',



                            backgroundColor: '#305393'
                        }}
                    >
                        <Icon>
                            <IoLogoLinkedin />
                        </Icon>
                    </li>
                    </a>*/}
            </ul>
            {/*
                socialmedia.site && <strong
                    onClick={handleOpenSite}
                    className="center" style={{ color: '#536675', cursor: 'pointer' }}
                >
                    <Icon style={{ transform: 'rotate(135deg)', marginRight: '4px' }}>
                        <IoLink size={18} />
                    </Icon>
                    {socialmedia.site}
                </strong>
            */}
        </div >

    )
}

export default F