import './styles.css'

interface Spinner_ {
    visible: boolean,
    size?: number,
    color?: any,
    children?: any,
    border?: number

}

function Spinner({
    visible,
    size = 20,
    color = '#fff',
    children,
    border = 3
}: Spinner_) {
    return (
        <div className='Spinner'>
            {
                visible
                    ? (
                        <div
                            className="loader"
                            style={{
                                width: size + 'px',
                                height: size + 'px',

                                borderTop: `${border}px solid ${color}`,
                                borderLeft: `${border}px solid ${color}`
                            }}
                        />
                    )


                    : (
                        <>
                            {children}
                        </>
                    )
            }
        </div>
    )
}

export default Spinner