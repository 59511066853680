import { Slide_ } from "../inputs.interfaces";

import '../styles.css';

export function Slide({ text, onChange, value, className, color = '#696969', fontWeight = 500 }: Slide_) {

    const handleCheckboxChange = (event: any) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        onChange(value)
    }

    return (
        <div className='center' style={{ marginTop: text ? '18px' : '' }}>
            {text && <p style={{ display: 'flex', flex: 1, color, fontWeight }}>
                {text}
            </p>}
            <label className="switch">
                <input
                    className={className}
                    checked={value}
                    type="checkbox"
                    onChange={(e: any) => handleCheckboxChange(e)}
                />
                <span className="slider round" />
            </label>
        </div>
    )
}