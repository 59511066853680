import './styles.css'

function Cardboard({ children, style = {} }) {
    return (
        <>
            <div className='Cardboard-green' />
            <div className='Cardboard-center'>
                <div
                    style={style}
                    className='Cardboard-border'
                >
                    {children}
                </div>
            </div>
        </>
    )
}

export default Cardboard