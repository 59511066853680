import { ServiceOrders_ } from "../interface"

type context_ = ServiceOrders_ | undefined

export const handleSocDescLength = (context: context_): boolean => {
    return (context && (context.description.length || context.message.length)) ? true : false
}

export const handleSocMsg = (msg: string | undefined): string => {
    return (`Olá nome_do_usuario, gostaria de ${msg}.`)
}

export const handleSocTextArea = (message: string | undefined, description: string | undefined): string => {
    return ((message ? handleSocMsg(message) : '') + '\n\n' + description).trim()
}

export const handleDateSelected = ( dateSelected:any, number:any, days:any, month:any ): string => {
    return (
        (dateSelected.dnumber === number) &&
        (dateSelected.month === days[month].monthName)
    ) ? "selected" : ''
}

/*
export const handleSocFormatDesc = (description: desc_): string => {

    const d = description ?
        ((description[0] + '\n' + description[1]))
        : ''

    return d.trim().length ? d : '';

}
*/