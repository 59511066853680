import { IoArrowBack } from "react-icons/io5"
import { useNavigate } from "react-router-dom";
//import RegVerifyAccount from "../Containers/RegistrationContainer/layouts/RegVerifyAccount"
import SafeDiv from "../SafeDiv"
import RegPremiumProfile from "../Containers/RegistrationContainer/layouts/RegPremiumProfile";
import './styles.css';

function VerifyYourAcount() {
    const navigate = useNavigate();
    return (
        <SafeDiv >
            <div className="center column" style={{ color: '#222d36' }}>
                <div style={{ textAlign: 'center' }}>
                    <h2 style={{ padding: '0 10px' }}>Considere fazer upgrade do seu plano para poder criar mais anúncios</h2>
                    <p>Planos <strong style={{ color: 'dodgerblue' }}><i>Free</i></strong> ou <strong style={{ color: 'dodgerblue' }}><i>Premium</i></strong> em período de teste possuem limite de <strong>2</strong> anúncios por usuário.</p>
                </div>
                <div className="center" style={{ width: window.innerWidth > 960 ? '80%' : '100%', justifyContent: 'space-around' }}>
                    <div onClick={() => navigate(-1)} className='center column VerifyYourAcountBtn'>
                        <div className="VerifyYourAcountBtn-inner center">
                            <strong className="center"><span className="center" style={{marginRight: '8px'}}><IoArrowBack size={20}/></span>Voltar</strong>
                            
                        </div>
                    </div>
                    <div style={{ width: '580px', paddingBottom: '20px' }}>
                        <RegPremiumProfile />
                    </div>
                </div>
                {window.innerWidth < 960 && <span style={{ height: '50px' }}></span>}
            </div>

        </SafeDiv>
    )
}



export default VerifyYourAcount