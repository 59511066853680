import { IoChevronForward } from "react-icons/io5"
import CentrlMenu_ from "../../../interfaces/centralMenu.interface"

interface CMenuLi_ {
    children: string | JSX.Element,
    setCentralMenu: (s: CentrlMenu_) => void,
    screen: CentrlMenu_['screen'],
    code: CentrlMenu_['code'],
    comment?: string,
    bigger?: boolean
}

const CMenuLi = (
    {
        children,
        setCentralMenu,
        screen,
        code,
        comment,
        bigger = false
    }: CMenuLi_
) => (
    <li
        onClick={() => setCentralMenu({ screen, code })}
        className='CMenuLi'
        style={{height: bigger ? '100px': '' }}
    >
        <strong>{children}</strong>

        {comment && <p>{comment}</p>}

        <span className='center'>
            <IoChevronForward size={30} />
        </span>
    </li>
)

export default CMenuLi;