import { IoPricetag, IoReader } from 'react-icons/io5';
import Input from '../../../Form/Inputs/layouts/Input';
import Select from '../../../Form/Inputs/layouts/Select';
import { TextArea } from '../../../Form/Inputs/layouts/TextArea';
import Parting from './Parting';
import MaxLengthDesc from '../../../MaxLengthDesc';


interface IWcFrist {
    discount: number | string, setDiscount: (value: number) => void,
    title: string, setTitle: (value: string) => void,
    description: string, handleDescription: (value: string) => void,countDesc:number
    price: number | string, setPrice: (value: number | string) => void,
    phone: string, setPhone: (value: string) => void,
    per: string, setPer: (value: string) => void,
}

const WcFrist = ({
    //week,
    discount, setDiscount,
    title, setTitle,
    description, handleDescription, countDesc,
    price, setPrice,
    phone, setPhone,
    per, setPer,

    //method,
    //banners, handleBanners, handleBannersToUpdate

}: IWcFrist) => {
    return (
        <>
            <Input
                label='Nome do serviço ou profissão'
                placeholder='Ex: Medicina'
                value={title}
                onChange={setTitle}
                required
            />
            <TextArea
                label='Descrição'
                placeholder='Descrição do serviço.'
                value={description}
                onChange={handleDescription}
            />
            <MaxLengthDesc countDesc={countDesc}/>
            <Input
                label='Telefone'
                placeholder='Exemplo: (11) 9 9988-7766'
                value={phone}
                onChange={setPhone}
                mask='phone'

            />
            <p style={{ margin: '6px 0 0 10px', fontSize: '.8em', color: '#00000060' }}>Ao informar seu número de contato você concorda em divulga-lo nos seus anúncios.</p>
            <Parting
                text='Preço'
                icon={<IoPricetag size={20} color='#75757599' />}
            />
            <div className='wc-two'>
                <div style={{ width: '48%', position: 'relative' }}>
                    <Input
                        label='Valor (R$)'
                        placeholder='a combinar'
                        value={price}
                        onChange={setPrice}
                        type='number'
                        clear={true}
                    />

                </div>
                <div style={{ width: '48%', position: 'relative' }}>
                    <Input
                        style={{ width: '100%' }}
                        label='Desconto (%)'
                        placeholder='sem desconto'
                        type='number'
                        max={80}
                        min={0}
                        value={discount}
                        onChange={setDiscount}
                        clear={true}
                    />
                </div>
            </div>
            <Select
                label='Forma do pagamento'
                placeholder='não informar'
                value={per}
                onChange={setPer}
                list={
                    [
                        'não informar',
                        'por mês',
                        'por semana',
                        'por dia',
                        'por hora',
                        'empreitada',
                        'consulta',
                        'aproximadamente',
                        'em média'
                    ]
                }
            />
        </>
    )
}

export default WcFrist