import professionsStorage from "../../../constants/firebase/professionsStorage";
import Profession from "../../home/Profession";
import "./styles.css";

function ProfessionsContainer() {
  return (
    <div className="professions-container">
      <Profession
        bki={professionsStorage[0]}
        name="Eletricista"
        search="eletri"
      />

      <Profession
        bki={professionsStorage[1]}
        name="Designer"
        search="design"
      />

      <Profession
        bki={professionsStorage[2]}
        name="Diaristas"
        search="faxineira diarista"
      />
    </div>
  );
}

export default ProfessionsContainer;
