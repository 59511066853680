
import '../styles.css';

export default function LabelTitle({
    label,
    space,
    required
}: any) {
    return (
        <label
            style={{
                marginTop: `${space !== undefined ? space : 18}px`
            }}
            className='Label'
        >
            <p>
                {label}&nbsp;
                {required && <strong style={{ color: 'tomato' }}>*</strong>}
            </p>
        </label>
    )
}