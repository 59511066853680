import GoogleAd from '../GoogleAd';

function AdsSearchSquareOne_mobile() {
    return (
        <div
            style={{
                width: '100%',
                height: '100%',
            }}
        >
            <GoogleAd googleAdId="ca-pub-1196789424751315" slot='6651210897' />
        </div>
    )
}

export default AdsSearchSquareOne_mobile;