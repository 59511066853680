import { IoCalendarOutline, IoChatbox, IoDocumentTextOutline, IoLocationOutline, IoPersonOutline } from "react-icons/io5"
import { SocList_ } from "../interface"

function SocServiceOrdersListDetails({ list }: { list: SocList_ }) {
  return (
    <div>
      <div className="center" style={{ padding: '20px 0 30px 0' }}>
        <div style={{ width: 'calc(100% - 20px)' }}>
          <div className='soc-w-bottom soc-w-bottom--bigger-font' style={{ marginTop: '0', paddingTop: '5px', borderTop: 'none', flexDirection: 'column' }}>
            <div style={{ display: 'flex' }}>
              <span className="center" style={{ marginRight: '4px' }}>
                <IoPersonOutline />
              </span>
              <p>{list.prof_name}</p>
            </div>
            <div style={{ marginTop: '10px', backgroundColor: '#00000005', borderRadius: '6px', padding: '0', display: 'flex', alignItems: 'center', flexDirection: 'column', border: '1px solid var(--border)', overflow: 'hidden' }}>
              <div style={{ backgroundColor: '#00000015', display: 'flex', width: 'calc(100% - 20px)', padding: '6px 10px' }}>
                <p style={{ marginRight: '6px' }} className="center">
                  <IoChatbox />
                </p>
                <p>Resposta</p>
              </div>
              <div style={{ padding: '10px' }}>
                <p style={{ textAlign: 'start' }}>
                  {list.ser_response}
                </p>
              </div>
            </div>
          </div>
          <div className='soc-w-bottom soc-w-bottom--bigger-font' style={{ marginTop: '14px', paddingTop: '14px' }}>
            <div className="center">
              <span className="center" style={{ marginRight: '4px' }}>
                <IoCalendarOutline />
              </span>
              <div style={{ display: 'flex', textAlign: 'left' }}>
                {
                  (list.loc_location || list.loc_place)
                    ? <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <div style={{ display: 'flex' }}>
                        <p>{list.date_week}</p>
                        <p>{list.date_day}</p>
                      </div>
                      <p style={{ fontWeight: 'bold' }}>
                        <i>{list.date_hours}</i>
                      </p>
                    </div>
                    : <p>Endereço a combinar</p>
                }
              </div>
            </div>
          </div>
          <div className='soc-w-bottom soc-w-bottom--bigger-font' style={{ marginTop: '14px', paddingTop: '14px' }}>
            <div className="center">
              <span className="center" style={{ marginRight: '4px' }}>
                <IoLocationOutline />
              </span>
              <div style={{ display: 'flex', textAlign: 'left' }}>
                {
                  (list.loc_location || list.loc_place)
                    ? <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <p>{list.loc_place}</p>
                      <p style={{ fontWeight: 'bold' }}><i>{list.loc_location}</i></p>
                    </div>
                    : <p>Localização a combinar</p>
                }
              </div>
            </div>
          </div>
          {list.price
            ? <div className='soc-w-bottom soc-w-bottom--bigger-font' style={{ marginTop: '14px', paddingTop: '14px', width: '100%', flexDirection: 'column' }}>
              {/*<div style={{ backgroundColor: '#00ae7410', marginBottom: '10px', borderRadius: '2px', padding: '10px 5px' }}>
                <p style={{ color: 'var(--green)' }}>Aceite para contratar ✓</p>
              </div>*/}
              <div style={{ width: '100%' }}>
                <div style={{ width: '100%', display: 'flex', marginBottom: '18px' }}>
                  <div className="center">
                    <span className="center" style={{ marginRight: '4px' }}>
                      <IoDocumentTextOutline />
                    </span>
                    <p>Orçamento:</p>
                  </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'flex-end', width: '95%', marginTop: '-10px' }}>
                  <strong >R$ {list.price}</strong>
                </div>
              </div>
            </div>
            : <div className='soc-w-bottom' style={{ marginTop: '14px', paddingTop: '14px' }}>
              <div className="center">
                <span className="center" style={{ marginRight: '4px', fontSize: '13px' }}>
                  <IoDocumentTextOutline />
                </span>
                <p>Agardando orçamento</p>
              </div>
            </div>
          }
        </div>
      </div>
    </div>
  )
}

export default SocServiceOrdersListDetails