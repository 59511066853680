import GoogleAd from "../GoogleAd"

function AdsUsersDetails({ slot }: { slot: string }) {

    return (
        <div
            style={{
                width: '100%',
                height: '200px',
            }}
        >
            <GoogleAd googleAdId="ca-pub-1196789424751315" slot={slot} />
        </div>
    )
}

export default AdsUsersDetails;