import { useContext, useState } from "react"
import ServiceOrdersContext from "../context"
import { IoArrowBack, IoArrowForward, IoCalendar, IoTime, IoTrashBinOutline } from "react-icons/io5"
import list from '../constants/constants.json';
import generateDaysLeftOfMonth from "../helpers/generateDaysLeftOfMonth";
import { handleDateSelected } from "../helpers/helper";
import { dateSelected_, generateDaysLeftOfMonth_ } from "../interface";
import SocAdbBottom from "./SocAdbBottom";

interface list_ {
  list: Array<string>,
  switcher: 0 | 1
}


function SocDateAndHours() {
  window.scrollTo(0, 0)

  const {
    dateSelected,
    setDateSelected,
    hourSelected,
    setHourSelected,
  } = useContext(ServiceOrdersContext).socDateAndHour1

  const [switcher, setSwitcher] = useState<list_['switcher']>(0)
  const [month, setMonth] = useState<0 | 1>(0)
  const days: generateDaysLeftOfMonth_[] = generateDaysLeftOfMonth()

  const handleSwitcher = () => {
    setSwitcher(s => Boolean(s) ? 0 : 1)
  }

  const handleMonth = () => {
    setMonth(m => Boolean(m) ? 0 : 1)
  }

  const handleClearDateTime = () => {
    setDateSelected({} as dateSelected_)
    setHourSelected('')
  }

  return (
    <div>
      <div style={{ height: '80px' }} />
      <div className="center" >
        <div className="soc-month">
          <div>
            <SocLeftRight switcher={month} handleSwitcher={handleMonth}>
              <div className="soc-month-name">
                <span className="center">
                  <IoCalendar color="var(--dark)" />
                </span>
                &nbsp;
                <strong className="soc-captalize">{days[month].monthName}</strong>
              </div>
            </SocLeftRight>
          </div>
          <div className="soc-date-scroll">
            <div style={{ display: 'flex' }}>
              {
                days[month].monthDays.map((it, idx) =>
                  <SocDaysBullet
                    dnumber={it.number}
                    key={idx}
                    dname={it.name}
                    classname={handleDateSelected(dateSelected, it.number, days, month)}
                    setDateSelected={setDateSelected}
                    month={days[month].monthName}
                  />
                )
              }
            </div>
          </div>
        </div>
      </div>
      <div className="center">
        <div className="soc-orange">
          <SocLeftRight switcher={switcher} handleSwitcher={handleSwitcher}>
            <div className={`soc-month-name`}>
              <span className="center">
                <IoTime color="var(--dark)" />
              </span>
              &nbsp;
              <strong className="soc-captalize">horário</strong>
            </div>
          </SocLeftRight>
          <div className="soc-grid">
            {
              list[switcher].map((it, idx) =>
                <SocHours
                  idx={idx}
                  it={it}
                  classname={hourSelected === it ? 'selected' : ''}
                  setHourSelected={setHourSelected}
                />
              )
            }
          </div>
        </div>
      </div>
      <SocAdbBottom
        txt={
          <strong style={{ color: 'white', marginLeft: '15px' }}>
            {dateSelected.dname} {dateSelected.dnumber} &nbsp;&nbsp; {hourSelected}
          </strong>
        }
      />
      <div style={{ width: '100%', marginTop: '40px' }} className="center">
        <button
          onClick={handleClearDateTime}
          className="center"
        >
          <IoTrashBinOutline />
          <p style={{ marginLeft: '8px' }}>Limpar data e horário</p>
        </button>
      </div>
      <div style={{height:'80px'}}/>
    </div>
  )
}

interface SocHours_ {
  it: string | JSX.Element,
  idx?: number,
  classN?: string,
  classname?: 'selected' | '',
  setHourSelected: any
}

const SocHours = ({ it, idx, classname, setHourSelected }: SocHours_) =>
  <div
    onClick={() => setHourSelected(it)}
    key={idx}
    className={`card156 center soc-grid-div soc-grid-div--${classname}`}
  >
    <strong className="center">{it}</strong>
  </div>

interface SocLeftRight_ {
  switcher: any,
  handleSwitcher: any,
  children?: JSX.Element
}

const SocLeftRight = ({ switcher, handleSwitcher, children }: SocLeftRight_) =>
  <div
    className="soc-hours"
  >
    <button
      onClick={() => handleSwitcher(-1)}
      className="center soc-hours-btn"
      style={{
        opacity: (switcher > 0) ? 1 : 0,
        pointerEvents: (switcher > 0) ? 'all' : 'none'
      }}
    >
      <IoArrowBack />
    </button>
    {children}
    <button
      onClick={() => handleSwitcher(1)}
      className="center soc-hours-btn"
      style={{
        opacity: (switcher < 1) ? 1 : 0,
        pointerEvents: (switcher < 2) ? 'all' : 'none'
      }}
    >
      <IoArrowForward />
    </button>
  </div>

interface SocDaysBullet_ {
  dnumber: number,
  classname?: string
  dname: string,
  setDateSelected: any,
  month: string
}

const SocDaysBullet = ({
  dnumber,
  dname,
  classname = '',
  setDateSelected,
  month
}: SocDaysBullet_) =>
  <div
    onClick={() => setDateSelected({ dnumber, month, dname })}
    className={`card156 soc-bullet-days soc-bullet-days--${classname}`}
  >
    <div className={`soc-bullet-days-name soc-bullet-days-name--${classname}`}>
      <p>{dname}</p>
    </div>
    <div className={'soc-bullet-days-number'}>
      <strong>{dnumber.toString()}</strong>
    </div>
  </div>

export default SocDateAndHours