import {  IoCheckmarkCircle, IoChevronForward } from "react-icons/io5"

function NextButton({ done, onClick }: { done: boolean, onClick: () => void }) {
    return (
        <button
            type="button"
            onClick={onClick}
            className="center" style={{
                backgroundColor: 'var(--dark)',
                height: '40px',
                width: done ? '100%' : '40%',
                borderRadius: '100px',
                color: 'white',
                fontWeight: 'bold',
                position: 'relative'
            }}
        >
            {done ? <IoCheckmarkCircle size={24}/> : 'Próximo'}
            {!done && <span className="center" style={{ position: 'absolute', top: 0, height: '100%', right: '20px' }}>
                <IoChevronForward size={16} />
            </span>}
        </button>
    )
}

export default NextButton