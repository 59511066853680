import Avatar from '../../../Images/Avatar'

import {
    IoAdd, IoCall, IoChatbox,
    IoCheckmark, IoChevronForward,
    IoDiamond, IoLocationOutline, IoLockClosed,
    IoPeople, IoTodayOutline
} from 'react-icons/io5'
import txts from '../txts.json'
import SocProBtn from './SocProBtn'
import SocTextInput from './SocTextInput'
import SocAbsGreen from './SocAbsGreen'
import { useContext } from 'react'
import ServiceOrdersContext from '../context'
import { ServiceOrders_ } from '../interface'

interface SocLocked_ {
    strong: string,
    icon: JSX.Element,
    lock: string
}

interface SocInsideBtn_ { icon: JSX.Element, p: string }

interface SocInput_ {
    t: string,
    btn: string,
    btnP: SocInsideBtn_['p'],
    icon: SocInsideBtn_['icon'],
    onClick: any,
    topContent: string,
    bottomContent: string,
    hasContent: boolean
}

interface SocX_ {
    x: string
    title: string,
    h6: string, colors: {
        title: string,
        backgroundColor: string,
        color: string
    }
}

/* TMP */
const nome_do_usuarioTMP = ''
const prof_do_usarioTMP = ''
const xTMP = 'Informar depois'

function SocMain() {
    const context: ServiceOrders_ = useContext(ServiceOrdersContext);

    return (
        <div className='soc'>
            <div className='soc-green-top' />
            <div className='soc-white-card-wrap center'>
                <div className='soc-white-card card156'>
                    <div className='soc-white-card-container'>
                        <div className='soc-w-top'>
                            <div className='soc-w-left'>
                                <div className='soc-w-avatar-wrap'>
                                    <Avatar uid={' '} />
                                </div>
                            </div>
                            <div className='soc-w-right'>
                                <h2 className='soc-title ellipsis'>{prof_do_usarioTMP}</h2>
                                <h3 className='soc-h3 ellipsis'>{nome_do_usuarioTMP}</h3>
                                <p className='description-ellipsis soc-p'>
                                    {txts.welcome[0]}
                                    &nbsp;
                                    <strong>{nome_do_usuarioTMP}</strong>
                                    {txts.welcome[1]}
                                </p>
                            </div>
                        </div>
                        <div className='soc-w-bottom'>
                            <div>
                                <p>
                                    {txts.price_holder[0]}
                                    <br />
                                    {txts.price_holder[1]}
                                </p>
                            </div>
                            <div className='center'>
                                <strong>{txts.price}</strong>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div className='center'>
                <div className='soc-form'>
                    <SocX
                        x='soc-title'
                        title={txts.title}
                        h6='free'
                        colors={{
                            title: 'var(--dark)',
                            backgroundColor: 'var(--border)',
                            color: 'var(--green)'
                        }}
                    />
                    <p className='soc-p soc-f-p'>{txts.estimate}</p>
                    <SocInput
                        onClick={() => context?.changeOutlet('date')}
                        t={txts.inputs[0]}
                        btn={xTMP}
                        icon={<IoTodayOutline />}
                        btnP='Data e horário'
                        hasContent={
                            Boolean((Object.keys(context.socDateAndHour1.dateSelected).length))
                        }
                        topContent={context.socDateAndHour1.dateSelected.month}
                        bottomContent={
                            `${context.socDateAndHour1.dateSelected.dname}` +
                            ` ${context.socDateAndHour1.dateSelected.dnumber} ` +
                            ` ${context.socDateAndHour1.hourSelected}`
                        }
                    />
                    <SocInput
                        onClick={() => context?.changeOutlet('location')}
                        t={txts.inputs[1]}
                        btn={xTMP}
                        icon={<IoLocationOutline />}
                        btnP='Local e endereço'
                        hasContent={Boolean((context.socLoc1.socPlace.alias !== 'dont_know' || context.socLoc1.socLoc.length))}
                        topContent={context.socLoc1.socPlace.t}
                        bottomContent={context.socLoc1.socLoc}
                    />
                    <SocTextInput
                        t={txts.text_input.pleceholder}
                    />
                    {/*

                        // Adção de imagens!

                        <SocAdd
                        t={txts.inputs[2]}
                    />
                    */}
                </div>
            </div>
            <SocProBtn />

            {/*<div className='center'>
                <div className='soc-border-top'>

                    <div>
                        <div
                            className='center soc-title-pro'
                            style={{
                                borderRadius: '100px',
                                width: 'fit-content',
                                marginBottom: '8px',
                            }}
                        >
                            <span className='center' style={{ marginRight: '5px' }}>
                                <IoCall />
                            </span>
                            <strong>Meios de contato</strong>
                        </div>
                        <p style={{ marginBottom: '15px', }} className='soc-p soc-f-p'>
                            O Chat, o número e os demais meios de contato serão liberados após o pagamento.
                        </p>
                    </div>

                    <div className='card156' style={{ marginTop: '10px' }}>
                        <SocLocked
                            strong='(41) 9 9988...'
                            icon={<IoCall />}
                            lock='copiar número'
                        />
                        <SocLocked
                            strong='Chat'
                            icon={<IoChatbox />}
                            lock='conversar pelo chat'
                        />
                        <button
                            onClick={() => { }}
                            className='soc-form-btn' id='soc-text-i-btn'
                        >
                            <strong className='center'>
                                <div className='center' style={{ marginRight: '6px' }}>
                                    <IoPeople />
                                </div>
                                Entrar em contato
                            </strong>
                        </button>

                    </div>
                </div>
            </div>*/}

            {/*<div className='center'>
                <div className='soc-border-top'>
                    <div>
                        <div
                            className='center soc-title-pro'
                            style={{

                                borderRadius: '100px',
                                width: 'fit-content',

                                marginBottom: '8px',
                            }}
                        >
                            <span className='center' style={{ marginRight: '5px' }}>
                                <IoDiamond />
                            </span>
                            <strong>Nossa recomendação</strong>
                        </div>
                        <p style={{ marginBottom: '15px', }} className='soc-p soc-f-p'>Com o PRO você pode contratar qualquer profissional da maneira que achar mais adequada...</p>
                    </div>
                    <div className='soc-pro card156'>
                        <div style={{ width: '90%', paddingBottom: '12px' }}>
                            <div>
                                <SocX
                                    x='soc-title-pro'
                                    title={'PRO'}
                                    h6='R$ 19,90'
                                    colors={{
                                        title: 'white',
                                        backgroundColor: 'white',
                                        color: 'var(--dark)'
                                    }}
                                />

                            </div>
                            <div>
                                <ul style={{ width: '100%', margin: '30px 0' }}>
                                    <SocLi
                                        t={<p>Contrate à vontade com o PRO!</p>}
                                    />
                                    <SocLi
                                        t={
                                            <p>
                                                <strong style={{ color: 'var(--green)', marginRight: '4px' }}>
                                                    R$ 10,00 de
                                                    cashback
                                                </strong>
                                                na sua primeira contratação
                                            </p>
                                        }
                                    />
                                    <SocLi
                                        t={
                                            <p>
                                                <strong style={{ margin: '0 4px' }}>
                                                    Chat
                                                </strong>
                                                e
                                                <strong style={{ margin: '0 4px' }}>
                                                    Meios de contatos
                                                </strong>
                                                liberados sempre
                                            </p>
                                        }
                                    />

                                </ul>
                            </div>
                            <button style={{ fontSize: 'medium', color: 'white', width: '100%', borderRadius: '8px', background: 'linear-gradient(45deg,var(--green) 58%, var(--hover))', height: '50px' }}>
                                <strong>{txts.pro_btn}</strong>
                            </button>
                            <div className='center'>
                                <p style={{ color: '#ffffff80', margin: '7px 0 0 0', fontSize: '12px' }}>
                                    {txts.pro_or_orc[0]}
                                    &nbsp;
                                    <strong>{txts.pro_or_orc[1]}</strong>
                                    &nbsp;
                                    {txts.pro_or_orc[2]}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>*/}
        </div>
    )
}

const SocInput = ({ t, btn, btnP, icon, onClick, topContent, bottomContent, hasContent }: SocInput_) =>
    <div className='soc-f'>
        <strong className='soc-h3 soc-f-h3'>{t}</strong>
        <button onClick={onClick} className='card156 soc-form-btn soc-f-btn-margin-top-p-relative'>
            <SocAbsGreen />


            {
                hasContent ?
                    (<div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start'
                        }}
                    >
                        <strong style={{textTransform:'capitalize'}}>{(!!topContent) ? topContent : btn}</strong>
                        <SocInsideBtn
                            icon={icon}
                            p={(!!bottomContent) ? bottomContent : btnP}
                        />
                    </div>)
                    : (<div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start'
                        }}
                    >
                        <strong style={{textTransform:'capitalize'}}>{btn}</strong>
                        <SocInsideBtn
                            icon={icon}
                            p={btnP}
                        />
                    </div>)
            }


            <span className='center'><IoChevronForward /></span>
        </button>
    </div>

const SocAdd = ({ t }: { t: string }) =>
    <div className='soc-f'>
        <strong className='soc-h3 soc-f-h3'>{t}</strong>
        <button onClick={() => { }} className='card156 soc-add soc-f-btn-margin-top-p-relative '>
            <SocAbsGreen />
            <span className='center'><IoAdd /></span>
        </button>
    </div>

const SocInsideBtn = ({ icon, p }: SocInsideBtn_) =>
    <div
        className='center'
        style={{ margin: '6px 0 0 0', padding: 0, color: '#808080' }}
    >
        <div className='center' style={{ marginRight: '4px' }}>
            {icon}
        </div>
        <p style={{ margin: 0 }}>{p}</p>
    </div>





const SocX = ({ title, h6, colors, x }: SocX_) =>
    <div className={`${x} soc-title-2`} style={{ color: colors.title }}>
        <strong className='soc-title-pro'>{title}</strong>
        <div className='center soc-gray' style={{ color: colors.color, backgroundColor: colors.backgroundColor }}>
            <h6>{h6}</h6>
        </div>
    </div>

const SocLi = ({ t }: { t: JSX.Element }) => <li style={{ width: '100%', marginBottom: '13px' }}>
    <p style={{ width: '100%', display: 'flex', color: '#c4c4c4' }}>
        <strong className='center' style={{ marginRight: '6px' }}><IoCheckmark /></strong>
        {t}
    </p>
</li>

const SocLocked = ({ strong, icon, lock }: SocLocked_) => <div className='soc-lock'>
    <div >
        <span className='center soc-lock-b'>
            {icon}
        </span>
    </div>
    <div className='center' style={{ width: '75%' }}>
        <strong>{strong}</strong>
        <div
            className='soc-b'
        >
            <strong>{lock}</strong>
            <IoLockClosed />
        </div>

    </div>
</div>

export default SocMain