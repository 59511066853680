
//import Icon from '../../Icon'
import './styles.css'
import { Link } from 'react-router-dom';
import popularProfessionalsStorage from '../../../constants/firebase/popularProfessionalsStorage';

function PopularProfessionals({ children }: any) {

    const iconsList = [
        { url: popularProfessionalsStorage[0], text: 'Designers', city: 'Fortaleza', to: '/search?search=designer' },
        { url: popularProfessionalsStorage[1], text: 'Advogados', city: 'São Paulo', to: '/search?search=advogad' },
        { url: popularProfessionalsStorage[2], text: 'Eletricistas', city: 'Santa Catarina', to: '/search?search=eletricista' },
        { url: popularProfessionalsStorage[3], text: 'Pintores', city: 'São Paulo', to: '/search?search=pintor' },
        { url: popularProfessionalsStorage[4], text: 'Pedreiros', city: 'São Paulo', to: '/search?search=pedreiro' },
    ]

    return (
        <div className='Hide-popC'>
            {children}
            <div id='PopularProfessionals'>
                <ul>
                    {iconsList.map((prof, idx) => (
                        <Link to={prof.to}>
                            <li
                                key={idx}
                                className='center column'
                                style={{ backgroundImage: `url(${prof.url})` }}
                            >
                                <span className='PopProf-gradient' />
                                <div className='PopProf-absolute-bottom center'>
                                    <div>
                                        <strong>{prof.text}</strong>
                                        {/**
                                         <p>
                                            <Icon style={{ margin: '0 5px 0 -1px' }}>
                                                <IoLocationOutline />
                                            </Icon>
                                            {prof.city}
                                        </p>
                                         */}
                                    </div>
                                </div>
                            </li>
                        </Link>
                    ))}
                </ul>
            </div>
        </div>
    )
}

export default PopularProfessionals