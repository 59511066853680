import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import DoubtsButton from "../../components/Buttons/DoubtsButton";
import WorkCreateContainer from "../../components/Containers/WorkCreateContainer"
import ThinHeader from "../../components/Headers/ThinHeader"
import SafeDiv from "../../components/SafeDiv";
import Spinner from "../../components/Spinner";
import api from "../../config/connection/axios";


function Edit() {

  const { work_id } = useParams();

  const [spinner, setSpinner] = useState<boolean>(true)
  const [data, setData] = useState<object>({})

  useEffect(() => {
    const fetchData = async () => {
      if (!isNaN(Number(work_id))) {

        await api.get(`/works/load/${work_id}`)
          .then(r => {
            const result = r.data;
            setData(result || {})
            setSpinner(false)
          })
          .catch(() => setSpinner(false))
      }
    }

    fetchData()
  }, [])

  return (
    <>
      <ThinHeader
        button={<DoubtsButton />}
      />
      {
        spinner
          ?
          <SafeDiv>
            <Spinner
              color='#00ae74'
              visible={spinner}
            />
          </SafeDiv>
          : <WorkCreateContainer
            method='update'
            work_id={work_id}
            data={data}
          />
      }
    </>
  )
}

export default Edit