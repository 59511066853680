function VPFromText() {
    return (
        <div>
            <p style={{ lineHeight: 1.6 }}>
                Quero registrar minha <strong>empresa</strong>, e agora?
            </p>
            <ol>
                <li>
                    <p style={{ lineHeight: 1.6 }}>
                        Se deseja verificar sua empresa, por favor, forneça os dados de um responsável. <strong>Em breve, teremos perfis verificados específicos para empresas</strong>. Agradecemos pela compreensão.
                    </p>
                </li>
                <br />
            </ol>
            <br />


            <p style={{ lineHeight: 1.6 }}>
                Por que solicitamos essas informações:
            </p>
            <ol>
                <li>
                    <p style={{ lineHeight: 1.6 }}>
                        <strong>Para os usuários</strong> que contratam através da nossa plataforma, e também para os seus potenciais clientes, a validação é crucial. Ela aumenta a <strong>segurança</strong> e influencia positivamente na decisão de contratar. Afinal, é mais seguro escolher alguém pela internet sabendo que é uma pessoa real, não é mesmo?
                    </p>
                </li>
                <br />
                <li>
                    <p style={{ lineHeight: 1.6 }}>
                        <strong>Para nós</strong>, enquanto empresa, a validação é essencial para reforçar nossa credibilidade. Desejamos anunciar apenas profissionais devidamente registrados.
                    </p>
                </li>
            </ol>
            <br />



            <p style={{ lineHeight: 1.6 }}>
                O que acontece com os seus dados:
            </p>
            <ol>
                <li>
                    <p style={{ lineHeight: 1.6 }}>
                        Inicialmente, seus dados serão validados; posteriormente, será gerada uma "ficha de identidade", onde todos os dados serão criptografados, contendo seu nome completo, CPF, data de nascimento e endereço. Essa ficha será armazenada em uma parte específica do banco de dados destinada a dados sensíveis. As imagens fornecidas não são mantidas. <strong>Esses dados nunca serão acessíveis, nem mesmo por você</strong>, exceto sob mandado judicial. Claro que você pode solicitar a exclusão a qualquer momento. Ao excluir sua conta, os dados são automaticamente removidos.
                    </p>
                </li>
                <br />
            </ol>
            <br /><br />

            <div style={{ width: '100%', borderBottom: '1px solid #c4c4c4', margin: '-20px 0 50px 0' }} />

            <p style={{ lineHeight: 1.6 }}>
                <strong>Importante:</strong>
            </p>
            <ol style={{ listStyle: 'none' }}>
                <li>
                    <p style={{ lineHeight: 1.6 }}>
                        Você <strong>não</strong> é obrigado a fornecer esses dados para utilizar a plataforma.
                    </p>
                </li>
                <br />
                <li>
                    <p style={{ lineHeight: 1.6 }}>
                        Por favor, informe seus dados apenas se você concordar que isso contribuirá para um ambiente mais seguro para seus potenciais clientes.
                    </p>
                </li>
            </ol>
            <p style={{ lineHeight: 1.6 }}>
                <strong>Lembramos:</strong>
            </p>
            <ol style={{ listStyle: 'none' }}>
                <li>
                    <p style={{ lineHeight: 1.6 }}>
                        Você pode pedir a exclusão desses dados a qualquer momento.
                    </p>
                </li>
            </ol>
            <p style={{ lineHeight: 1.6 }}>
                <strong>Mais informações:</strong>
            </p>
            <ol style={{ listStyle: 'none' }}>
                <li>
                    <p style={{ lineHeight: 1.6 }}>
                        Se ainda ficou com alguma dúvida entre em contato conosco pela <a href="/central" style={{ color: 'dodgerblue', textDecoration: 'underline' }}><strong>central de atendimento</strong></a>.
                    </p>
                </li>
            </ol>
            <br />
            <div style={{ width: '100%', borderBottom: '1px solid #c4c4c4', margin: '10px 0 40px 0' }} />
            <p style={{ lineHeight: 1.6 }}>
                <strong>Prefere não fornecer imagens:</strong>
            </p>
            <ol style={{ listStyle: 'none' }}>
                <li>
                    <p style={{ lineHeight: 1.6 }}>
                        Se você deseja verificar sua conta, mas prefere não fornecer imagens dos seus documentos, pode enviar os dados através do e-mail de contato <strong>suporte@servicess.com.br</strong>. Lembre-se de incluir seu <a href="/central" style={{ color: 'dodgerblue', textDecoration: 'underline' }}><strong>identificador público</strong></a> para facilitar o processo.
                    </p>
                </li>
            </ol>
            <br />

        </div>
    )
}

export default VPFromText