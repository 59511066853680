export const premiumIsExpired = (premium: string): boolean => {
    try {
        const premiumDate = Date.parse(premium);

        if (Number.isNaN(premiumDate)) return true;

        const today = new Date().getTime();

        const date1 = new Date(today);
        const date2 = new Date(premiumDate);

        const diffTime = date2.getTime() - date1.getTime();
        const diffDays = Math.floor(Math.abs(diffTime / (1000 * 3600 * 24)));

        return today > premiumDate ? true : false;
    } catch {
        return true;
    }
}

export const premiumDaysLeft = (premium: string): number => {
    try {
        const premiumDate = Date.parse(premium);

        if (Number.isNaN(premiumDate)) return 0;

        const today = new Date().getTime();

        const date1 = new Date(today);
        const date2 = new Date(premiumDate);

        const diffTime = date2.getTime() - date1.getTime();
        const diffDays = Math.floor(Math.abs(diffTime / (1000 * 3600 * 24)));

        return today > premiumDate ? 0 : diffDays;
    } catch {
        return 0;
    }
}
