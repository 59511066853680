import { Link } from 'react-router-dom'
import findItOutStorage from '../../../constants/firebase/findItOutStorage'
import './styles.css'

function FindItOut() {
    const profs = [
        {
            text: 'Faxina',
            query: 'faxina diarista limpeza',
            url: findItOutStorage[0]
        },
        {
            text: 'Barbeiro',
            query: 'barbeir',
            url: findItOutStorage[1]
        },
        {
            text: 'Desenvolvedor',
            query: 'Desenvolv',
            url: findItOutStorage[2]
        },
        {
            text: 'Para máquinas',
            query: 'maquina',
            url: findItOutStorage[3]
        },
        {
            text: 'Limpeza de estofados',
            query: 'higien',
            url: findItOutStorage[4]
        },
        {
            text: 'Contador',
            query: 'contad contabilidade',
            url: findItOutStorage[5]
        }
    ]
    return (
        <div id='FindItOut' className='center'>
            <div
                className='fio-full fio-quarter fio-b-right'
            >
                <Link to={`/search?search=${profs[5].query}`}>
                    <FioGrad
                        url={profs[5].url}
                    />
                    <strong>{profs[5].text}</strong>
                </Link>
            </div>
            <div className='fio-quarter fio-b-right '>
                <div
                    className='fio-smaller'
                >
                    <Link to={`/search?search=${profs[1].query}`}>
                        <FioGrad
                            url={profs[1].url}
                        />
                        <strong>{profs[1].text}</strong>
                    </Link>
                </div>
                <div
                    className='fio-bigger fio-b-top'
                >
                    <Link to={`/search?search=${profs[2].query}`}>
                        <FioGrad
                            url={profs[2].url}
                        />
                        <strong>{profs[2].text}</strong>
                    </Link>
                </div>
            </div>
            <div className='fio-quarter'>
                <div
                    className='fio-bigger'
                >
                    <Link to={`/search?search=${profs[3].query}`}>
                        <FioGrad
                            url={profs[3].url}
                        />
                        <strong>{profs[3].text}</strong>
                    </Link>
                </div>
                <div
                    className='fio-smaller fio-b-top'
                >
                    <Link to={`/search?search=${profs[4].query}`}>
                        <FioGrad
                            url={profs[4].url}
                        />
                        <strong>{profs[4].text}</strong>
                    </Link>
                </div>
            </div>
            <div
                className='fio-full fio-quarter fio-b-left'
            >
                <Link to={`/search?search=${profs[0].query}`}>
                    <FioGrad
                        url={profs[0].url}
                    />
                    <strong>{profs[0].text}</strong>
                </Link>
            </div>
        </div>
    )
}

const FioGrad = ({ url }: { url?: string }) => (
    <aside
        style={{
            backgroundImage: `url(${url ? url : findItOutStorage[6]})`
        }}
    >
        <span className='fio-gradient' />
    </aside>
)

export default FindItOut