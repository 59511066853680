import { useState } from 'react';
import { IoDiamondOutline, IoIdCardOutline, IoOptionsOutline, IoSettingsOutline, IoShieldOutline } from 'react-icons/io5'
import { useLocation } from 'react-router-dom';
import Icon from '../../Icon';
import RegAvatar from '../../Images/RegAvatar/RegAvatar';

import RegCMyRegistration from './layouts/RegCMyRegistration';
import RegCResetPassword from './layouts/RegCResetPassword';
import RegDelete from './layouts/RegDelete';
import RegLogOut from './layouts/RegLogOut';
import './styles.css'
import RegIsVerifyMessage from './layouts/RegIsVerifyMessage';
import iosIsBoring from '../../../functions/iosIsB';
import RegPremiumProfile from './layouts/RegPremiumProfile';
import RegCAskToRemoveData from './layouts/RegCAskToRemoveData';
//import axios from 'axios';

function RegistrationContainer() {

    const location: any = useLocation();
    const hash = location.hash;
    const wid = location.state?.wid;

    const initial = parseInt(hash.replace('#', ''));

    const [selected, setSelected] = useState<number>(
        (typeof initial === 'number' && !Number.isNaN(initial))
            ? initial
            : 0
    )
    const [open, setOpen] = useState<boolean>(false)

    return (
        <div>
            <div id='RegistrationContainer'>
                <button
                    className='open-side-reg'
                    style={{ backgroundColor: 'white' }}
                    onClick={() => setOpen(open ? false : true)}
                >
                    <Icon>
                        <IoOptionsOutline size={24} />
                    </Icon>
                </button>
                {open && <div style={{
                    position: 'absolute',
                    display: 'flex',
                    flex: 1,
                    backgroundColor: '#00000040',
                    height: '100vh',
                    width: '100vw',
                    zIndex: 3
                }}
                    onClick={() => setOpen(false)}
                />}
                <div
                    className={`RegC-left ${open ? 'r-true' : 'r-false'}`}>
                    <ul>
                        <li
                            className={selected === 0 ? 'reg-li-selected' : ''}
                            onClick={() => {
                                setOpen(false)
                                setSelected(0)
                            }}
                        >
                            <span>
                                <IoSettingsOutline />
                            </span>
                            <strong>Configurações</strong>
                        </li>
                        <li
                            className={selected === 1 ? 'reg-li-selected' : ''}
                            onClick={() => {
                                setOpen(false)
                                setSelected(1)
                            }}
                        >
                            <span>
                                <IoShieldOutline />
                            </span>
                            <strong>Gerenciar dados</strong>
                        </li>
                        {wid && <li
                            className={selected === 2 ? 'reg-li-selected' : ''}
                            onClick={() => {
                                setOpen(false)
                                setSelected(2)
                            }}
                        >
                            <span>
                                <IoDiamondOutline />
                            </span>
                            <strong>Perfil  Premium</strong>
                        </li>}
                        <li
                            className={selected === 3 ? 'reg-li-selected' : ''}
                            onClick={() => {
                                setOpen(false)
                                setSelected(3)
                            }}
                        >
                            <span>
                                <IoIdCardOutline />
                            </span>
                            <strong>Perfil Verificado</strong>
                        </li>
                        {/*<li
                            className={selected === 4 ? 'reg-li-selected' : ''}
                            onClick={() => {
                                setOpen(false)
                                setSelected(4)
                            }}
                        >
                            <span>
                                <IoStarOutline />
                            </span>
                            <strong>Perfil parceiro</strong>
                        </li>*/}
                        <li
                            className={selected === 5 ? 'reg-li-selected' : ''}
                            onClick={() => {
                                setOpen(false)
                                setSelected(5)
                            }}
                        >
                            <span>
                                <IoDiamondOutline />
                            </span>
                            <strong>Meus planos</strong>
                        </li>
                    </ul>
                </div>
                <div
                    className='RegC-mid'
                >
                    {
                        selected === 0 &&
                        <>
                            <div className='RegC-title'>
                                <h2>Dados pessoais</h2>
                                <p>Configure seus dados pessoais</p>
                            </div>
                            {
                                iosIsBoring.camera() &&
                                <div className='RegC-card'>
                                    <RegAvatar />
                                </div>
                            }
                            <div className='RegC-card'>
                                <RegCMyRegistration />
                            </div>
                            <RegLogOut />
                        </>
                    }
                    {
                        selected === 1 &&
                        <>
                            <div className='RegC-title'>
                                <h2>Gerenciamento de dados</h2>
                                <p>Gerenciamento e exclusão de dados sensíveis</p>
                            </div>
                            <div className='RegC-card'>
                                <RegCResetPassword />
                            </div>
                            <div className='RegC-card'>
                                <RegDelete />
                            </div>
                            <div className='RegC-card'>
                                <RegCAskToRemoveData />
                            </div> 
                        </>
                    }
                    {
                        selected === 3 &&
                        <>
                            <div className='RegC-title'>
                                <h2>Perfil verificado</h2>
                                <p>Um ambiente mais seguro e confiável</p>
                            </div>
                            <RegIsVerifyMessage />
                        </>
                    }
                    {
                        selected === 5 &&
                        <>
                            <div className='RegC-title'>
                                <h2>Plano Premium</h2>
                                <p>Um plano completo para você</p>
                            </div>
                            <RegPremiumProfile />
                        </>
                    }
                </div>
            </div>
        </div>
    )
}

export default RegistrationContainer