import { IoChevronForward } from "react-icons/io5";
import SocAbsGreen from "./SocAbsGreen";
import txts from '../txts.json'
import { useContext } from "react";
import ServiceOrdersContext from "../context";
import { handleSocTextArea } from "../helpers/helper";


const SocTextInput = ({ t }: { t: string }) => {

    const context = useContext(ServiceOrdersContext)

    return (
        <div
            className='soc-f' style={{ cursor: 'pointer' }}
            onClick={() => context?.changeOutlet('description')
            }
        >
            <strong className='soc-h3 soc-f-h3'>
                {txts.text_input.title[0]}
                &nbsp;
                {'o profissional'}
                &nbsp;
                {txts.text_input.title[1]}
            </strong>
            <form className='card156 soc-f-btn-margin-top-p-relative'>
                <SocAbsGreen />
                <textarea
                    style={{ pointerEvents: 'none' }}
                    /*ref={focusRef2}*/
                    value={handleSocTextArea(context?.message, context?.description)}
                    id='soc-text-input'
                    className='soc-text-input'
                    placeholder={t}
                />
                <button
                    style={{ pointerEvents: 'none' }}
                    className='soc-form-btn'
                    id='soc-text-i-btn'
                >
                    <strong>{txts.text_input.btn}</strong>
                    <span className='center'><IoChevronForward /></span>
                </button>
            </form>
        </div>
    )
}

export default SocTextInput