import { useEffect, useState } from 'react'
import api from '../../../../config/connection/axios';
import RegVerifyAccount from './RegVerifyAccount';
import Spinner from '../../../Spinner';
import RegIsV from './RegIsV';

function RegIsVerifyMessage() {

  const [isVerified, setIsVerified] = useState<boolean>(false);
  const [spinner, setSpinner] = useState<boolean>(false);

  useEffect(() => {
    const fetchData = async () => {

      const u = JSON.parse(window.localStorage.getItem('serss-user') || '{}');

      await api.get(
        `/mixed/verified/${u.uid}`,
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${u.token}`
        }
      }
        )
    .then(r => {
      const result = r.data;
      setIsVerified(result.verified)
      setSpinner(false)
    })
    .catch(() => setSpinner(false))

}

fetchData()
  }, [])
return (
  <>
    {spinner && <div className='RegC-card'><Spinner visible color='#00ae74' /></div>}
    {(!spinner && !isVerified) && <RegVerifyAccount />}
    {(!spinner && isVerified) && <RegIsV text='Perfil verificado com sucesso!' />}
  </>
)
}



export default RegIsVerifyMessage