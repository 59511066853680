import { useEffect, useState } from 'react';
//import Input from '../../../../Form/Inputs/layouts/Input';
import { Slide } from '../../../../Form/Inputs/layouts/Slide';
import './styles.css';


interface Hours_ {
    to: string,
    from: string,
    closed: boolean
}

interface HoursTable_ {
    hours: Array<Hours_>,
    setHours: React.Dispatch<
        React.SetStateAction<{
            from: string;
            to: string;
            closed: boolean;
        }[]>
    >
}

function HoursTable({ hours, setHours }: HoursTable_) {
    const [firstChange, setFirstChange] = useState({
        to: false,
        from: false
    })

    const handleHours = {
        closed(idx: number) {
            setHours(h => h.map((it, i) => i === idx ? { ...it, closed: it.closed ? false : true } : it))
        },

        to(idx: number, to: string) {
            if (!firstChange.to) {
                setHours(h => h.map((it, i) => i <= 4 ? { ...it, to } : it));
                setFirstChange({ ...firstChange, to: true })
            }
            setHours(h => h.map((it, i) => i === idx ? { ...it, to } : it))
        },

        from(idx: number, from: string) {
            if (!firstChange.from) {
                setHours(h => h.map((it, i) => i <= 4 ? { ...it, from } : it));
                setFirstChange({ ...firstChange, from: true })
            }
            setHours(h => h.map((it, i) => i === idx ? { ...it, from } : it))
        }
    }

    return (
        <table id='HoursTable'>
            <tr>
                <th>Dias</th>
                <th>De</th>
                <th>Até</th>
                <th>Fechado</th>
            </tr>
            <HoursRow
                text='Seg.'
                handleHours={handleHours}
                hours={hours}
                idx={0}
            />

            <HoursRow
                text='Ter.'
                handleHours={handleHours}
                hours={hours}
                idx={1}
            />
            <HoursRow
                text='Qua.'
                handleHours={handleHours}
                hours={hours}
                idx={2}
            />
            <HoursRow
                text='Qui.'
                handleHours={handleHours}
                hours={hours}
                idx={3}
            />
            <HoursRow
                text='Sex.'
                handleHours={handleHours}
                hours={hours}
                idx={4}
            />
            <HoursRow
                text='Sab.'
                handleHours={handleHours}
                hours={hours}
                idx={5}
            />
            <HoursRow
                text='Dom.'
                handleHours={handleHours}
                hours={hours}
                idx={6}
            />

        </table>
    )
}

const HoursRow = (
    {
        text,
        hours,
        handleHours,
        idx
    }:
        {
            text: string,
            hours: any,
            handleHours: { closed(idx: number): void; to(idx: number, to: string): void; from(idx: number, from: string): void; },
            idx: number
        }) => (
    <tr>
        <td
            style={{ color: hours[idx].closed ? '#00000060' : 'black' }}
        >
            {text}
        </td>
        <td>
            <input

                type='time'
                value={hours[idx].from}
                onChange={(e: any) => handleHours.from(idx, e.target.value)}
                disabled={hours[idx].closed}
            />
        </td>
        <td>
            <input
                type='time'
                value={hours[idx].to}
                onChange={(e: any) => handleHours.to(idx, e.target.value)}
                disabled={hours[idx].closed}
            />
        </td>
        <td>
            <Slide
                className='tomato'
                value={hours[idx].closed}
                onChange={() => handleHours.closed(idx)}
            />
        </td>
    </tr>
)

export default HoursTable