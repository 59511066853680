// Telefone:
export const maskPhone = (value:any) => {
  return value
    .replace(/\D/g, "")
    .replace(/(\d{2})(\d)/, "($1) $2")
    .replace(/(\d{5})(\d)/, "$1-$2")
    .replace(/(-\d{4})(\d+?)$/, "$1");
};

// ou 
// export const maskPhone = (value) => {
//   return value
//     .replace(/\D/g, "")
//     .replace(/(\d{2})(\d)/, "($1) $2")
//     .replace(/(\d{5})(\d{4})(\d)/, "$1-$2");
// };

/*

// CPF:
export const maskCPF = (value) => {
  return value
    .replace(/\D/g, "")
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d{1,2})/, "$1-$2")
    .replace(/(-\d{2})\d+?$/, "$1");
};

// APENAS NÚMEROS:
export const maskOnlyNumbers = (value) => {
  return value.replace(/\D/g, "");
};

// DATA:
export const maskDate = (value) => {
  return value
    .replace(/\D/g, "")
    .replace(/(\d{2})(\d)/, "$1/$2")
    .replace(/(\d{2})(\d)/, "$1/$2")
    .replace(/(\d{4})(\d)/, "$1");
};

// APENAS LETRAS:
export const maskOnlyLetters = (value) => {
  return value.replace(/[0-9!@#¨$%^&*)(+=._-]+/g, "");
};

// CEP:
export const maskCEP = (value) => {
  return value.replace(/\D/g, "").replace(/^(\d{5})(\d{3})+?$/, "$1-$2");
};

*/