import { useState } from 'react';
import { IoAdd } from 'react-icons/io5';
import api from '../../../config/connection/axios';
import regex from '../../../functions/regex';
import TrashButton from '../../Buttons/TrashButton';
import Input from '../../Form/Inputs/layouts/Input';

import Modal from '../../Modal'
import Spinner from '../../Spinner';
import './styles.css'

function ChangePhone({ visible, setVisible, updatePhoneState }: { visible: boolean, setVisible: (value: boolean) => void, updatePhoneState: (n: string) => void }) {

    const [phone, setPhone] = useState<string>('');
    const [spinner, setSpinner] = useState<boolean>(false);

    const handleChangePhone = async (e: Event) => {

        e.preventDefault()

        setSpinner(true);

        const u = JSON.parse(window.localStorage.getItem('serss-user') || '{}');

        const replacedPhone = phone.replace(/\D/g, '');

        if (!regex.phone.test(replacedPhone)) return;
        if (u.phone === replacedPhone) return;

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${u.token}`
        }

        await api.put(
            '/users/updates/phone',
            {
                phone: replacedPhone
            },
            {
                headers
            }
        )
            .then(() => {
                window.localStorage.setItem('serss-user', JSON.stringify(
                    { ...u, phone }
                ));
                setVisible(false);
                updatePhoneState(replacedPhone);
            })
            .catch(error => {
                console.error(error)
                setVisible(false)
            });

    }

    return (
        <Modal
            visible={visible}
            setVisible={setVisible}
            children={
                <div id='ChangePhone'>
                    <div style={{ display: 'flex', alignItems: 'flex-end', marginTop: '-20px' }}>
                        <Input
                            space={0}
                            label='Telefone'
                            placeholder='(11) 9 9988-7766'
                            value={phone}
                            onChange={setPhone}
                            mask='phone'

                        />
                        <TrashButton
                            color='white'
                            backgroundColor='var(--hover)'
                            children={spinner ? <Spinner visible={spinner} /> : <IoAdd size={26} />}
                            funk={handleChangePhone}
                        />
                    </div>
                    <p style={{ margin: '6px 0 0 10px', fontSize: '.8em', color: '#00000060' }}>Ao informar seu número de contato você concorda em divulga-lo nos seus anúncios.</p>
                </div>
            }
        />
    )
}

export default ChangePhone