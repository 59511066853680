interface RegBorderBut_ {
    icon: JSX.Element, 
    title: string, 
    text?: string, 
    button: any,
    children?: JSX.Element
}
const RegBorderBut = ({ icon, title, text, button, children }: RegBorderBut_) => (
    <div className='RegC-title' style={{ border: '2px solid #222d36', borderRadius: '12px', padding: '20px 10% 30px 10%', maxWidth: '100%', marginTop: '20px' }}>
        <h4 className='center'>
            <span className='center' style={{ marginRight: '8px' }}>
                {icon}
            </span>
            {title}
        </h4>
        {text && <p>{text}</p>}

        {children && children}

        {button}

    </div>
)

export default RegBorderBut;