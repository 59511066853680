const RegBtnI = ({ text, color }: { text?: string, color?: string }) => (
    <div
        className='center'
        style={{
            marginTop: '8px',
            fontWeight: 'bolder',
            color: 'white',
            width: '100%',
            borderRadius: '12px',
            backgroundColor: color ? color : '#00ae74',
            padding: '16px 0'
        }}
    >
        {
            text
                ? text
                : 'Eu quero!'
        }
    </div>
)

export default RegBtnI;