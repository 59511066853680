function By(
    { left, top, right,color }:
        {
            left?: number,
            top: number,
            right?: number,
            color?: string
        }
) {
    return (
        <p style={{
            position: 'absolute',
            padding: '8px',
            borderRadius: '4px',
            border: `1px solid #c4c4c4`,
            width: 'fit-content',
            height: 'fit-content',
            opacity: .7,
            left: left ? left : 'auto',
            right: right ? right : 'auto',
            top,
            fontSize: '10px',
            color
        }}
        >
            by
        </p>
    )
}

export default By