import { IoCheckmarkCircleOutline } from 'react-icons/io5'

const RegIsV = ({ text, children }: { text?: string, children?: JSX.Element }) => (
    <div className='RegC-card'>
        <span className='center'>
            <IoCheckmarkCircleOutline size={120} color='#00ae74' />
        </span>
        <div style={{ width: '100%' }} className='center'>
            <h4>{text && text}</h4>
        </div>
        {children && children}
    </div>
)

export default RegIsV