import { IoTrash } from "react-icons/io5"


function TrashButton({ funk, children = <IoTrash />, color = 'tomato', backgroundColor = '#f0beb5' }: { funk: (e: any) => void, children?: JSX.Element, color?: string, backgroundColor?: string }) {
    return (
        <button style={{ height: '42px', width: '64px', margin: '0 0 1px 10px', borderRadius: '8px', backgroundColor, color, fontSize: '1.2em' }} onClick={funk}>
            <span className="center">
                {children}
            </span>
        </button>
    )
}

export default TrashButton