//Components:
import SubHeader from './layouts/SubHeader';
import HIconsFixed from './layouts/HIconsFixed';
import SideBar from '../../SideBar';

import './styles.css';
import { IoSearch } from 'react-icons/io5'
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Location from '../../Locations/Location';
//import Chat from '././../Chat';

import { userData_, HeaderWithoutChat_ } from '../headers.interfaces';
import GoBack from '../../mobile/GoBack';
import Bubble from '../../../Chat/chat-components/Bubble';

//import socket from '../../../config/connection/socket';


function HeaderWithoutChat({
  home = false,
  switcher,
  setSwitcher,
  Chat,
  setChat
}: HeaderWithoutChat_) {
  const navigate = useNavigate();

  const [userData, setUserData] = useState<userData_>({})
  const [scrolling, setScrolling] = useState<boolean>(false);
  const [sideBarIsVisible, setSideBarIsVisible] = useState<Array<any>>([false]);
  const [locationIsVisible, setLocationIsVisible] = useState<boolean>(false);
  const [value, setValue] = useState<string>('')

  const handleSideBar = (bool: any) => {
    setSideBarIsVisible([bool])
  }

  const OpenCatSideBar = () => {
    setSideBarIsVisible([true, 'categories'])
  }

  function handleScroll() {
    if (window.pageYOffset > 40) {
      setScrolling(true)
    } else {
      setScrolling(false)
    }
  }

  window.addEventListener('scroll', handleScroll)

  const handleSearch = (e: any) => {
    e.preventDefault();
    window.sessionStorage.setItem('serss-search', value);
    navigate(`/search?search=${value}`);
  }

  const [serssLocation, setSerssLocation] = useState<string>('Todo o Brasil')

  const toggleLocation = () => {
    const locationData = JSON.parse(window.localStorage.getItem('serss-location') || '{}')
    setSerssLocation(
      JSON.stringify(locationData) === '{}'
        ? 'Todo o Brasil'
        : `${locationData.city_name}, ${locationData.state_name}`
    )
  }

  useEffect(() => {
    toggleLocation()
  }, [window.localStorage.getItem('serss-location'), switcher])

  useEffect(() => {
    let storage = JSON.parse(window.localStorage.getItem('serss-user') || '{}')
    if (typeof storage === 'object') setUserData(storage)

    return window.removeEventListener('scroll', handleScroll)
  }, [])
  return (
    <div className={
      `Header-Container 
      ${(home && !scrolling && window.innerWidth <= 960)
        ? ''
        : 'H-Shadow '}`
    }>
      {
        sideBarIsVisible[0] &&
        <SideBar
          setChat={setChat}
          userUid={userData.uid}
          setSideBarIsVisible={handleSideBar}
          userName={userData.name}
          userProfession={userData.profession}
          setLocationIsVisible={setLocationIsVisible}
          type={sideBarIsVisible[1] && sideBarIsVisible[1]}
        />
      }
      <Location
        locationIsVisible={locationIsVisible}
        setLocationIsVisible={setLocationIsVisible}
        switcher={switcher}
        setSwitcher={setSwitcher}
      />
      <div id="Header">
        {Chat}
        <div className='h-center'>
          <div className='h-c-left'>

            <div className='h-c-l-img-wrap'>
              {!home && <GoBack />}
              <Link to='/'>
                <img className='h-c-l-img' src={require('../../../assets/logo.png')} alt='Logotipo Servicess' />
              </Link>
              <div className='h-c-l-title'>
                <Link to='/'><h1>Servicess</h1></Link>
              </div>
            </div>
          </div>
          <div className='h-c-mid'>
            <div className='h-c-mid-form-wrap'>
              <form className='h-c-mid-form' onSubmit={handleSearch}>
                <input
                  className='h-c-mid-input'
                  placeholder={`${window.innerWidth > 960 ? 'Pesquise por s' : 'S'}erviços ou profissionais`}
                  value={value}
                  onChange={(e) => setValue(e.target.value)}
                />
                <button type='submit' className='h-c-mid-serach-button'>
                  <IoSearch />
                </button>
              </form>
            </div>
          </div>
          <div className='h-c-right'>
            <HIconsFixed
              setSideBarIsVisible={handleSideBar}
              serssLocation={serssLocation}
              uid={userData.uid}
              bubble={
                userData.uid
                  ? <HBubble
                    uid={userData.uid}
                    setChat={setChat}
                    setSideBarIsVisible={setSideBarIsVisible}
                  />
                  : <></>
              }
            />
          </div>
        </div>
      </div>
      {!scrolling && <SubHeader
        setSideBarIsVisible={handleSideBar}
        setLocationIsVisible={setLocationIsVisible}
        serssLocation={serssLocation}
        OpenCatSideBar={OpenCatSideBar}
        uid={userData.uid}
        bubble={
          userData.uid
            ? <HBubble
              uid={userData.uid}
              setChat={setChat}
              setSideBarIsVisible={setSideBarIsVisible}
            />
            : <></>
        }
      />}
    </div>
  );
}

const HBubble = ({ setChat, setSideBarIsVisible, uid }: { setChat: any, setSideBarIsVisible: any, uid?: string }) => (
  <div onClick={(e: any) => {
    setSideBarIsVisible(false);
    if (uid) {
      e.preventDefault();
      setChat(true);
    }
  }}>
    <Bubble uid={uid} type={2} />
  </div>
)

export default HeaderWithoutChat;
