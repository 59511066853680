import { useState } from 'react'
import Modal from '../../Modal'

import GetLocation from '../../GetLocation'
import './styles.css'
import FormButton from '../../Form/Inputs/layouts/FormButton'

//import statesList from '../../constants/statesList.json'

interface ILocation {
  locationIsVisible: boolean,
  setLocationIsVisible: React.Dispatch<React.SetStateAction<boolean>>,
  setSwitcher?: any,
  switcher?: any
}

function Location({ locationIsVisible, setLocationIsVisible, setSwitcher, switcher }: ILocation) {

  const [locationData, setLocationData] = useState({
    state_name: 'Todo o Brasil',
    state_id: null,
    city_name: null,
    city_id: null
  });

  const handleNewLocation = (e: any) => {
    e.preventDefault()
    if (locationData.state_name === 'Todo o Brasil') {
      window.localStorage.setItem('serss-location', '{}')
      if (setSwitcher !== undefined && switcher !== undefined) setSwitcher(switcher ? false : true)
    }
    else {
      window.localStorage.setItem('serss-location', JSON.stringify(locationData))
      if (setSwitcher !== undefined && switcher !== undefined) setSwitcher(switcher ? false : true)
    }

    setLocationIsVisible(false)
  }

  return (
    <Modal
      visible={locationIsVisible}
      setVisible={setLocationIsVisible}
      width='40%'
      height='40%'
      children={
        <form id='Location'>
          <div className='Loc-strong'>
            <strong>Localização</strong>
          </div>
          <GetLocation
            setLocationData={setLocationData}
            city_name=''
            state_name=''
            children={
              <div style={{ marginTop: '16px' }}>
                <FormButton
                  text='Definir'
                  onClick={handleNewLocation}
                />
              </div>
            }
          />
        </form>
      }
    />
  )
}

export default Location