import api from "../../../config/connection/axios";
import regex from "../../../functions/regex";

// Altera o número de telefone:
export const handleChangePhone = async (
    u: any,
    phone: string
) => {
    if (!regex.phone.test(phone.replace(/\D/g, ''))) return;
    if (u.phone === phone.replace(/\D/g, '')) return;

    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${u.token}`
    }

    await api.put(
        '/users/updates/phone',
        {
            phone: phone.replace(/\D/g, '')
        },
        {
            headers
        }
    )
        .then(() => window.localStorage.setItem('serss-user', JSON.stringify(
            { ...u, phone }
        )))
        .catch(error => console.error(error));

}

export const handleFirebaseUpdate = async (
    token: string,
    workID: string,
    banners: Array<any>
) => {
    var formData = new FormData();

    for (let i = 0; i < banners.length; i++) {
        if (banners[i].slice(0, 4) === 'blob') {
            let blob = await fetch(banners[i]).then(r => r.blob());
            formData.append('uploadedBanners', blob, `${banners[i]}`)
        }
    }

    formData.append('work_id', workID);

    api.post(
        '/firebase/banners/update',
        formData,
        {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${token}`
            }
        }
    )
        .catch((error: Error) => console.error(error));
}

// Funções para gerenciar ações na interface:
export const retractHours = (e: Event, setShowHours: any, setHours: any,) => {
    e.preventDefault()
    setShowHours(false)
    setHours(null)
}
export const retractCheckList = (e: Event, setShowCheckList: any, setCheckList: any) => {
    e.preventDefault()
    setShowCheckList(false)
    setCheckList([''])
}
export const retractSocialMedia = (e: Event, setShowSocialMedia: any, setSocialmedia: any) => {
    e.preventDefault()
    setShowSocialMedia(false)
    setSocialmedia({
        instagram: '',
        facebook: '',
        linkedin: ''
    })
}