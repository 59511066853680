import DcTitle from "./DcTitle"
import '../styles.css';
import AdsDetailsWithin_mobile from "../../../Announcements/mobilescreen/AdsDetailsWithin_mobile";
import '../../../../css/card156.css'

function PPublicidade() {
    return (
        <div className='DC-card DC-card-B card156' id='dc-social-media'>
            <DcTitle
                text='Publicidade'
            />
            <div style={{ width: '100%', margin: '-30px 0 30px 0' }}>
                <div>
                    <AdsDetailsWithin_mobile />
                </div>
            </div>
        </div >

    )
}

export default PPublicidade;