import {
    IoHandLeftOutline,
    IoLockClosedOutline,
    IoPlanetOutline,
    IoRocketOutline,
    IoStarOutline
} from 'react-icons/io5'
import './styles.css'

function Stripe() {
    return (
        <div id='Stripe'>
            <div>
                <span>
                    <IoRocketOutline />
                </span>
                <aside>
                    <h4>Rápido</h4>
                    <p>procurou, achou!</p>
                </aside>
            </div>
            <div>
                <span>
                    <IoPlanetOutline />
                </span>
                <aside>
                    <h4>Atendemos</h4>
                    <p>todo o Brasil</p>
                </aside>
            </div>
            <div>
                <span>
                    <IoStarOutline />
                </span>
                <aside>
                    <h4>Novos serviços</h4>
                    <p>todos os dias</p>
                </aside>
            </div>
            <div>
                <span>
                    <IoLockClosedOutline />
                </span>
                <aside>
                    <h4>Seguro</h4>
                    <p>certificado SSL</p>
                </aside>
            </div>
            <div>
                <span>
                    <IoHandLeftOutline />
                </span>
                <aside>
                    <h4>Todos os profissionais</h4>
                    <p>na palma da sua mão</p>
                </aside>
            </div>
        </div>
    )
}

export default Stripe