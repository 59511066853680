import { useState, useEffect, useContext } from 'react'
import './styles.css'
//import week from '../../../constants/week.json'

// Components:
import SafeDiv from '../../SafeDiv'

//import Steps from '../../Steps';

import WcFrist from './layouts/WcFrist';
import WcLocation from './layouts/WcLocation';
import AddMachineList from './layouts/AddMachine/AddMachineList';
import { useNavigate } from 'react-router-dom';
//import StepsButtons from '../../Buttons/StepsButtons';
import api from '../../../config/connection/axios';
import { maskPhone } from '../../../functions/masks';
import FormButton from '../../Form/Inputs/layouts/FormButton';
import { IoAddCircle, IoCalendarClear, IoCamera, IoCheckbox, IoLocationSharp, IoPeople } from 'react-icons/io5';
import Parting from './layouts/Parting';
import Input from '../../Form/Inputs/layouts/Input';
//import AddMachineDetails from './layouts/AddMachine/AddMachineDetails';
import HoursTable from './layouts/HoursTable';
import defaulHours from '../../../constants/defaultHours.json'

//import ImageChangedMessage from '../RegistrationContainer/layouts/ImageChangedMessage';
import WcMessage from './layouts/WcMessage';
//import bannersStorage from '../../../constants/firebase/bannersStorage';
import AddMachineUpdateBanner from './layouts/AddMachine/AddMachineUpdateBanner';
//import YellowAlert from '../../YellowAlert';
import { handleChangePhone, handleFirebaseUpdate, retractCheckList, retractHours, retractSocialMedia } from './functions';
import iosIsBoring from '../../../functions/iosIsB';
import WindowAlertContext from '../../../context/windowAlert.context';


interface IworkCreateContainer {
    method?: "create" | "update",
    work_id?: any,
    data?: any
}

function WorkCreateContainer({ method = 'create', work_id, data = {} }: IworkCreateContainer) {

    const windowAlert = useContext(WindowAlertContext);

    // States de inputs:
    const [locationData, setLocationData] = useState({
        state_name: data.state ? data.state : 'Todo o Brasil',
        state_id: data.city_id ? parseInt(data.city_id.toString().slice(0, 2)) : null,
        city_name: data.city ? data.city : null,
        city_id: data.city_id ? data.city_id : null
    });
    const [banners, setBanners] = useState<Array<string>>([])
    const [title, setTitle] = useState<string>(data.title ? data.title : () => {
        const p = JSON.parse(window.localStorage.getItem('serss-user') || '{}').profession;
        return p ? p : '';
    })
    const [description, setDescription] = useState<string>(data.description ? data.description : '')
    const [countDesc, setCountDesc] = useState<number>(0);
    const [price, setPrice] = useState<number | string>(data.price ? data.price : '')
    const [discount, setDiscount] = useState<number | string>(data.discount ? data.discount : '');
    const [per, setPer] = useState<string>(data.per ? data.per : 'não informar');
    const [details, setDetails] = useState<any>(data.details ? JSON.parse(data.details) : [{ key: '', value: '' }])
    const [remote, setremote] = useState<boolean>(data.remote ? data.remote : false)
    const [district, setDistrict] = useState<string>(data.district ? data.district : '')
    const [address, setAddress] = useState<string>(data.address ? data.address : '')
    const [number, setNumber] = useState<number | string>(data.number ? data.number : '')
    const [zipcode, setZipcode] = useState<number>(data.zipcode ? data.zipcode : 0)
    const [cities, setCities] = useState<Array<any>>(data.cities ? data.cities : [{ city_name: 'test' }])
    const [phone, setPhone] = useState<string>(() => {
        const p = JSON.parse(window.localStorage.getItem('serss-user') || '{}').phone;
        return p ? maskPhone(p) : '';
    });
    const [socialMedia, setSocialmedia] = useState(
        data.socialmedia ? JSON.parse(data.socialmedia || "{instagram: '',linkedin: '',facebook: ''}") :
            {
                instagram: '',
                linkedin: '',
                facebook: ''
            }
    )
    const [checkList, setCheckList] = useState<Array<string>>(data.checklist ? JSON.parse(data.checklist || '[]') : ['']);
    const [hours, setHours] = useState(data.hours ? JSON.parse(data.hours || '[]') : null)

    // States de controle:
    const [spinner, setSpinner] = useState<boolean>(false);
    const [limit, setLimit] = useState<boolean>(false);
    const [showMessage, setShowMessage] = useState<boolean>(false);
    const [haveChange, setHaveChange] = useState<boolean>(false);
    const [showHours, setShowHours] = useState<boolean>(false);
    const [showCheckList, setShowCheckList] = useState<boolean>(false);
    const [showSocialMedia, setShowSocialMedia] = useState<boolean>(false);

    useEffect(() => {
        window.scrollTo(0, 0)
        // Limite de 4 anúncios por pessoa:
        if (method === 'create') {
            const u = JSON.parse(window.localStorage.getItem('serss-user') || '{}')
            api.get(
                '/limit',
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${u.token}`
                    }
                }
            )
                .catch((e) => {
                    if (
                        e.response &&
                        e.response.data &&
                        typeof e.response.data.message === 'string'
                    ) setLimit(true)
                })
        }
    }, [])

    const handleSubmit = async (e: any, alertfy: any) => {
        e.preventDefault();

        if (typeof title !== 'string') return alertfy('Nome do serviço deve ter no mínimo 3 caracteres')
        if (title.trim().length < 3) return alertfy('Nome do serviço deve ter no mínimo 3 caracteres')
        if (description && description.trim().length > 510) return alertfy('Descrição deve ter no máximo 510 caracteres')

        setSpinner(true)

        const u = JSON.parse(window.localStorage.getItem('serss-user') || '{}')

        const body = {
            work_id,
            title: title.trim(),
            description,
            price,
            discount: typeof discount === 'number' ? discount : 0,
            details: JSON.stringify(details.filter((n: { key: string, value: string }) => n.value)),
            remote,
            banners,
            hours: JSON.stringify(hours),
            checklist: JSON.stringify(checkList.filter(n => n)),
            socialmedia: JSON.stringify({ ...socialMedia, instagram: socialMedia.instagram.replace('@', '') }),
            city_id: locationData.city_id,
            district,
            address,
            number: number === 0 ? null : number,
            zipcode: null,
            per: per !== 'não informar' ? per : null

        }

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${u.token}`
        }

        method === 'update'
            ? await api.put(
                `/works/${method}`,
                body,
                { headers }
            )
                .then(async r => {
                    await handleChangePhone(u, phone)
                    //const result = r.data;

                    if (haveChange && banners.length) {
                        handleFirebaseUpdate(u.token, work_id, banners);
                    }

                    setShowMessage(true);
                })
                .catch((e) => {
                    setSpinner(false)
                    if (
                        e.response &&
                        e.response.data &&
                        typeof e.response.data.message === 'string'
                    ) alertfy(e.response.data.message)
                    else alertfy('Por favor tente novamente mais tarde');
                })
            : await api.post(
                `/works/${method}`,
                body,
                { headers }
            )
                .then(async r => {
                    await handleChangePhone(u, phone)
                    const result = r.data;

                    if (haveChange && banners.length) {
                        handleFirebaseUpdate(u.token, result.work_id, banners)
                    }

                    setShowMessage(true);
                })
                .catch((e) => {
                    setSpinner(false)
                    if (
                        e.response &&
                        e.response.data &&
                        typeof e.response.data.message === 'string'
                    ) alertfy(e.response.data.message)
                    else alertfy('Por favor tente novamente mais tarde');
                })
    }

    const addBanner = (url: string) => {
        !haveChange && setHaveChange(true);

        setBanners(b => [...b, url]);
    }

    const removeBanner = (idx: number) => {
        var arr = banners
        arr = arr.filter(function (it, i) {
            return i !== idx
        })

        setBanners(arr);
    }

    const handleKeyDown = (e: any) => {
        if (e.key === 'Enter') {
            e.preventDefault()
        }
    }

    const navigate = useNavigate();
    const handleNext = (f: boolean) => {
        navigate('/profile')
    }

    const handleDescription = (value: string) => {
        setDescription(value);
        setCountDesc(value.length)
    }

    return (
        <div>
            {
                showMessage && <WcMessage
                    visible={showMessage}
                    setVisible={handleNext}
                />
            }

            <div
                style={{
                    width: '100%',
                    height: '380px',
                    backgroundColor: '#00ae74',
                    position: 'absolute',
                    zIndex: 0

                }}
            />
            <SafeDiv>
                <div id='WorkCreate' className='center column' onKeyDown={handleKeyDown}>
                    <form>
                        <h2>Qual serviço você está anunciando?</h2>
                        <WcFrist
                            title={title} setTitle={setTitle}
                            description={description} handleDescription={handleDescription} countDesc={countDesc}
                            phone={phone} setPhone={setPhone}

                            discount={discount} setDiscount={setDiscount}
                            price={price} setPrice={setPrice}
                            per={per} setPer={setPer}
                        />
                        <Parting
                            text='Localização'
                            icon={<IoLocationSharp size={20} color='#75757599' />}
                        />
                        <WcLocation
                            district={district}
                            setDistrict={setDistrict}
                            address={address}
                            setAddress={setAddress}
                            number={number}
                            setNumber={setNumber}
                            remote={remote}
                            setRemote={setremote}
                            locationData={locationData}
                            setLocationData={setLocationData}
                            zipcode={zipcode}
                            setZipcode={setZipcode}
                            cities={cities}
                            setCities={setCities}
                        />
                        <WcBorder />


                        {!showHours
                            ? <WcExpand
                                text='Horários'
                                icon={<IoCalendarClear size={20} />}
                                expand={(a: any) => {
                                    setHours(hours ? hours : defaulHours)
                                    setShowHours(true)
                                }}
                            />
                            : <>
                                <Parting
                                    border={false}
                                    text='Horários'
                                    icon={<IoCalendarClear size={20} color='#75757599' />}
                                    retract={
                                        (event: Event) => {
                                            retractHours(
                                                event,
                                                setHours,
                                                setShowHours
                                            )
                                        }
                                    }
                                />
                                <HoursTable
                                    hours={hours ? hours : defaulHours}
                                    setHours={setHours}
                                />
                            </>
                        }

                        {
                            iosIsBoring.camera() &&
                            <AddMachineWrapper
                                premium={data.premium}
                                banners={banners}
                                addBanner={addBanner}
                                removeBanner={removeBanner}
                            />
                        }

                        {!showCheckList
                            ? <WcExpand
                                text='Lista'
                                icon={<IoCheckbox size={20} />}
                                expand={setShowCheckList}
                            />
                            : <>
                                <Parting

                                    text='Lista'
                                    icon={<IoCheckbox size={20} color='#75757599' />}
                                    retract={(event: Event) => {
                                        retractCheckList(
                                            event,
                                            setShowCheckList,
                                            setCheckList
                                        )
                                    }}
                                />
                                <AddMachineList
                                    list={checkList}
                                    setList={setCheckList}
                                />
                            </>
                        }

                        {!showSocialMedia
                            ? <WcExpand
                                text='Redes sociais'
                                icon={<IoPeople size={20} />}
                                expand={setShowSocialMedia}
                            />
                            : <>
                                <Parting

                                    text='Redes sociais'
                                    icon={<IoPeople size={20} color='#75757599' />}
                                    retract={(event: Event) => {
                                        retractSocialMedia(
                                            event,
                                            setShowSocialMedia,
                                            setSocialmedia
                                        )
                                    }}
                                />
                                <>
                                    <div style={{ position: 'relative' }}>
                                        <Input
                                            style={{ textIndent: '38px' }}
                                            label='Instagram'
                                            placeholder='Ex: nomedeusuario'
                                            value={socialMedia.instagram}
                                            onChange={(e: string) => setSocialmedia((media: any) => ({ ...media, instagram: e }))}
                                        />
                                        <strong style={{ position: 'absolute', left: '16px', bottom: '13px' }}>
                                            {'@'}
                                        </strong>
                                    </div>

                                    <div style={{ position: 'relative' }}>
                                        <Input
                                            style={{ textIndent: '132px' }}
                                            label='Facebook'
                                            placeholder='Ex: nomedeusuario'
                                            value={socialMedia.facebook}
                                            onChange={(e: string) => setSocialmedia((media: any) => ({ ...media, facebook: e }))}
                                        />
                                        <strong style={{ position: 'absolute', left: '16px', bottom: '13px' }}>
                                            {'facebook.com/'}
                                        </strong>
                                    </div>
                                </>
                            </>
                        }
                        {
                            (
                                title &&
                                locationData.city_id
                            )
                                ? <div className='done-button center'>
                                    <FormButton
                                        onClick={handleSubmit}
                                        text='Finalizar'
                                        spinner={spinner}
                                        space={0}
                                        style={{
                                            borderRadius: '100px',
                                            height: '48px',
                                            width: '90%'
                                        }}
                                    />
                                </div>
                                : <div className='done-button center'>
                                    <FormButton
                                        onClick={(e: Event) => { e.preventDefault(); !limit && windowAlert?.confirm({ strong: "Campos obrigatórios", shake: 'Nome do serviço ou profissão, estado e cidade são obrigatórios' }) }}
                                        text={!limit ? 'Finalizar' : 'Limite de 8 anúncios'}
                                        spinner={spinner}
                                        space={0}
                                        style={{
                                            borderRadius: '100px',
                                            height: '48px',
                                            width: '90%',
                                            backgroundColor: !limit ? '#00ae7460' : 'tomato',
                                            border: '1px solid transparent',
                                            color: '#eee'
                                        }}

                                    />
                                </div>}
                    </form>
                    <div style={{ height: '100px' }} />
                </div>
            </SafeDiv>
        </div>
    )
}

const WcExpand = ({ text, icon, expand }: { text: string, icon: any, expand: (value: boolean) => void }) => (
    <button
        onClick={(e: Event | any) => {
            e.preventDefault();
            expand(true)
        }}
        style={{
            display: 'flex',
            border: '1px solid #c4c4c4',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderRadius: '8px',
            height: '74px',
            padding: '0 20px',
            margin: '20px 0 0 0',
            boxShadow: '0 8px 20px 0 rgb(158 184 209 / 23%)',
            color: '#75757599',
        }}>
        <div className='center'>
            <span
                className='center'
                style={{
                    borderRadius: '8px',
                    backgroundColor: '#eee',
                    height: '40px',
                    width: '40px'
                }}>
                {icon}
            </span>
            <strong style={{ fontSize: '16px', marginLeft: '8px' }}>{text}</strong>
        </div>
        <span className='center'>
            <IoAddCircle size={34} color='#75757599' />
        </span>
    </button>
)

const WcBorder = () => (
    <div style={{
        borderTop: '30px solid #eeeeee',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

        width: '110%',
        margin: '0 0 0 -5%',
    }} />
)

const AddMachineWrapper = (
    {
        premium,
        banners,
        addBanner,
        removeBanner
    }: {
        premium: Date | null,
        banners: any,
        addBanner: any,
        removeBanner: any
    }
) => {
    const [visible, setVisible] = useState<boolean>(false);

    return (
        <>
            <WcExpand
                text='Novas imagens'
                icon={<IoCamera size={20} />}
                expand={setVisible}
            />
            {
                visible && <AddMachineUpdateBanner
                    addBanner={addBanner}
                    removeBanner={removeBanner}
                    banners={banners}
                    premium={premium}
                    setVisible={() => setVisible(false)}
                    visible={visible}
                />
            }
        </>
    )

}

export default WorkCreateContainer;