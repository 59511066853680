import './styles.css';

function LanguageButtom({ onClick, left, right }: { onClick: any, left: any, right: any }) {
    return (
        <div style={{ width: '100%', height: '200px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <button className='languageButtom languageButtom-left' onClick={(e) => { e.preventDefault(); onClick(left.n) }}>{left.l}</button>
            <button className='languageButtom languageButtom-right' onClick={(e) => { e.preventDefault(); onClick(right.n) }}>{right.l}</button>
        </div>
    )
}

export default LanguageButtom;