import { useLocation } from "react-router-dom";
import DoubtsButton from "../../components/Buttons/DoubtsButton";
import Cardboard from "../../components/Containers/Cardboard"
import ThinHeader from "../../components/Headers/ThinHeader"
import ValidationCode from '../../components/ValidationCode'

function Validation() {

    const location = useLocation();
    const state: any = location.state;
    const data = state !== null ? state.data : {}
    
    return (
        <>
            <ThinHeader button={<DoubtsButton />}/>
            <Cardboard
                children={
                    <ValidationCode
                        data={data}
                    />
                }
            />
        </>
    )
}

export default Validation