import GreenBorderButton from "../../../Buttons/GreenBorderButton"
import Modal from "../../../Modal"

function ImageChangedMessage(
    { visible, setVisible, avatar }: { visible: boolean, setVisible: any, avatar?: JSX.Element }
) {

    const handleClick = (e: Event) => {
        e.preventDefault();
        setVisible(false);
    }

    return (
        <Modal
            visible={visible}
            setVisible={setVisible}
            closeBtn={false}
            padding={'80px  60px'}
            children={
                <div style={{ textAlign: 'center', maxWidth: '400px', padding: '0 40px' }} className='center column'>
                    {avatar && avatar}
                    <strong style={{ marginTop: '50px', fontSize: '1.2em' }}>{avatar ? 'A foto de perfil será atualizada em breve':'As suas imagens serão atualizadas em breve'}</strong>
                    <p style={{ color: '#00000090' }}>Pode levar <strong>alguns minutos</strong> para que a mudança seja exibida em todos os serviços</p>
                    <GreenBorderButton
                        onClick={handleClick}
                        text='Entendi'
                    />
                </div>
            }
        />
    )
}

export default ImageChangedMessage