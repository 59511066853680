function DcTitle({ text, noBottom = false }: { text: string, noBottom?: boolean }) {
    return (
        <div className={`DC-Title ${noBottom ? 'DC-title-no-bottom' : ''}`}>
            <h3>
                {text}
            </h3>
            <div className="DC-title-gradient" />            
        </div>
    )
}

export default DcTitle