import { IoChevronForward, IoDiamond } from "react-icons/io5"
//import RegAnun from "../Containers/RegistrationContainer/layouts/RegAnun";

const GItems = ({ wid, title, type = 'details', self, premium = 0 }: { wid: number, title: string, type?: 'details' | 'profile', self?: boolean, premium?: number }) => (
    <div
        onClick={() => { window.location.href = `/details/${wid}` }}
        style={
            type === 'profile'
                ? { cursor: 'pointer' }
                : {
                    display: 'flex',
                    width: '120px',
                    flexDirection: 'column',
                    marginRight: '60px',
                    cursor: 'pointer'
                }}
    >
        <span
            className='center column'
            style={{
                width: type === 'profile' ? '60vw' : '100px',
                height: '100px',
                padding: '20px',
                border: '1px solid #c4c4c4',
                borderRadius: '12px',
                position: 'relative',
                overflow: 'hidden',
                backgroundColor: 'white',
                alignItems: 'flex-start',
                boxShadow: type === 'profile' ? '-4px 8px 8px #222d3630' : ''

            }}
        >
            <strong
                className="ellipsis"
                style={{
                    maxWidth: '90%',
                    maxHeight: '50%',
                    color: '#696969',
                    fontSize: '14px',
                    paddingLeft: '0 3px',
                    height: '65%',
                    marginBottom: '8px'
                }}
            >
                {title}
            </strong>
            {
                self &&
                <div style={{ position: 'absolute', right: 12, top: 6 }}>
                    <strong style={{ color: 'dodgerblue' }}>
                        {wid}
                    </strong>
                </div>
            }

            <div style={{ width: '100%', height: '35%', display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
                {(self && premium !== 0) &&
                    <span
                        className="center"
                        style={{
                            height: '100%',
                            marginRight: '10px',
                            background: 'linear-gradient(70deg, #00ae74, #00F3A1)',
                            color: 'white',
                            borderRadius: '100px',
                            padding: '0 12px 0 12px',
                            textShadow: '1px 1px 1px #222D3670'

                        }}
                    >
                        <IoDiamond />
                        {/*<p style={{ marginLeft: '8px', fontSize: '14px' }}>{premium}</p>*/}
                    </span>
                }
                <button style={{ borderRadius: '6px', width: '100%', maxWidth: '140px', backgroundColor: '#c4c4c499', color: '#222d36', height: '100%', display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                    <strong></strong>
                    <span className='center'><IoChevronForward /></span>
                </button>
            </div>
        </span>
    </div>
)

export default GItems;