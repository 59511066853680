import './styles.css';

//Components:
import SafeDiv from '../../SafeDiv';
import Card from '../../Cards/Card';
import Spinner from '../../Spinner';
import { useEffect, useState } from 'react';

import Location from '../../Locations/Location';
import api from '../../../config/connection/axios';
import Select from '../../Form/Inputs/layouts/Select';
import Input from '../../Form/Inputs/layouts/Input';
import { Link } from 'react-router-dom';
import { IoChevronDown, IoClose, IoDiamond, IoEllipsisVertical, IoList, IoOptions, IoTrophy } from 'react-icons/io5';
import Icon from '../../Icon';
import SearchTitles from '../../SearchTitles';
import CardsWithoutImagesWrapper from '../../Cards/CardsWithoutImagesWrapper';
import work_ from '../../../interfaces/works.interface'

//import Emphasis2 from '../../Emphasis2';
//import Emphasis from '../../Emphasis';
import AdsSearchRight_Wild from '../../Announcements/wildscreen/AdsSearchRight_Wild';
import SeeButton from '../../Buttons/SeeButton';
import AdsSearctTop_mobile from '../../Announcements/mobilescreen/AdsSearctTop_mobile';
import AdsSearchSquareOne_mobile from '../../Announcements/mobilescreen/AdsSearchSquareOne_mobile';
import AdsSearchSquareTwo_mobile from '../../Announcements/mobilescreen/AdsSearchSquareTwo_mobile';

interface SearchContainer_ {
    search: string,
    switcher?: boolean,
    setSwitcher?: React.Dispatch<React.SetStateAction<boolean>>
}
interface composedWorks_ {
    works: Array<work_>,
    empty: Array<work_>
}
interface stop_ {
    premium: boolean,
    empty: boolean,
    all: boolean
}

function SearchContainer({
    search = '',
    switcher,
    setSwitcher
}: SearchContainer_) {
    const [offset, setOffset] = useState<number>(0);

    const [premiumWorks, setPremiumWorks] = useState<Array<work_>>([]);
    const [composed, setComposed] = useState<Array<composedWorks_>>([]);
    //const [noImgWorks, setNoImgWorks] = useState<Array<work_>>([]);

    const [locationIsVisible, setLocationIsVisible] = useState<boolean>(false);
    const [loc, setLoc] = useState<string>('');
    const [cityId, setCityId] = useState<string>('');

    const [spinner, setSpinner] = useState<boolean>(false);

    const [stop, setStop] = useState<stop_>({
        premium: false,
        empty: false,
        all: false
    });

    const [showLeft, setShowLeft] = useState<boolean>(false);

    const handleLocation = () => {
        const serssLocation = JSON.parse(window.localStorage.getItem('serss-location') || '{}')
        const city_id = JSON.stringify(serssLocation) === '{}'
            ? ''
            : serssLocation.city_id;

        setLoc(
            (Object.keys(serssLocation).length === 0)
                ? 'Todo o Brasil'
                : serssLocation.city_name + ', ' + serssLocation.state_name
        )
        setCityId(city_id)
        return city_id;
    }

    const handleLoadMore = () => {
        if (!stop.premium) handlePremium()
        else if (!stop.all) handleComposed();
    }

    const handlePremium = async (initialize = false) => {

        if (stop.premium || stop.all) return;


        setSpinner(true);

        await api.get(
            `/cards/list/premium?search=${search}&location=${!cityId.length
                ? handleLocation()
                : cityId}&offset=${offset}`
        )
            .then(r => {
                const result: Array<work_> = r.data;

                initialize
                    ? setPremiumWorks(previous => result)
                    : setPremiumWorks(previous => [...previous, ...result]);
                setSpinner(false);

                // Para se não tiver mais anúncios premium e reiniciar o offset: 
                if (result.length < 21) {
                    setStop({ ...stop, premium: true });
                    setOffset(0);
                } else { setOffset(offset + 1) }

                // Após mandar parar - carrega mais anúncios se não vier nada:
                if (!result.length || (result.length < 6) && offset === 0) { handleComposed(initialize) }
            })
    }

    const handleComposed = async (initialize = false) => {

        if (stop.all) return;

        setSpinner(true);

        await api.get(
            `/cards/list/composed?empty=${stop.empty
                ? stop.empty
                : ''
            }&search=${search}&location=${!cityId.length
                ? handleLocation()
                : cityId}&offset=${offset}`
        )
            .then(r => {
                const result: composedWorks_ = r.data;

                initialize
                    ? setComposed(previous => [result])
                    : setComposed(previous => [...previous, result]);



                setSpinner(false);
                setOffset(offset + 1)

                let tmpStop = stop;

                if (result.works.length < 21) {
                    tmpStop = { ...tmpStop, all: true };
                }

                if (result.empty.length < 8) {
                    tmpStop = { ...tmpStop, empty: true };
                }

                setStop(tmpStop)
            })
    }


    useEffect(() => {
        handlePremium(true)
    }, [])

    const makeLocVisible = (e: any) => {
        e.preventDefault()
        setLocationIsVisible(true)
    }


    const handleCleanFilter = () => {
        setShowLeft(false)
        window.localStorage.setItem('serss-location', '{}')
    }

    return (
        <SafeDiv>
            <Location
                locationIsVisible={locationIsVisible}
                setLocationIsVisible={setLocationIsVisible}
                switcher={switcher}
                setSwitcher={setSwitcher}
            />
            <div id='SearchContainer' style={{ overflow: showLeft ? 'hidden' : '' }}>
                <div className={'SerC-left'}>
                    {/*

                    Descomentar essa linha para reativar o menu de filtros

                    <div className={`SerC-left ${showLeft ? 'showLeft' : ''}`}>
                */}
                    {/*showLeft && (
                        <div className='showLeft-header'>
                            <div onClick={() => setShowLeft(false)}>
                                <Icon>
                                    <IoClose />
                                </Icon>
                            </div>
                        </div>
                    )*/}
                    {/*
                        showLeft && (
                            <div className='showLeft-header showLeft-header--bottom'>
                                <CleanFilter onClick={handleCleanFilter} />
                                <button onClick={() => setShowLeft(false)}>
                                    Filtrar
                                </button>
                            </div>
                        )
                        */}
                    <form className='filter-border'>
                        <ul style={{ height: '100%' }}>
                            {/*<li style={{ height: '20%', paddingTop: '32px' }}>
                                <strong>Ordem</strong>
                                <div style={{ width: '80%' }}>
                                    <Select
                                        list={['Padrão']}
                                        space={14}
                                    />
                                </div>
                            </li>*/}
                            <li style={{ height: '30%', paddingTop: '32px' }}>
                                <strong>Localização</strong>
                                <div
                                    style={{
                                        width: '80%',
                                    }}

                                >
                                    <button
                                        style={{
                                            marginTop: '20px',
                                            height: '48px',
                                            borderRadius: '12px',
                                            fontWeight: 'bold',
                                            color: 'var(--dark)',
                                            boxShadow: '#DEE6EB -2px 2px 6px',
                                            width: '100%',
                                            border: '1px solid var(--border)'
                                        }}
                                        onClick={makeLocVisible}
                                    >
                                        {loc}
                                    </button>
                                </div>
                            </li>
                            {/*<li style={{ height: '40%' }}>
                                <strong>Categorias</strong>
                                <div className='ser-cats' style={{ width: '80%' }}>
                                    <h5>
                                        <Link to='/search?search=pintor'>
                                            Pintor
                                        </Link>
                                    </h5>
                                    <h5>
                                        <Link to='/search?search=diarista'>
                                            Diarista
                                        </Link>
                                    </h5>
                                    <h5>
                                        <Link to='/search?search=pedreiro'>
                                            Pedreiro
                                        </Link>
                                    </h5>
                                    <h5>
                                        <Link to='/search?search=Designer'>
                                            Designer
                                        </Link>
                                    </h5>
                                </div>
                                </li>*/}
                            <li style={{ height: '8%' }} className='SerC-to-hide'>
                                <CleanFilter onClick={handleCleanFilter} />
                            </li>
                        </ul>
                    </form>
                </div>
                <div className='SerC-mid'>
                    <div style={{ width: '100%' }} className='center column'>

                        <SearchFilter setShowLeft={setShowLeft} makeLocVisible={makeLocVisible} />

                        {/*
                            !spinner && <>
                                {(window.innerWidth > 960) && <SearchTitles
                                    title='Destaque'
                                    subtitle='Destacamos o melhor serviço para você'
                                    top={-30}
                                    icon={<IoTrophy size={18} />}
                                />}
                                {// IF HAVE BANNER ??

                                    true
                                        ? <Emphasis
                                            name='CTW PINTURA PREDIAL'
                                            //prof='Faça o seu orçamento sem compromisso'
                                            prof='Nosso compromisso e sempre presta o melhor serviço. Asim podemos ter credibilidade com os nossos clientes a melhor opção pra sua obra está aqui CTW PINTURA PREDIAL '
                                            uid='64339196-2'
                                            wid='342'
                                        />
                                        : <Emphasis2 />
                                }
                            </>
                        */}
                    </div>

                    {/*search && (
                        <h6 id='breadcrumbs'>
                            <p>Pesquisa</p>
                            <p style={{ padding: '0 10px' }}>{' > '}</p>
                            <p>{search}</p>
                        </h6>
                    )*/}

                    {spinner &&
                        <div className='Ser-spinner-wrap center' >
                            <Spinner
                                visible={true}
                                color={'var(--hover)'}
                            />
                        </div>
                    }

                    {
                        premiumWorks.length
                            ? <SercMidGridPremium
                                loc={loc}
                                search={search}
                                offset={offset}
                                pWorks={premiumWorks}
                            />
                            : <></>
                    }

                    {
                        (
                            composed[0] &&
                            (
                                composed[0].works.length ||
                                composed[0].empty.length
                            )
                        )
                            ? composed.map(
                                (composed: composedWorks_, idx: number) =>
                                    <SercMidGrid
                                        key={idx}
                                        composed={composed}
                                        stop={stop}
                                        search={search}
                                    />
                            )
                            : <></>
                    }

                    {
                        (
                            !premiumWorks.length &&
                            composed[0] &&
                            (
                                !composed[0].works.length &&
                                !composed[0].empty.length
                            )
                        ) &&
                        <>
                            <SearchTitles
                                title={`Nenhum resultado encontrado ${search ? `para "${search}"` : ''}`}
                                subtitle={`Seja você o primeiro ${search ? `"${search}"` : ''} da sua cidade!`}
                            />
                            <div style={{ width: '400px', backgroundColor: 'white' }}>
                                <SeeButton text='Anúnciar meu serviço' to={window.localStorage.getItem('serss-user') && window.localStorage.getItem('serss-user') !== '{}' ? '/work/create' : '/signin'} />
                            </div>
                        </>
                    }

                    {(!stop.all) &&
                        <div className='center' style={{ width: '100%', height: 'fit-content', marginTop: '50px' }}>
                            <button
                                onClick={handleLoadMore}
                                className='center'
                                style={{
                                    backgroundColor: '#222D36',
                                    padding: '20px 60px',
                                    borderRadius: '100px',
                                    color: 'white',
                                    fontWeight: 'bolder',
                                    fontSize: '16px',
                                    display: spinner ? 'none' : 'flex'
                                }}
                            >
                                Mais
                                <span className='center' style={{ margin: '0 0 0 4px' }}>
                                    <IoChevronDown size={16} />
                                </span>
                            </button>
                        </div>
                    }

                </div>

                <div className='SerC-right'>
                    <AdsSearchRight_Wild />
                </div>

            </div>
        </SafeDiv>
    )
}

const SercMidGrid = ({ composed, stop, search }: { composed: composedWorks_, stop: stop_, search: string }) => {
    return (
        <>
            <SearchTitles
                title={
                    search
                        ? search.charAt(0).toUpperCase() + search.slice(1)
                        : 'Mais opções'
                }
                subtitle={`Confira mais opções ${search ? `para "${search}"` : ''}`}
            />
            <div className='SerC-mid-grid'>
                {
                    <>
                        {
                            composed.works.map((work: any, idx: any) =>
                                <>
                                    <Card
                                        className='InSearch'
                                        work={work}
                                        key={idx}
                                    />
                                    {
                                        (idx === 3 || idx === 7) &&
                                        <PublicidadeQuadrada>
                                            <AdsSearchSquareTwo_mobile />
                                        </PublicidadeQuadrada>
                                    }
                                </>
                            )
                        }
                    </>
                }
            </div >

            <CardsWithoutImagesWrapper emptyWorks={composed.empty} />
        </>
    )
}

const SercMidGridPremium = ({ pWorks, offset, search, loc }: { pWorks: Array<work_>, offset: number, search: string, loc: string }) => {
    return (
        <>
            {/*<div style={{ width: '100%', height: '180px', flexDirection:'column' }} className='hide-mobile'>
                <Publicidade />
                <div style={{ height: '144px', width: '100%' }} className='center'>
                    <AdsSearctTop_mobile />
                </div>
            </div>*/}
            <SearchTitles
                first
                /*
                title={`
                    ${search
                        ? '"' + search.charAt(0).toUpperCase() + search.slice(1) + '"'
                        : 'Todas profissões'
                    }
                     - 
                    ${loc !== 'Todo o Brasil' ?
                        loc.slice(0, loc.indexOf(','))
                        : loc
                    }
                        `}
                        */
                title={search
                    ? '"' + search.charAt(0).toUpperCase() + search.slice(1) + '"'
                    : 'Todas profissões'}
                subtitle='Os melhores profissionais estão aqui!'
            />
            <div className='SerC-mid-grid'>
                {
                    <>
                        {
                            pWorks.map((work: any, idx: any) =>
                                <>
                                    <Card
                                        className='InSearch'
                                        work={work}
                                        key={idx}
                                    />
                                    {
                                        (idx === 3 || idx === 7) &&
                                        <PublicidadeQuadrada>
                                            <AdsSearchSquareOne_mobile />
                                        </PublicidadeQuadrada>
                                    }
                                </>

                            )
                        }
                    </>
                }
            </div >
        </>
    )
}

const CleanFilter = ({ onClick }: { onClick: any }) => (
    <p
        style={{
            margin: 0,
            textDecoration: 'underline',
            color: '#757575',
            fontSize: '.9em'
        }}
        onClick={onClick}
    >
        <Link to='/search?search='>
            Limpar filtros
        </Link>
    </p>
)


const SearchFilter = ({ setShowLeft, makeLocVisible }: { setShowLeft: any, makeLocVisible: any }) => (
    <div className='SerC-filters-wrapper'>
        <span
            className='center'
            style={{
                position: 'absolute',
                top: 0,
                left: '48%',
                height: '100%',
                color: '#c4c4c4',

            }}>
            <IoEllipsisVertical size={24} />
        </span>
        <div className='SerC-filters'>

            <div className='center'>
                <Link to='/categories'>
                    <div className='center' style={{ paddingLeft: '30px' }}>
                        <span style={{ marginRight: '8px' }}>
                            <IoList size={23} />
                        </span>
                        Categorias
                    </div>
                </Link>
            </div>


            <div
                className='center' style={{ paddingRight: '40px' }}
                /*onClick={() => setShowLeft(true)}*/
                onClick={makeLocVisible}
            >
                <span style={{ marginRight: '8px' }}>
                    <IoOptions size={23} />
                </span>
                Localização
            </div>
        </div>
    </div>
)

const Publicidade = () => (
    <div
        style={{
            color: '#8994a9',
            fontFamily: "Nunito Sans, Helvetica Neue, Helvetica, Arial, sans-serif", margin: '12px 10px 10px 10px'
        }}
    >
        publicidade
    </div>
)

const PublicidadeQuadrada = ({ children }: { children: JSX.Element }) => (
    <div className='hide-mobile' style={{ flexDirection: 'column' }}>
        <Publicidade />
        <div style={{ width: '100%', height: window.innerWidth }}>
            <div style={{ width: '96%', height: '96%' }}>
                {children}
            </div>
        </div>
    </div>
)

export default SearchContainer