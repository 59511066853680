import Avatar from "../../Images/Avatar"
import './styles.css';

function CardPartner({ partner }: { partner: { name: string, uid: string, profession: string } }) {
    return (
        <div
            className='center card-partner'
        >

            <div className="center card-partner-avatar">
                <Avatar
                    uid={partner.uid}
                    borderRadius='20px'
                />
            </div>
            <div style={{ width: 'calc(100% - 182px)', color: '#222D36' }}>
                <p className='home-partner-ellipsis' style={{ opacity: .6, marginTop: 0, marginBottom: '8px', fontWeight:400 }}>{partner.name}</p>
                <strong className='home-partner-ellipsis' style={{ textTransform: 'uppercase' }}>{partner.profession}</strong>
            </div>
        </div>
    )
}

export default CardPartner