import './styles.css';
import {
  IoLocationOutline,
  IoChevronForwardOutline,
  IoReorderFourOutline,
  IoListOutline,
  IoChevronDownOutline,
  //IoBriefcase,
  IoRocketSharp,
  IoPersonCircleOutline,
} from 'react-icons/io5'
import { Link } from 'react-router-dom';
//import Icon from '../../../../Icon';

interface SubHeader_ {
  setSideBarIsVisible: any,
  setLocationIsVisible: any,
  serssLocation: string,
  OpenCatSideBar: () => void,
  bubble: any,
  uid: any

}

function SubHeader({
  setSideBarIsVisible,
  setLocationIsVisible,
  serssLocation,
  OpenCatSideBar,
  bubble,
  uid
}: SubHeader_) {

  const makeVisible = () => {
    setSideBarIsVisible(true)
  }

  const makeLocVisible = () => {
    setLocationIsVisible(true)
  }

  return (
    <div className="SubHeader">
      <div className='sh-center'>
        <div className='sh-c-left'>
          {
            uid
              ? <SHButton
                onClick={makeLocVisible}
                text={serssLocation}
                icon={<IoLocationOutline size={16} />}
              />
              :
              <Link to='/signin'>
                <SHButton
                  text={'Cadastre-se'}
                  icon={<IoPersonCircleOutline size={20} />}
                />
              </Link>
          }
        </div>
        <div className='sh-c-mid'>
          <button className='sh-buttons sh-button-allc sh-cats' onClick={OpenCatSideBar}>
            <div className='sh-icons-wrap sh-icon-list'>
              <IoListOutline />
            </div>
            Principais Categorias
            <span style={{ margin: '5px 20px 0 10px' }}>
              <IoChevronDownOutline />
            </span>
          </button>
          <ul className='sh-cats-list center'>
            <Link to='/search?search='>
              <li>
                <h5>Todos os serviços</h5>
                <span />
              </li>
            </Link>
            <Link to='/search?search=pintor'>
              <li>
                <h5>Pintor</h5>
                <span />
              </li>
            </Link>
            <Link to='/search?search=Designer'>
              <li>
                <h5>Designer</h5>
                <span />
              </li>
            </Link>
            <Link to='/search?search=Diarista'>
              <li>
                <h5>Diarista</h5>
                <span />
              </li>
            </Link>
            {/*
            <Link to='/search?search='>
              <li>
                <h5 style={{ fontWeight: 'bold' }}>Explorar</h5>
                <span />
              </li>
            </Link>
            */}
            {/*
            <span style={{ backgroundColor: '#c4c4c4', width: '1px', height: '18px' }} />
            <Link to='/search?search=jobs&jobs=true'>
              <li>
                <h5 className='center' style={{ fontWeight: 'bold' }}>
                  <div className='center' style={{ marginRight: '8px' }}>
                    <IoRocketSharp />
                  </div>
                  Oportunidades
                </h5>
                <span />
              </li>
            </Link>
            */}
          </ul>
          {bubble && <button className='sh-buttons sh-button-allc sh-visible'>
            <div className='sh-icons-wrap sh-icon-list' style={{ marginRight: '24px' }}>
              {bubble}
            </div>
          </button>}
          <button className='sh-buttons sh-button-allc sh-visible' onClick={makeVisible}>
            <div className='sh-icons-wrap sh-icon-list'>
              <IoReorderFourOutline />
            </div>
          </button>
        </div>
      </div>
    </div>
  );
}

const SHButton = ({ onClick, text, icon }: { onClick?: () => void, text: string, icon: JSX.Element }) => (
  <button className='sh-buttons sh-button-location' onClick={onClick && onClick}>
    <div className='sh-icons-wrap sh-icon-location'>
      {icon}
    </div>
    <p className='ellipsis' style={{ maxWidth: '180px' }}>{text}</p>
    <div className='sh-icons-wrap sh-icon-forward'>
      <IoChevronForwardOutline />
    </div>
  </button>
)
export default SubHeader;
