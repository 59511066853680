import { IoDiamond } from 'react-icons/io5'
import '../../styles.css'
import SubNextButton from '../SubNextButton'

function SubAdvertising({ scrollWin }: { scrollWin?: any }) {
  return (
    <div className='SubContentArea' >
      {
        !!scrollWin &&
        <SubNextButton
          scrollWin={scrollWin}
          text='Premium'
          icon={<IoDiamond />}
          end
        />
      }
    </div>
  )
}

export default SubAdvertising;