import io from 'socket.io-client';
const u = JSON.parse(window.localStorage.getItem('serss-user') || '{}');

/* Production */
const url = 'https://servicess-04d4b6080f33.herokuapp.com/';

/* Development */
//const url = 'http://192.168.0.178:8000'; 

const socket = io(url, {
  query: { token: u.token }
});

export default socket;

/* Old - Comunidade Servicess */
//const url = 'https://servicess-server.herokuapp.com'; 