function SimpleDarkButton(
    {
        onClick,
        disabled = false,
        children,
        height = '60%',
        width = '90%'
    }:
        {
            onClick: any,
            disabled?: boolean,
            children: string | JSX.Element,
            height?: string,
            width?: string
        }
) {
    return (
        <button
            onClick={onClick}
            style={{
                fontWeight: 'bold',
                borderRadius: '12px',
                //border: '1px solid #eee',
                boxShadow: '0 7px 30px 0 rgb(0 0 0 / 20%), 0 2px 6px 0 rgb(0 0 0 / 10%)',
                height,
                width,
                color: 'white',
                fontSize: '16px',
                backgroundColor: '#222D36',
            }}

            disabled={disabled}

            className='center'
        >
            {children}
        </button>
    )
}

export default SimpleDarkButton