import { useState } from "react"
import { IoCall } from "react-icons/io5"
import { Link } from "react-router-dom"
import { maskPhone } from "../../../../functions/masks"

function ShowNumber({ phone, def = true }: { phone: string, def?: boolean }) {
    const [hidden, setHidden] = useState<boolean>(true)

    return (
        <div
            className="center"
            style={{
                color: '#151818',
                justifyContent: def ? 'center' : 'flex-start',
                margin: def ? '14px 0' : 0,
                padding: '10px 0'
            }}
        >
            <span className='center' style={{ backgroundColor: '#222D36', padding: '12px', borderRadius: '100%' }}>
                <IoCall size={16} color='white' />
            </span>
            <span style={{ fontSize: '18px', width: hidden ? '90px' : 'fit-content', margin: '0 20px', fontWeight: 'bold' }} className='ellipsis'>
                {maskPhone(phone)}
            </span>
            {hidden &&
                <Link
                    to={'/login'}
                    target={!navigator.userAgent.match(/expo/i) ? '_blank' : '_self'}
                    onClick={(e) => {
                        if (window.localStorage.getItem('serss-user')) {
                            e.preventDefault()
                            setHidden(false)
                        }
                    }}
                    style={{
                        color: '#222d36',
                        fontSize: '14px',
                        fontWeight: 'bolder',
                        cursor: 'pointer',
                        borderRadius: '100px',
                        border: '1px solid #222D36',
                        padding: '8px 30px',
                    }} className='center'
                >
                    ver número
                </Link>
            }
        </div>
    )
}

export default ShowNumber