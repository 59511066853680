
import { useState } from 'react';
import { IoArrowForwardOutline, IoBookmark, IoDiamond, IoIdCard, IoPricetag, IoPricetags, IoStar, IoVideocam } from 'react-icons/io5';
import { Link } from 'react-router-dom';
import money from '../../../../functions/money';
import AddPhoneNumber from '../../../Buttons/AddPhoneNumber';
import Avatar from '../../../Images/Avatar';
//import Banner from '../../../Images/Banner';
//import Share from '../../../Share';
import Verify from '../../../Verify';
//import Share from '../../../Share';
import ShowNumber from './ShowNumber';
import Bookmarkers from '../../../Bookmarkers';
import '../../../../css/card156.css'

/*
const TEST_links: Array<string> = [
    'https://champi.com.br/wp-content/uploads/2020/04/2020-com-massagem-1-600x377-1-600x300.jpeg.webp',
    'https://w0.peakpx.com/wallpaper/107/85/HD-wallpaper-iphone-11-celular-galaxy-tecnologia.jpg',
    'https://clinicaraizes.com.br/loja/wp-content/uploads/2020/08/Massagem-Desportiva-Masculina.jpg',
    'https://i.pinimg.com/236x/40/7c/cb/407ccb16181f29f01b34b052da1c474b.jpg'
]
*/

function A({
    user_name, user_profession,
    title, price, discount,
    description, remote, user_uid,
    id, per, phone, handleNewPhone,
    updated_at, verified, avatar, partner,
    user_description, premium
}: any) {

    //const [current, setCurrent] = useState(0);



    return (
        <div className='DC-card DC-card-A card156'>
            <div className='a-dc-wraper-1'>
                <div className='a-dc-wraper-2 center'>
                    <div className='a-dc-wraper-3'>
                        <UserOnTopMobile
                            user_description={user_description}
                            description={description}
                            avatar={avatar}
                            user_name={user_name}
                            user_profession={user_profession}
                            verified={verified}
                            user_uid={user_uid}
                        />
                        <div className='DC-max-width' style={{ position: 'relative' }}>
                            <h2 id='a-DC'>{title}</h2>
                            <p id='d-DC' style={{ whiteSpace: 'pre-wrap' }}>
                                {
                                    description
                                        ? description
                                        : ''
                                }
                            </p>
                            <span id='c-DC'>
                                {
                                    (discount && price)
                                        ? (
                                            <div className='price-holder'>

                                                <p>&nbsp;{`R$ ${money(price)}`}&nbsp;</p>
                                                <div style={{ display: 'flex' }}>
                                                    <h4>{`R$ ${money(price, discount)}`}</h4>
                                                    <div style={{ position: 'relative', margin: '-4px 0 0 14px' }}>
                                                        <span style={{ display: 'flex', transform: 'rotate(225deg)' }}>
                                                            <IoPricetag size={46} />
                                                        </span>
                                                        <div style={{ position: 'absolute', display: 'flex', alignItems: 'center', width: '100%', height: '100%', top: 0, left: 0 }}>
                                                            <h6 style={{ margin: '0 0 0 14px', color: 'white' }}>
                                                                {`${discount}%`}
                                                            </h6>
                                                        </div>
                                                    </div>
                                                </div>
                                                {(per && per !== 'não informar') && <strong>{per}</strong>}
                                            </div>
                                        )
                                        : (<div className='price-holder' style={{ display: 'flex', alignItems: 'center' }}>
                                            <div style={{ display: 'flex' }}>
                                                <div className='center' style={{ width: 'fit-content', backgroundColor: 'white', padding: '16px 0px 16px 16px', borderRadius: '12px' }}>
                                                    <span className='center'>
                                                        <IoPricetags color={'#222D36'} size={20} />
                                                    </span>

                                                </div>

                                            </div>
                                            {
                                                price

                                                    ? <>
                                                        <h4>{`R$ ${money(price)}`}</h4>
                                                        {per && <strong>{per}</strong>}
                                                    </>
                                                    : <h4>Valor a combinar</h4>
                                            }

                                        </div>)
                                }
                            </span>
                            {
                                (remote || phone) &&
                                <div
                                    style={{
                                        width: '100%',

                                        height: '1px',
                                        borderBottom: '1px solid #DEE6EB',
                                        paddingTop: '16px',
                                        marginBottom: '-20px',
                                    }}
                                />
                            }
                            <div style={{ marginTop: remote || phone ? '50px' : 0, display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                {phone &&
                                    <ShowNumber phone={phone} def={false} />
                                }
                                {(
                                    (!phone)
                                    &&
                                    (
                                        JSON.parse(window.localStorage.getItem('serss-user') || '{}').uid
                                        ===
                                        user_uid
                                    )
                                ) &&
                                    <div className='DC-show-number'>
                                        <AddPhoneNumber className='on-details' updatePhoneState={handleNewPhone} />
                                    </div>
                                }
                                <div style={{paddingTop: (phone && window.innerWidth <= 790) ? 0 : '30px',}}/>
                                {
                                    (remote || verified || partner || premium) &&
                                    <Bookmarkers
                                        partner={partner}
                                        premium={premium}
                                        verified={verified}
                                        remote={remote}
                                        user_name={user_name}
                                    />

                                }
                                {/*
                                    updated_at && (
                                        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', marginTop: '30px' }}>
                                            <strong style={{ backgroundColor: '#D9D9D934', color: '#5A5A5A90', fontSize: '12px', padding: '6px 26px', borderRadius: '6px' }}>
                                                {updated_at.slice(0, 10).replace(/-/ig, '/')}
                                            </strong>
                                        </div>
                                    )
                                */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}



const UserOnTopMobile = (
    {
        user_uid,
        user_profession,
        user_name,
        verified,
        avatar,
        description,
        user_description
    }:
        {
            user_uid: string,
            user_profession: string,
            user_name: string,
            verified: boolean,
            avatar: boolean,
            description: string,
            user_description: string
        }
) => {

    const [openMe, setOpenMe] = useState<boolean>(false);

    const handleOpenMe = () => {
        return openMe ? setOpenMe(false) : setOpenMe(true);
    }

    return (
        <div id='zero-DC' onClick={handleOpenMe}>
            <div className='zero-DC-u-info'>
                <span>
                    <div className='zero-DC-img center'>
                        <Avatar placeholder='transparent' uid={user_uid} size={16} borderRadius='12px' />
                    </div>
                </span>
                <div className='center column' style={{ alignItems: 'flex-start' }}>
                    {
                        user_profession
                            ? <h6 className='ellipsis' style={{ maxWidth: '50vw' }}>
                                {user_profession}
                            </h6>
                            : <></>
                    }
                    <div className='center'>
                        <h4 className='ellipsis' style={{ maxWidth: '50vw' }}>
                            {user_name}
                        </h4>
                        {
                            verified &&
                            <Verify
                                uid={user_uid}
                                inline
                                haveAvatar={avatar}
                                n={3}
                            />
                        }
                    </div>
                    <div style={{
                        color: '#222d3670',
                        width: '90%',
                        maxHeight: openMe ? '100%' : '0px',
                        overflow: 'hidden',
                        fontSize: '14px',
                        position: 'relative'
                    }}>
                        <p
                            style={{ margin: '4px 0 0 0', maxWidth: '66vw' }}
                            className={openMe ? '' : 'ellipsis'}>
                            {user_description}
                        </p>
                    </div>

                </div>
            </div>
            {openMe &&
                <Link to={`/profile/${user_uid}`} style={{ width: '100%', paddingTop: '20px' }}>
                    <div
                        style={{
                            top: 0,
                            right: 20,
                            height: '36px',

                            width: 'calc(100% - 16px)',
                            borderTop: '1px solid #dee6eb',
                            color: '#0b0e1199',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            padding: '8px 8px 0 8px'

                        }}
                    >
                        Perfil profissional
                        <span className='zero-DC-chevron center'>
                            <IoArrowForwardOutline color='#0b0e1199' />
                        </span>
                    </div>
                </Link>
            }
        </div>
    )
}

export default A