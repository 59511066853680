import GoogleAd from '../GoogleAd';

function AdsDetailsWithin_mobile() {
    return (
        <div
            style={{
                width: '100%',
                height: '100%',
            }}
        >
            <GoogleAd googleAdId="ca-pub-1196789424751315" slot='1744129452' />
        </div>
    )
}

export default AdsDetailsWithin_mobile;