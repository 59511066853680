import React from 'react'
import { IoClose } from 'react-icons/io5'

function ClearButton({handleClear}:{handleClear:any}) {
    return (
        <button
            onClick={(event: Event | any) => {
                event.preventDefault()
                handleClear && handleClear('')
            }}
            className='center'
            style={{
                position: 'absolute',
                right: '16px',
                bottom: '12px',
                backgroundColor: '#c4c4c470',
                borderRadius: '100%',
                padding: '4px',
            }}>
            <IoClose />
        </button>
    )
}

export default ClearButton