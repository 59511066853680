import { useState } from 'react'
import regex from '../../../functions/regex';
import './styles.css'


// Components:
import SafeDiv from '../../SafeDiv'
import GetAnAccount from '../../Form/GetAnAccount'


//import eighteenYears from '../../../functions/eighteenYears';
import { Link, useNavigate } from 'react-router-dom';
import api from '../../../config/connection/axios';
import Input from '../../Form/Inputs/layouts/Input';
//import Select from '../../Form/Inputs/layouts/Select';
import Checkbox from '../../Form/Inputs/layouts/Checkbox';
import FormButton from '../../Form/Inputs/layouts/FormButton';
import Modal from '../../Modal';
import ContactUsCannot from '../../ContactUs/ContactUsConnot';
import ContactUsOnError from '../../ContactUs/ContactUsOnError';

interface Signincontainer {
    mail: string
}

function SigninContainer({ mail }: Signincontainer) {

    const navigate = useNavigate();

    const [haveError, sethaveError] = useState<string | boolean>(false);

    const [name, setName] = useState<string>('');
    const [profession, setProfession] = useState<string>('');
    const [email, setEmail] = useState<string>(mail ? mail : '');
    //const [phone, setPhone] = useState<string>('');
    const [password, setPassword] = useState<string | null>(null);
    //const [birth, setBirth] = useState<string>(new Date().toISOString().slice(0, 10));
    const [eighteenPlus, setEighteenPlus] = useState<boolean>(false);
    //const [gender, setGender] = useState<string>('Masculino');
    const [agree, setAgree] = useState<boolean>(false);
    const [spinner, setSpinner] = useState<boolean>(false);

    const handleSubmit = async (e: any, alertfy: any) => {
        e.preventDefault();

        // Validations
        if (!agree) return alertfy('Confirmar os termos')
        if (!eighteenPlus) return alertfy('Idade mínima: 18 anos');
        if (!regex.email.test(email.trim())) return alertfy('Formato de email invalido')
        //if (!regex.phone.test(phone.replace(/\D/g, ''))) return alertfy('Celular invalido')
        if (typeof name !== 'string') return alertfy('Nome deve ter no mínimo 3 caracteres')
        if (name.trim().length < 3) return alertfy('Nome deve ter no mínimo 3 caracteres')
        if (!password) return alertfy('Senha deve ter no mínimo 6 caracteres')
        if (password && password.length < 6) return alertfy('Senha deve ter no mínimo 6 caracteres')

        if (regex.email.test(name)) return alertfy('Nome Completo ou razão social invalido "@"')
        if (regex.email.test(profession)) return alertfy('Profissão invalida "@"')

        const data = {
            name: name.trim(),
            profession: profession,
            email: email.trim(),
            //phone: phone.replace(/\D/g, ''),
            password,
            //birth,
            //gender: gender.slice(0, 1),
            agree,
        }

        setSpinner(true)

        await api.get(
            `/code/generate/new/user/${email}`
        )
            .then(r => {
                const result = r.data;
                navigate('/validation', {
                    state: {
                        data: data
                    }
                })
            })
            .catch((e) => {
                setSpinner(false)
                if (e && e.response && e.response && e.response.data && e.response.data.message) {
                    if (e.response.data.err === 'err_email409') alertfy('E-mail já está em uso, por favor faça login')
                    else alertfy(e.response.data.message)
                }
                else return sethaveError(e.response.data)
            })

    }

    return (
        <SafeDiv style={{ backgroundColor: '#fff', paddingBottom: '40px' }}>
            <Modal
                visible={Boolean(haveError)}
                setVisible={sethaveError}
                children={<ContactUsOnError />}
            />
            <div id='Sign'>
                <div style={{ width: '100%', maxWidth: '560px', display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexDirection: window.innerWidth < 640 ? 'column' : 'row' }}>
                    {window.innerWidth > 640
                        ? <>
                            <h2 style={{ paddingBottom: '6px', borderBottom: '4px solid #00ae74' }}>Criar seu cadastro</h2>
                            <GetAnAccount to='/login' />
                        </>

                        : <>
                            <div>
                                <GetAnAccount to='/login' />
                            </div>

                            <h2 style={{ margin: '50px 0 0 0', paddingBottom: '6px', borderBottom: '4px solid #00ae74' }}>Criar seu cadastro</h2>
                        </>
                    }
                </div>
                <form autoComplete="off">
                    <Input
                        label='Nome Completo ou razão social *'
                        placeholder='Seu nome e sobrenome'
                        value={name}
                        onChange={setName}

                    />
                    <Input
                        label='Profissão (opcional)'
                        placeholder='Ex: Psicólogo'
                        value={profession}
                        onChange={setProfession}

                    />
                    <Input
                        label='E-mail *'
                        placeholder='exemplo@email.com'
                        value={email}
                        onChange={setEmail}
                    />
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>

                        {/* <div style={{ width: '48%' }}>
                            <Input
                                label='Data de nascimento'
                                placeholder='dd/mm/yyyy'
                                type='Date'
                                value={birth}
                                onChange={setBirth}
                            />
                        </div>*/}

                        {/*
                            <div style={{ width: '48%' }}>
                                <Input
                                    label='Telefone'
                                    placeholder='(00) 0 0000-0000'
                                    value={phone}
                                    onChange={setPhone}
                                    mask={'phone'}
                                />
                            </div>
                        */}
                    </div>
                    {/*
                    <Select
                        label='Gênero'
                        list={['Masculino', 'Feminino', 'Não informar']}
                        value={gender}
                        onChange={setGender}
                    />
                    test
                    */}
                    <Input
                        label='Senha *'
                        placeholder='••••••••'
                        type='password'
                        value={password}
                        onChange={setPassword}
                    />
                    <Checkbox
                        label='A plataforma é exclusiva para maiores de idade:'
                        space={18}
                        text='Declaro ter mais de 18 anos de idade.'
                        value={eighteenPlus}
                        onChange={setEighteenPlus}
                    />
                    <Checkbox
                        label='Os Termos e as políticas devem ser respeitados:'
                        text='Concordo com os termos, condições e políticas, e confirmo que as informações fornecidas são verdadeiras.'
                        space={10}
                        value={agree}
                        onChange={setAgree}
                    />
                    <ContactUsCannot>
                        <FormButton
                            style={{ backgroundColor: '#00ae74', border: '1px solid #00ae74' }}
                            text='Criar conta'
                            onClick={handleSubmit}
                            spinner={spinner}
                        />
                    </ContactUsCannot>
                </form>
                <div className='Sign-Policy'>
                    <p>
                        Ao criar o seu cadastro, você confirma a veracidade das informações fornecidas e concorda com {'a '}
                        <span><Link to='/politica_de_privacidade' target="_blank">Política de Privacidade</Link></span> e
                        {' os'} <span><Link to='/terms' target="_blank">Termos e Condições de Uso</Link></span>.
                    </p>
                </div>
            </div>
        </SafeDiv>
    )
}

export default SigninContainer