const nextPositionX = (
    diretion: 'left' | 'right' = 'left',
    sliderxRef: React.RefObject<HTMLElement>,
    distance: number = 256
    ) => {
    if (sliderxRef.current) {
        const slideX: any = sliderxRef.current;

        /*const fullSize = slideX.scrollWidth - slideX.offsetWidth;*/
        const currentPosition = slideX.scrollLeft;
        const nextPosition = diretion === 'right'
            ? currentPosition + distance
            : currentPosition - distance

        slideX.scrollTo({
            left: nextPosition,
            behavior: 'smooth',
        });
    }
}

export default nextPositionX;