import Chat from "../../../Chat";
import Chat_ from "../../../Chat/chat-interfaces/chat.interface";

import './styles.css';

interface ModalChat_ {
    visible: boolean,
    setVisible: any,
    simpleReceiver?: Chat_['simpleReceiver']
}

function ModalChat({ visible, setVisible, simpleReceiver }: ModalChat_) {
    return (
        <div style={{
            display: visible ? 'flex' : 'none'
        }}>
            {
                visible &&
                <div className="modal-chat-wrapper center">
                    <div className="modal-chat">
                        <Chat
                            close={setVisible}
                            simpleReceiver={simpleReceiver}
                        />
                    </div>
                    <div className="modal-chat-dark mcd-glasseffect" onClick={() => setVisible(false)} />
                </div>
            }
        </div>

    )
}

export default ModalChat