
import Modal from "../../../Modal"

function SubModal(
    {
        subVisible,
        setSubVisible,
        works,
        setSelectedWork
    }: {
        subVisible: boolean,
        setSubVisible: any,
        works: Array<any>,
        setSelectedWork: any
    }
) {

    return (
        <Modal
            children={
                <ul>
                    <li style={{ marginBottom: '22px' }}>
                        <strong>Escolha um dos seus anúncios</strong>
                    </li>
                    {
                        works.map((it: any, idx) =>
                            <li
                                onClick={() => {
                                    setSubVisible(false)
                                    setSelectedWork(it)
                                }}
                                style={{
                                    width: '300px',
                                    backgroundColor: '#eee',
                                    borderRadius: '8px',
                                    padding: '20px 20px',
                                    marginBottom: '22px',
                                    cursor: 'pointer',
                                    border: '1px solid #c4c4c4'
                                }}
                                key={idx}
                            >
                                <p className="ellipsis">
                                    <strong
                                        style={{
                                            marginRight: '10px',
                                            backgroundColor: 'dodgerblue',
                                            borderRadius: '100px',
                                            padding: '2px 12px',
                                            color: 'white',
                                            fontSize: '12px'
                                        }}
                                    >
                                        {it.id}
                                    </strong>
                                    {it.title}
                                </p>

                            </li>
                        )
                    }
                </ul>
            }
            setVisible={setSubVisible}
            visible={subVisible}
        />
    )
}

export default SubModal