import { useEffect, useState } from 'react'
import { IoArrowForward, IoCalendarNumber, IoCalendarOutline, IoCheckmarkCircle, IoChevronForward, IoCloseCircle, IoDocumentTextOutline, IoLocationOutline, IoTimer } from 'react-icons/io5'
import TMPconstantes from '../TMP_constantes'
import { SocList_ } from "../interface"
import status from '../soc_status.json'
import Avatar from '../../../Images/Avatar'
import SocNavBar from './SocNavBar'
import { useNavigate } from 'react-router-dom'
import api from '../../../../config/connection/axios'
import SocDateCalendar from './SocDateCalendar'

interface SocListCard_ {
    list: SocList_,
    statusJson: { language: { "pt-BR": string; }; paid: boolean; color?: string; },
    hideB?: boolean,
    paid?: boolean
}

function SocServiceOrdersListMain() {
    const [showCanceledFinished, setSCF] = useState<boolean>(false)
    const [listData, setListData] = useState<Array<SocList_>[]>([[], []])

    useEffect(() => {
        const u = JSON.parse(window.localStorage.getItem('serss-user') || '{}')

        const handleSubmit = () => {
            api.get(
                '/service/orders/list/intentions',
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${u.token}`
                    }
                }
            ).then((resp) =>  setListData(resp.data) )
        }

        handleSubmit()
    }, [])

    return (
        <>
            <SocNavBar />
            <div className="center column" style={{ marginBottom: '30px' }}>
                <div style={{ width: '92vw' }}>
                    <div
                        className='center soc-title-pro'
                        style={{

                            borderRadius: '100px',
                            width: 'fit-content',

                            marginBottom: '8px',
                        }}
                    >
                        <strong>Ordens de Serviço</strong>
                    </div>
                    <p className='soc-p soc-f-p'>Essas são suas orderns de serviços, aqui vocẽ encontra a lista das <strong>pessoas que querem te contratar</strong>.</p>
                    <p style={{ marginBottom: '15px', }} className='soc-p soc-f-p'>Você deve analizar cada requisição e definir um orçamento. O orçamento deve ter data, local e mais importante o valor do serviço.</p>
                </div>

                {/*<div style={{ width: '93vw' }}>
                    <Select
                        style={{ marginTop: '-50px' }}
                        list={['Todos', 'Em andamento', 'Finalizados / Cancelados']}
                        value={hideFinishedOnes}
                        onChange={setFOnes}
                    />
                </div>*/}
            </div>


            {
                (listData[0] && Array.isArray(listData[0]) && listData[0].length)
                    ? <div>
                        <div
                            style={{
                                paddingTop: '30px',
                                marginBottom: '30px'
                            }}
                            className='center'
                        >
                            <SocCardAsTitle
                                title='Agendamentos'
                                txt='Você recebe após o cliente confirmar que o serviço foi realizado.'
                                icon={<IoCalendarNumber size={30} />}
                            />
                        </div>
                        {
                            listData[0].map((it: SocList_, idx) => {

                                return (
                                    <SocListCardWDate
                                        key={idx}
                                        list={it}
                                        statusJson={status[it.status]}
                                    />
                                )
                            }

                            )
                        }
                        <div className='center'>
                            <div style={{ borderTop: '1px solid #c4c4c4', marginTop: '25px', width: '92%' }} />
                        </div>
                    </div>
                    : <></>
            }
            {
                (listData[1] && Array.isArray(listData[1]) && listData[1].length)
                    ? <>
                        <div className='center' style={{ margin: '45px 0 20px 0' }}>
                            <SocCardAsTitle
                                icon={<strong>&#36;</strong>}
                                title='Propostas'
                                txt='Você deve analizar cada proposta e definir um valor.'
                            />
                        </div>
                        <div>
                            {
                                listData[1].map((it: SocList_, idx) => {

                                    return (
                                        <SocListCard
                                            key={idx}
                                            list={it}
                                            statusJson={status[it.status]}
                                            hideB
                                            paid={false}
                                        />
                                    )

                                }
                                )
                            }
                        </div>
                    </>
                    : <></>
            }
            {/*<div className='center' style={{ fontSize: '14px', color: 'var(--dark)' }}>
                <p>Cancelados/Finalizados</p>
                <span className='center'>
                    <IoChevronDown />
                </span>
            </div>*/}
            {showCanceledFinished &&
                <div style={{ border: '1px solid grey' }}>
                    {
                        TMPconstantes.map((it: SocList_, idx) => {
                            if (it.status === "canceled" || it.status === "finished") {
                                return (
                                    <SocLFinishedCard
                                        key={idx}
                                        list={it}
                                        statusJson={status[it.status]}
                                    />
                                )
                            }
                        }
                        )
                    }
                </div>}
        </>
    )
}

const SocListCard = ({
    list, statusJson, hideB = false, paid
}: SocListCard_) => {
    const navigate = useNavigate()

    const handleNextPage = () => {
        if (paid) return
        else navigate('/service_orders/response', {
            state: { list }
        })
    }

    return (

        <button
            onClick={handleNextPage}
            className='soc-white-card-wrap center'
            style={{
                position: 'relative',
                top: 0,
                marginBottom: '30px',
                textAlign: 'start'
            }}
        >
            <div className='soc-white-card card156'>
                <div className='soc-white-card-container'>
                    <div className='soc-w-top'>
                        <div className='soc-w-left'>
                            <div className='soc-w-avatar-wrap'>
                                <Avatar uid={' '} />
                            </div>
                        </div>
                        <div className='soc-w-right'>
                            <div style={{ marginBottom: '8px' }}>
                                <SocY socy={list.so_unique_id} />
                            </div>
                            <h3 className='soc-h3 ellipsis'>{list.client_name}</h3>
                            <p className='description-ellipsis soc-p'>
                                {list.ser_description}
                            </p>
                        </div>
                    </div>
                    <div className='soc-w-bottom'>
                        <div className="center">
                            <span className="center" style={{ marginRight: '10px' }}>
                                <IoCalendarOutline size={20} />
                            </span>
                            <p style={{ textTransform: 'capitalize' }}>{list.date_week} {list.date_day}&nbsp;&nbsp;<i style={{ fontWeight: 'bold' }}>{list.date_hours}</i></p>
                        </div>
                    </div>
                    <div className='soc-w-bottom'>
                        <div className="center">
                            <span className="center" style={{ marginRight: '10px' }}>
                                <IoLocationOutline size={20} />
                            </span>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <p>{list.loc_place}</p>
                                <p><i style={{ fontWeight: 'bold' }}>{list.loc_location}</i></p>
                            </div>
                        </div>
                    </div>
                    {!hideB && <div className='soc-w-bottom'>
                        <div>
                            <div style={{ width: '100%', display: 'flex', marginBottom: '18px' }}>
                                <div className="center">
                                    <span className="center" style={{ marginRight: '10px' }}>
                                        <IoDocumentTextOutline size={20} />
                                    </span>
                                    <p>Orçamento:</p>
                                </div>
                            </div>
                            <div>
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <div>
                                        <strong>R$ {list.price}</strong>
                                    </div>
                                    {
                                        paid && <PagoV />
                                    }
                                </div>
                                {
                                    paid &&
                                    <div style={{ marginTop: '20px' }}>
                                        <p>
                                            O valor pago será liberado entre <strong>5-7 dias</strong> após a confirmação do serviço.
                                        </p>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>}
                    {/*
                        paid
                        && <div className='soc-w-bottom'>
                            <div style={{ width: '100%' }} className='center column'>
                                <button
                                    className='center'
                                    style={{
                                        backgroundColor: 'var(--dark)',
                                        height: '40px',
                                        width: '100%',
                                        borderRadius: '4px',
                                        color: 'white'
                                    }}>
                                    <span className='center' style={{ marginRight: '6px' }}>
                                        <IoChatboxOutline />
                                    </span>
                                    Combinar data, horário e localização
                                </button>
                                <div className='center' style={{ marginTop: '10px' }}>
                                    <p style={{ color: 'var(--dark)' }}>Conversar com o cliente</p>
                                    <span className='center' style={{ marginLeft: '6px' }}>
                                        <IoChevronForward />
                                    </span>
                                </div>
                            </div>
                        </div>
                    */}
                    {(!paid && !list.price) && <div className='soc-w-bottom'>
                        <div>
                        </div>
                        <div className='center'>
                            <p
                                className="soc-l-status"
                                style={{
                                    fontWeight: 600,
                                    color: 'dodgerblue'
                                }}
                            >
                                Responder
                            </p>
                            <span className='center' style={{ marginLeft: '8px' }}>
                                <IoArrowForward color='dodgerblue' />
                            </span>
                        </div>
                    </div>
                    }

                    {(!paid && list.price) && <div className='soc-w-bottom'>
                        <div>
                        </div>
                        <div className='center'>
                            <span className='center' style={{ marginRight: '8px' }}>
                                <IoTimer color='#696969' />
                            </span>
                            <p
                                className="soc-l-status"
                                style={{
                                    fontWeight: 600,
                                    color: '#696969'
                                }}
                            >
                                Aguardando o pagamento
                            </p>
                        </div>
                    </div>
                    }
                </div>
            </div>
        </button>
    )
}

const SocListCardWDate = ({
    list, statusJson
}: SocListCard_) => (
    <div>
        <div className='center'>
            <div style={{ display: 'flex', width: '92%', marginBottom: '10px' }}>
                <SocDateCalendar list={list} />
            </div>
        </div>
        <SocListCard
            list={list}
            statusJson={statusJson}
            paid
        />
    </div>
)

const SocY = ({ socy }: { socy: string }) => <div style={{ display: 'flex', justifyContent: 'space-between' }}>
    <div className="center">
        <div
            style={{
                backgroundColor: 'var(--border)',
                height: '100%',
                padding: '0 10px',
                borderRadius: '6px', marginLeft: '8px'
            }}
            className="center"
        >
            <i style={{ fontSize: '10px' }}>#{socy}</i>
        </div>
    </div>
    <div>
        <IoChevronForward />
    </div>
</div>

const SocLFinishedCard = ({
    list, statusJson
}: SocListCard_) => {
    const navigate = useNavigate()
    return (
        <button
            onClick={() => navigate('/service_orders/response')}
            className='soc-white-card-wrap center'
            style={{ position: 'relative', top: 0, marginBottom: '30px', textAlign: 'start' }}
        >
            <div className='soc-white-card card156'>
                <div className='soc-white-card-container'>
                    <div style={{ display: 'flex' }}>
                        <div className='soc-w-left'>
                            <div className='soc-w-avatar-wrap'>
                                <Avatar uid={' '} />
                            </div>
                        </div>
                        <div className='soc-w-right' >
                            <SocY socy={list.so_unique_id} />
                            <h3
                                className='soc-h3 ellipsis'
                                style={{
                                    margin: '8px 0 0 0',
                                    borderBottom: '1px solid var(--border)',
                                    paddingBottom: '15px'
                                }}
                            >
                                {list.client_name}
                            </h3>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    color: statusJson.color,
                                    marginTop: '17px'
                                }}
                            >
                                <span className="center" style={{ marginRight: '4px' }}>
                                    {list.status === 'finished' && <IoCheckmarkCircle />}
                                    {list.status === 'canceled' && <IoCloseCircle />}
                                </span>
                                <p
                                    className='description-ellipsis soc-p'
                                    style={{ color: statusJson.color, fontWeight: 600 }}
                                >
                                    {statusJson.language['pt-BR']}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </button>
    )
}

const PagoV = ({ height }: { height?: string }) => (
    <div>
        <div
            className='center'
            style={{
                height: height && height,
                backgroundColor: '#00ae7425',
                borderRadius: '100px',
                padding: '6px 30px'
            }}
        >
            <p style={{ color: '#00ae74' }}>Pago ✓</p>
        </div>
    </div>
)

interface SocCardAsTitle_ {
    icon?: JSX.Element,
    title: string,
    txt?: string
}

const SocCardAsTitle = ({
    icon,
    title,
    txt
}: SocCardAsTitle_) => (
    <div
        style={{
            border: '1px solid #c4c4c4',
            backgroundColor: '#ffffff80',
            borderRadius: '8px',

            width: 'calc(93% - 30px)',

            color: 'var(--dark)',
            display: 'flex',

            padding: '15px',
            flexDirection: 'column'


        }}
    >
        <div style={{ display: 'flex' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                {icon && <span className='center' style={{ marginRight: '10px' }}>
                    {icon}
                </span>}
                <strong>
                    {title}
                </strong>
            </div>
        </div>
        {txt && <div>
            <p>
                {txt}
            </p>
        </div>}
    </div>
)

export default SocServiceOrdersListMain