import React from 'react'

function MaxLengthDesc({ countDesc }: { countDesc: number }) {
    return (
        <div style={{ paddingTop: '8px' }}>
            <span
                style={{
                    fontSize: '12px',
                    textIndent: '4px',
                    backgroundColor: '#eee',
                    padding: '4px 12px',
                    borderRadius: '100px'
                }}
            >
                {countDesc}/510
            </span>
        </div>
    )
}

export default MaxLengthDesc