import RegistrationContainer from "../components/Containers/RegistrationContainer"
import ThinHeader from "../components/Headers/ThinHeader"
import Screen from "../components/screens/Screen"

function Registration() {
  return (
    <Screen>
      <ThinHeader />
      <RegistrationContainer />
    </Screen>
  )
}

export default Registration