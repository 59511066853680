function Beta(
    {
        top = 0,
        left = 'auto',
        right = 'auto',
        color = '#f20074',
        text = 'B E T A'
    }:
        {
            top?: number,
            left?: number | string,
            right?: number | string,
            color?: string,
            text?: string
        }
) {
    return (
        <div style={{
            backgroundColor: color,
            borderRadius: '100px',
            color: 'white',
            padding: '6px 16px',
            position: 'absolute',
            fontSize: '11px',
            boxShadow: '0 8px 12px -8px ' + color,
            top, left, right
        }}>
            {text}
        </div>
    )
}

export default Beta