
import { IoCheckmarkCircleOutline, IoOpenOutline } from "react-icons/io5";
import { Link } from "react-router-dom";
import FormButton from "../../../Form/Inputs/layouts/FormButton";
import Modal from "../../../Modal"

interface customTexts_ {
    check: string,
    title: string,
    content: JSX.Element | string,
    button: string
}

interface WcMessage_ { 
    visible: boolean, 
    setVisible: any, 
    customTexts?: customTexts_ 
}

function WcMessage({ visible, setVisible, customTexts }: WcMessage_) {

    const handleClick = (e: Event) => {
        e.preventDefault();
    }

    const texts: customTexts_ = {
        check: 'Seu anúncio estará disponível em até 48h.',
        title: 'As suas imagens serão atualizadas em breve:',
        button: 'Concordo',
        content: <p
            style={{ color: '#00000090' }}
        >
            Pode levar
            <strong> alguns minutos </strong>
            para que a mudança seja exibida em todos os serviços
        </p>
    }

    return (
        <Modal
            visible={visible}
            setVisible={setVisible}
            closeBtn={false}
            padding={'80px  60px'}
            children={
                <div
                    style={{
                        maxWidth: '400px',
                        padding: '0 40px'
                    }}
                    className='center column'
                >
                    <div
                        style={{
                            border: '1px solid #c4c4c4',
                            borderRadius: '12px',
                            padding: '20px'
                        }}
                    >
                        <strong
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                alignItems: 'flex-start',
                                fontSize: '1.2em'
                            }}
                        >
                            <span
                                className="center"
                                style={{ marginRight: '12px' }}
                            >
                                <IoCheckmarkCircleOutline
                                    size={34}
                                    color='#00ae74'
                                />
                            </span>
                            {customTexts ? customTexts.check : texts.check}
                        </strong>
                    </div>
                    <strong
                        style={{
                            marginTop: '30px',
                            fontSize: '1.2em'
                        }}
                    >
                        {customTexts ? customTexts.title : texts.title}
                    </strong>

                    {customTexts ? customTexts.content : texts.content}
                    <div
                        style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'flex-end',
                            color: 'dodgerblue'
                        }}
                    >
                        <Link to='/terms'>
                            <div className="center">
                                Termos de uso
                                <span
                                    className="center"
                                    style={{ marginLeft: '6px' }}
                                >
                                    <IoOpenOutline />
                                </span>
                            </div>
                        </Link>
                    </div>
                    <Link to='/profile' style={{ width: '100%' }}>
                        <FormButton
                            style={{ backgroundColor: '#00ae74', border: '1px solid #00ae74' }}
                            text={customTexts ? customTexts.button : texts.button}
                        />
                    </Link>
                </div>
            }
        />
    )
}



export default WcMessage