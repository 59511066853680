import './styles.css'
interface Safediv {
    children?: any,
    style?: React.CSSProperties,
    center?: boolean,
    column?: boolean
}

function SafeDiv({ children, style, center=false, column=false }: Safediv) {
    return (
        <main
            style={style}
            id="SafeDiv"
            className={`${center && 'center'} ${column && 'column'}`}
        >
            {children}
        </main>
    )
}

export default SafeDiv