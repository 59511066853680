import "./contactContainer.style.css";

import Input from "../../../Form/Inputs/layouts/Input";
import { TextArea } from "../../../Form/Inputs/layouts/TextArea";
import FormButton from "../../../Form/Inputs/layouts/FormButton";
import api from "../../../../config/connection/axios";
import { useContext, useState } from "react";
import regex from "../../../../functions/regex";
import { IoChatbubble } from "react-icons/io5";
import CentrlMenu_ from "../../../../interfaces/centralMenu.interface";
import WindowAlertContext from "../../../../context/windowAlert.context";


interface styB_ {
    backgroundColor?: string,
    border?: string
}
export default function ContactContainer({ centralMenuCode, uid }: { centralMenuCode: CentrlMenu_['code'], uid?: string }) {

    const windowAlert = useContext(WindowAlertContext);

    const [name, setName] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [message, setMessage] = useState<string>('');
    const [phone, setphone] = useState<string>('');

    const [spinner, setSpinner] = useState<boolean>(false);

    const [styB, setStyB] = useState<styB_>({ backgroundColor: 'var(--dark)', border: '1px solid var(--dark)' });

    const handleSendEmail = async (e: any, alertfy: any) => {
        e.preventDefault();

        //if (!email && !phone) return alertfy('Informe um número de telefone ou um e-mail para contato')

        if (
            !name ||
            !email ||
            !message
        ) return alertfy('Todos os campos obrigatórios(*) devem ser preenchidos')

        if (email && !regex.email.test(email.trim())) return alertfy('Formato de email invalido')

        setSpinner(true)

        const hedaers =
            centralMenuCode === 'manage_data'
                ? {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization':
                            `Bearer ${JSON.parse(
                                window.localStorage.getItem('serss-user') || '{}'
                            ).token}`
                    }
                }
                : {};

        await api.put(
            centralMenuCode === 'manage_data'
                ? '/feedback/manage/data'
                : '/feedback',
            {
                suggestion: message, evaluation: '', uid: uid,
                email, phone, name, code: centralMenuCode
            },
            hedaers

        )
            .then(() => {
                setSpinner(false);
                setStyB({});
                windowAlert?.confirm({p:'Aguarde, responderemos o mais rápido possível.', strong:'Central de atendimento'});
            })
            .catch(() => {
                setSpinner(false);
                alertfy('Por favor tente novamente mais tarde');
            })
    }


    return (
        <div id="ContatoContent" style={{ ...sty }}>
            <div id='contato-wrapper' style={{ width: '80%', ...sty, flexDirection: 'column' }}>
                <div style={{ width: '100%', ...sty, flexDirection: 'column' }}>
                    <div className="center column">
                        <div
                            className="center"
                            style={{
                                border: '1px solid #c4c4c4',
                                width: 'fit-content',
                                height: 'fit-content',
                                color: '#696969',
                                padding: '14px',
                                borderRadius: '14px',
                                boxShadow: '-2px 2px 6px var(--border)',
                                margin: '52px 0 -6px 0'
                            }}
                        >
                            <span className="center" style={{ position: 'relative' }}>
                                <IoChatbubble size={40} color="var(--green)" />
                                <strong style={{ fontFamily: 'montserrat', fontSize: 'bold', color: 'white', position: 'absolute' }}>S</strong>
                            </span>
                        </div>
                        <h2 style={{ color: 'var(--green)' }}>Como podemos ajudar?</h2>
                    </div>
                </div>

                <div id='wrap-contato-formulario' style={{ width: '100%', ...sty }}>
                    <div id='c-formulario' style={{ width: '60%', marginBottom: '60px' }}>
                        <div className={'Formulario Formulario--Contato'}>
                            <form>
                                <div className='form_top_inputs'>
                                    <Input
                                        label="Nome*"
                                        placeholder="Nome Completo"
                                        value={name}
                                        onChange={setName}
                                    />

                                </div>

                                <div className='form_top_inputs'>
                                    <Input
                                        label="E-mail*"
                                        placeholder="exemplo@email.com"
                                        value={email}
                                        onChange={setEmail}
                                    />
                                    <Input
                                        label="WhatsApp (opcional)"
                                        placeholder="00 9 9988-7766"
                                        value={phone}
                                        onChange={setphone}
                                    />
                                </div>
                                <div className='form_botton_textArea'>
                                    <TextArea
                                        label="Descreva o problema*"
                                        placeholder="Conte-nos o que aconteceu..."
                                        value={message}
                                        onChange={setMessage}
                                    />
                                </div>
                                <div className='form_button'>
                                    <FormButton
                                        style={{ ...styB }}
                                        text={styB.border ? "Enviar" : '✓'}
                                        onClick={styB.border ? handleSendEmail : (e: Event) => e.preventDefault()}
                                        spinner={spinner}
                                    />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}


const sty = {
    backgroundColor: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
}