import { IoChevronBack, IoChevronForward } from "react-icons/io5"
import './styles.css';
import nextPositionX from "../../../functions/nextPositionX";

function DirectionButtons(
    {
        sliderxRef,
        arrow
    }:
        {
            sliderxRef: React.RefObject<HTMLElement>,
            arrow?: 'default' | 'white'
        }
) {
    return (
        <div className='s-buttons-x'>
            <SXArrowButn
                sliderxRef={sliderxRef}
                arrow={arrow}
            />
            <SXArrowButn
                sliderxRef={sliderxRef}
                arrow={arrow}
                direction='right'
            />
        </div>
    )
}

const SXArrowButn = (
    {
        direction = 'left',
        sliderxRef,
        arrow = 'default' }:
        {
            direction?: 'left' | 'right',
            sliderxRef: React.RefObject<HTMLElement>,
            arrow?: 'default' | 'white'
        }
) => (
    <div
        className={`s-x-arrow-wrapper s-x-wrapper-arrow--${arrow} center`}
    >

        <button
            className={`s-x-arrow s-x-arrow--${arrow}`}
            onClick={() => nextPositionX(direction, sliderxRef)}
        >
            {
                direction === 'right'
                    ? <IoChevronForward />
                    : <IoChevronBack />
            }
        </button>
    </div>
)

export default DirectionButtons