import { IoChevronBack } from 'react-icons/io5'
import VPFromText from './VPFromText'

const VPFromThree = (
    {

        toggleScreens
    }:
        {


            toggleScreens: any
        }
) => (
    <form className='vpFrom'>
        <h2>Perfil verificado: Regras e informações</h2>

        <VPFromText />

        <div className='done-button center'>
            <button
                onClick={(e: any) => toggleScreens(e, 1)}
                className='center'
                style={{
                    borderRadius: '100px',
                    height: '47px',
                    width: '90%',
                    //border: '1px solid transparent',
                    color: 'var(--dark)',
                    fontSize: '20px',
                    border: '2px solid var(--dark)'
                }}
            >
                <IoChevronBack />
                <strong style={{ marginLeft: '12px' }}>Voltar</strong>
            </button>
        </div>
    </form>
)

export default VPFromThree