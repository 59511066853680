import { IoPerson } from 'react-icons/io5'
import './styles.css'

function AvatarPlaceholder({ size = 24, backgroundColor='', borderRadius='100%' }) {
    return (
        <span
            className='AvatarPlaceholder center'
            style={{backgroundColor, borderRadius}}
        >
            <IoPerson size={size} />
        </span>
    )
}

export default AvatarPlaceholder