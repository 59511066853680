import { IoChevronForward } from "react-icons/io5"

const SubNextButton = (
    {
        scrollWin,
        text,
        icon,
        end = false
    }:
        {
            scrollWin: any,
            text: string,
            icon: React.ReactElement<any, any>,
            end?: boolean
        }
) => {
    return (
        <div className='center'
            style={{
                width: '100%',
            }}
        >
            <button
                onClick={() => scrollWin(end)}
                style={{
                    padding: '10px 0',
                    width: '86%',
                    border: '2px solid #c4c4c4',
                    borderRadius: '8px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    color: '#696969',
                    fontWeight: 'bold'
                }}
            >
                <p style={{ margin: 0, marginLeft: '30px', display: 'flex' }}>
                    <span className='center' style={{ marginRight: '8px' }}>
                        {icon}
                    </span>
                    {text}
                </p>
                <span className='center' style={{ marginRight: '30px' }}>
                    {!end && <IoChevronForward />}
                </span>
            </button>
        </div>
    )
}

export default SubNextButton;