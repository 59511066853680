import './styles.css';
import CentrlMenu_ from '../../interfaces/centralMenu.interface';
//import ScAlert from '../ScAlerts/ScAlert';
import CMenuLi from './layouts/CMenuLi';

function CentralMenu({ setCentralMenu, uid }: { uid?: string, setCentralMenu: (s: CentrlMenu_) => void }) {
    return (
        <div className='CentralMenu'>
            <ul >
                <CMenuLi
                    setCentralMenu={setCentralMenu}
                    screen={3}
                    code='fraud'
                    bigger

                >
                    <div style={{ display: 'flex' }}>
                        <div className='center'>
                            🚨
                        </div>
                        <div>
                            <div>
                                Suspeita de golpe
                            </div>
                            <br />
                            <div>
                                Denúncias
                            </div>
                        </div>
                    </div>
                </CMenuLi>
                <Aa />
                <CMenuLi
                    setCentralMenu={setCentralMenu}
                    screen={1}
                    code='create_account'

                >
                    Não consigo criar minha conta
                </CMenuLi>
                <CMenuLi
                    setCentralMenu={setCentralMenu}
                    screen={1}
                    code='complaint'

                >
                    Reclamação
                </CMenuLi>
                <CMenuLi
                    setCentralMenu={setCentralMenu}
                    screen={1}
                    code='questions'

                >
                    Tirar dúvidas
                </CMenuLi>
                <CMenuLi
                    setCentralMenu={setCentralMenu}
                    screen={1}
                    code='error'

                >
                    Relatar falha técnica
                </CMenuLi>
                <Aa />
                <CMenuLi
                    setCentralMenu={setCentralMenu}
                    screen={1}
                    code='help'

                >
                    Preciso de ajuda
                </CMenuLi>
                <Aa />
                <CMenuLi
                    setCentralMenu={setCentralMenu}
                    screen={1}
                    code='feedback'

                >
                    Feedback
                </CMenuLi>
                <CMenuLi
                    setCentralMenu={setCentralMenu}
                    screen={1}
                    code='feedback'

                >
                    Sugerir melhoria
                </CMenuLi>
                {
                    uid &&
                    <>
                        <Aa />
                        <CMenuLi
                            setCentralMenu={setCentralMenu}
                            screen={2}
                            code='manage_data'

                        >
                            Gerenciar minhas informações
                        </CMenuLi>
                    </>
                }
                <Aa />
                <div className='center column' style={{ color: '#696969', textAlign: 'center', width: '90%', marginBottom: '80px' }}>
                    <p>Se preferir você pode entrar em contato pelo e-mail de suporte:</p>
                    <strong>suporte@servicess.com.br</strong>
                </div>
                {/*<ScAlert
                    title='Aviso Importante'
                    content={
                        <p style={{ lineHeight: 1.6 }}>
                            <p>Prezados clientes e parceiros,</p>
                            Gostaríamos de informar que enfrentamos recentemente alguns problemas técnicos que <strong>afetaram nossa capacidade de responder</strong> a todos os chamados e e-mails recebidos nos últimos dias. <strong>Pedimos sinceras desculpas</strong> por qualquer inconveniente que isso possa ter causado.
                        </p>
                    }
                />*/}
            </ul>
        </div>
    )
}

const Aa = () => (
    <div style={{ width: '70%', paddingTop: '20px', marginBottom: '50px', borderBottom: '1px solid var(--border)' }} />
)


export default CentralMenu