import { useState } from "react";
import RegAvatar from "../Images/RegAvatar/RegAvatar";
import Modal from "../Modal";
import './styles.css';
import { IoCheckmarkSharp } from "react-icons/io5";

const Verify = ({ inline = false, haveAvatar, n = 1, uid }: { inline?: boolean, haveAvatar?: boolean, n?: 1 | 2 | 3, uid: string | undefined }) => {


    const userUid = JSON.parse(window.localStorage.getItem('serss-user') || '{}').uid;
    const selfAlert = (!haveAvatar && (userUid === uid));

    return (
        <div className='center' style={inline ? { marginLeft: '8px' } : { position: 'absolute', top: 0, right: 10, height: '100%' }}>
            {
                haveAvatar &&
                <span className={`center verifyP ${selfAlert && 'verifyP-hover-alert'}`}>
                    <div className="center" style={{ zIndex: 1 }}>
                       <IoCheckmarkSharp size={10}/>
                    </div>
                    {selfAlert && <VerifyAlert />}
                    <span className={`verifyP-45deg ${selfAlert && 'verifyP-hover-alert'}`} />
                    <span style={{transform: 'rotate(67.5deg)'}} className={`verifyP-45deg ${selfAlert && 'verifyP-hover-alert'}`} />
                    <span style={{transform: 'rotate(112.5deg)'}} className={`verifyP-45deg ${selfAlert && 'verifyP-hover-alert'}`} />
                </span>
            }
        </div>
    )
}

/*  

TROCAR O "verifyP" POR ESSE SVG FEITO NO FIGMA:

<svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
  <path d="M10.1273 1.56C10.509 0.877639 11.491 0.877639 11.8727 1.56L12.4913 2.66569C12.7661 3.15694 13.3921 3.32466 13.8757 3.03664L14.9642 2.38837C15.636 1.98829 16.4864 2.47927 16.4758 3.26108L16.4586 4.52791C16.451 5.09075 16.9092 5.54899 17.4721 5.54136L18.7389 5.52421C19.5207 5.51362 20.0117 6.36402 19.6116 7.0358L18.9634 8.12433C18.6753 8.60795 18.8431 9.23391 19.3343 9.50873L20.44 10.1273C21.1224 10.509 21.1224 11.491 20.44 11.8727L19.3343 12.4913C18.8431 12.7661 18.6753 13.3921 18.9634 13.8757L19.6116 14.9642C20.0117 15.636 19.5207 16.4864 18.7389 16.4758L17.4721 16.4586C16.9092 16.451 16.451 16.9092 16.4586 17.4721L16.4758 18.7389C16.4864 19.5207 15.636 20.0117 14.9642 19.6116L13.8757 18.9634C13.3921 18.6753 12.7661 18.8431 12.4913 19.3343L11.8727 20.44C11.491 21.1224 10.509 21.1224 10.1273 20.44L9.50873 19.3343C9.23391 18.8431 8.60795 18.6753 8.12433 18.9634L7.0358 19.6116C6.36402 20.0117 5.51362 19.5207 5.52421 18.7389L5.54136 17.4721C5.54899 16.9092 5.09075 16.451 4.52791 16.4586L3.26108 16.4758C2.47927 16.4864 1.98829 15.636 2.38837 14.9642L3.03664 13.8757C3.32466 13.3921 3.15694 12.7661 2.66569 12.4913L1.56 11.8727C0.877639 11.491 0.877639 10.509 1.56 10.1273L2.66569 9.50873C3.15694 9.23391 3.32466 8.60795 3.03664 8.12433L2.38837 7.0358C1.98829 6.36402 2.47927 5.51362 3.26108 5.52421L4.52791 5.54136C5.09075 5.54899 5.54899 5.09075 5.54136 4.52791L5.52421 3.26108C5.51362 2.47927 6.36402 1.98829 7.0358 2.38836L8.12433 3.03664C8.60795 3.32466 9.23391 3.15694 9.50873 2.66569L10.1273 1.56Z" fill="#24A0ED"/>
  <circle cx="11" cy="11" r="7" fill="#24A0ED" stroke="#24A0ED" stroke-width="2"/>
  <path d="M8.60004 11L10.5125 14L13.7 8" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>


*/


const VerifyAlert = () => {

    const [visible, setVisible] = useState<boolean>(false);

    return (
        <>
            <button
                id="v-p-hover"
                onClick={() => setVisible(true)}
                style={{
                    position: 'absolute',
                    width: '140%',
                    height: '140%',
                    left: '-20%',
                    top: '-20%',
                    zIndex: 2
                }}
            >
                <div
                    className='center blink'
                    style={{
                        position: 'absolute',
                        top: -10,
                        right: -10,
                        backgroundColor: 'orange',
                        borderRadius: '100%',
                        padding: '5px',
                        border: '2px solid white'
                    }}
                />
            </button>
            {visible && <Modal
                visible={visible}
                setVisible={setVisible}
                children={
                    <div style={{ display: 'flex', flexDirection: 'column', color: '#222d36', maxWidth: '80vw' }}>
                        <div className="center" style={{ marginBottom: '20px', borderBottom: '1px solid #c4c4c4', paddingBottom: '20px' }}>
                            <h3>A sua foto deve ser igual à informada no documento!</h3>
                        </div>
                        <RegAvatar />
                    </div>
                }

            />}
        </>
    )
}

export default Verify;