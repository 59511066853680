import { IoShield } from "react-icons/io5"
import txts from '../txts.json'
import { useContext } from "react"
import ServiceOrdersContext from "../context"
import { handleSocDescLength } from "../helpers/helper"

const SocProBtn = () => {
    const context = useContext(ServiceOrdersContext)

    const handleNextBtn = (e: Event) => {
        if (handleSocDescLength(context))
            context.submit(e)
        else context?.changeOutlet('description#conclude')
    }

    return (
        <div className='soc-pro-btn'>
            <div className='center'>
                <button onClick={(e: Event | any) => handleNextBtn(e)}>
                    {txts.btns.btn}
                </button>
            </div>
            {/*<div className='center'>

                <p className='center'>
                    <strong
                        className='center'
                        style={{ marginRight: '4px' }}
                    >
                        <IoShield color='var(--green)' />
                    </strong>
                    {txts.btns.pro_message[0]}
                    &nbsp;
                    <strong style={{ textDecoration: 'underline' }} onClick={() => { }}>
                        {txts.btns.pro_message[1]}
                    </strong>
                </p>
            </div>*/}
        </div>
    )
}

export default SocProBtn