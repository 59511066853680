import './styles.css'
import { Link, useNavigate } from 'react-router-dom'
import Icon from '../../Icon'
import { FaDollarSign } from 'react-icons/fa'
import api from '../../../config/connection/axios'
//import api from '../../../config/connection/axios'

interface Conatctbuttons {
    type?: string
    height?: string,
    data: any,
    setChat: any
}

function ContatButtons({ type = 'onlyButtons', height, data, setChat }: Conatctbuttons) {
    const u = JSON.parse(window.localStorage.getItem('serss-user') || '{}').uid
    
    const navigate = useNavigate();

    const handleHiringClick = async () => {
        await api.get(
            `/service/orders/validation?prof_uid=${data.user_uid}&client_uid=${u}`
        )
            .then(r => {
                const yes = r.data;
                yes
                    ? navigate('/service_orders/budgets')

                    : navigate('/hiring', {
                        state: {
                            prof_uid: data.user_uid,
                            work_ref: data.id,
                            prof_name: data.user_name,
                            work_title: data.title
                        }
                    })
            })


    }

    return (
        <div
            id='ContactButtons'
            className={`ContactButtons--${type} center`}
        >
            {

                (navigator.userAgent.match(/expo/i))
                    ? <div className='CBtns-center'>
                        {u
                            ? <CbChatButton
                                onClick={handleHiringClick}
                                height={height}
                            />
                            : <Link to='/signin' className='CBtns-l-a center'>
                                <CbChatButton />
                            </Link>
                        }
                    </div>
                    : <div className='CBtns-center'>
                        {u
                            ? <CbChatButton onClick={setChat} height={height} />
                            : <Link to='/signin' className='CBtns-l-a center'>
                                <CbChatButton />
                            </Link>
                        }
                    </div>
            }
        </div>
    )
}


const CbChatButton = ({ onClick, height }: { onClick?: any, height?: string, }) => (
    <button
        onClick={onClick && onClick}
        style={{
            height: height && height,
        }}

        className='CBtns-l-a center'
    >
        <div
            style={{
                left: 0,
                top: 0,
                height: '100%',
                backgroundColor: '#00000060',
                width: '19%',
                position: 'absolute'
            }}
            className="center"
        >
            <Icon style={{ marginLeft: '-4px' }}>
                <FaDollarSign size={18} />
            </Icon>
        </div>
        Fazer orçamento
    </button>
)

export default ContatButtons