import AdsUsersDetails_wild from "../Announcements/wildscreen/AdsUsersDetails_wild";
import PremiumButton from "../Buttons/PremiumButton";
import By from "../By";
import Avatar from "../Images/Avatar"
import Verify from "../Verify";

import './styles.css';

interface DetailsUser_ {

    data: {
        id?: string
        user_uid: string,
        user_profession: string,
        user_name: string,
        user_description: string,
        verified?: boolean,
        avatar: boolean
    },
    children?: JSX.Element,
    style?: React.CSSProperties,
    show?: boolean,
    mySelf: boolean,
    premium: boolean,
    DUHaveBanner: boolean
}

function DetailsUser({
    mySelf,
    premium,
    data,
    children,
    style,
    show = false,
    DUHaveBanner
}: DetailsUser_) {
    return (
        <div
            id='Details-user'
            className={`Details-user details-user--${DUHaveBanner ? 'normal' : 'static-tmp'}`}
            style={style}
        >
            <By left={10} top={2} color={'#8b8b8b'} />
            <div className='DUser-wrapper'>
                <div style={{ width: '100%', alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
                    <div style={{ border: '8px solid white', borderRadius: '18px', boxShadow: '-6px 6px 12px #00000024,0px 0px 2px #00000010', margin: '20px 0 10px 0' }}>
                        <div className='DUser-img center'>
                            <Avatar borderRadius="12px" uid={data.user_uid} size={20} />
                        </div>
                    </div>
                    <div className='DUser-informations'>
                        <h6 className="ellipsis" style={{ maxWidth: '90%' }}>{data.user_profession}</h6>
                        <div className="center" style={{ width: '100%' }}>
                            <h4 className='ellipsis' style={{ width: '80%' }}>
                                {data.user_name}
                            </h4>
                            {
                                data.verified &&
                                <div style={{ marginLeft: '-8px' }}>
                                    <Verify haveAvatar={data.avatar} inline n={3} uid={data.user_uid} />
                                </div>
                            }
                        </div>
                        <p className={show ? '' : 'd-elip'}>{data.user_description}</p>
                        <div style={{ height: '26px' }} />
                    </div>
                </div>
                {children}
            </div>
            {/*
                (mySelf && !premium) &&
                <PremiumButton absolute wid={data.id} />
            */}
            {
                (mySelf) &&
                <div style={{ width: '100%', height: '200px', position: 'absolute', bottom: '-300px', left: 0 }}>
                    <AdsUsersDetails_wild slot='5810169846' />
                </div>
            }
        </div>
    )
}

export default DetailsUser