import './styles.css';

function GreenBorderButton({ onClick, text, borderColor = 'var(--hover)'}: { onClick: (e: any) => void, text: string, borderColor?: string | null}) {
    return (
        <button
            style={{
                border:'2px solid ' + borderColor
            }}
            className='GreenBorderButton'
            onClick={onClick}
        >
            {text}
        </button>
    )
}

export default GreenBorderButton;