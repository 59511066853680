import { IoCard } from "react-icons/io5"
import { useLocation, useNavigate } from "react-router-dom"

const SocNavBar = () => {
    return (
        <div id='NavigationBar' style={{ height: '70px' }} className="center">
            <div style={{
                width: '95%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
            }}>


                <SocNavBarBtn
                    icon={
                        <strong>
                            &#36;
                        </strong>
                    }
                    text="Ordens de serviço"
                    page={"list"}
                />
                <SocNavBarBtn
                    icon={<IoCard />}
                    text="Contratar"

                    page={"budgets"}
                />
            </div>
        </div>
    )
}

const SocNavBarBtn = (
    { icon, text, page }:
        { icon: JSX.Element, text: string, page: 'list' | 'budgets' }
) => {

    const navigate = useNavigate()
    const changeOutlet = (page: 'list' | 'budgets') => {
        navigate('/service_orders/' + page)
    }

    const location = useLocation();
    let p = location.pathname.slice(-page.length).trim();

    return (
        <button
            onClick={() => changeOutlet(page)}
            className={`center column soc-nav-btn soc-nav-btn--${p === page && 'selected'}`}
        >
            <div className="center">
                {icon}
            </div>
            <div>
                <strong style={{ margin: 0, fontSize: '13px' }}>{text}</strong>
            </div>
        </button>
    )
}

export default SocNavBar