
import { useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DetailsContainer from "../components/Containers/DetailsContainer";
import Footer from "../components/Footer";
//import Footer from "../components/Footer";
import HeaderWithoutChat from "../components/Headers/HeaderWithoutChat";
import ModalChat from "../components/modals/ModalChat";
import isMobile from "../functions/isMobile";
import Chat_ from "../Chat/chat-interfaces/chat.interface";
import Screen from "../components/screens/Screen";


function Details() {
  window.scrollTo(0, 0);
  const extraContantRef = useRef(null);

  const { work_id } = useParams();
  const [chat, setChat] = useState<boolean>(false);

  const [simpleReceiver, setSimpleReceiver] = useState<Chat_['simpleReceiver']>({} as Chat_['simpleReceiver']);
  const [spinner, setSpinner] = useState<boolean>(false);
  //const uid = JSON.parse(window.localStorage.getItem('serss-user') || '{}').uid;
  const navigate = useNavigate();
  const handleSetChat = (boo: boolean) => {
    return isMobile()
      ? navigate("/chat", { state: simpleReceiver })
      : setChat(boo)

  }

  return (
    <Screen>
      <HeaderWithoutChat
        setChat={handleSetChat}
        Chat={
          chat &&
          <ModalChat
            visible={chat}
            setVisible={setChat}
            simpleReceiver={simpleReceiver}
          />
        }
      />
      <DetailsContainer
        setChat={handleSetChat}
        work_id={work_id}
        setReceiver={setSimpleReceiver}
        spinner={spinner}
        setSpinner={setSpinner}
        extraContent={extraContantRef}
      />
      <div ref={extraContantRef}>
        {(window.innerWidth > 960 && !spinner) && <Footer />}
      </div>
    </Screen>
  )
}

export default Details