

//import BrokenlistTouchable from "../../../BrokenListTouchable"

import Input from "../../../Form/Inputs/layouts/Input"
import { Slide } from "../../../Form/Inputs/layouts/Slide"
import GetLocation from "../../../GetLocation"
//import WcMoreCities from "./WcMoreCities"

interface IWcLocation {
    locationData: any,
    setLocationData: any,
    remote: boolean,
    setRemote: any,
    district: string,
    setDistrict: (value: string) => void,
    address: string,
    setAddress: (value: string) => void,
    number: number | string,
    setNumber: (value: number) => void
    zipcode: number | string,
    setZipcode: (value: number) => void
    cities: Array<any>,
    setCities: any
}

const WcLocation = ({
    locationData,
    setLocationData,
    remote,
    setRemote,
    district, setDistrict,
    address, setAddress,
    number, setNumber,
    zipcode, setZipcode,
    cities, setCities
}: IWcLocation) => {

    const handleRemoveOne = (idx: number) => {
        setCities((c: any) => c.filter((itc: any, idxx: number) => idxx !== idx))
    }

    const addOneCity = (neww: any) => {
        setCities((c: any) => [...c, neww])
    }

    return (
        <>
            <GetLocation
                setLocationData={setLocationData}
                city_name={locationData.city_name}
                state_name={locationData.state_name}
                required
            />

            <div className='wc-two'>
                <div style={{ width: '68%' }}>
                    <Input
                        label='Bairro'
                        placeholder='Ex: Jardim das Américas'
                        value={district}
                        onChange={setDistrict}

                    />
                </div>
                <div style={{ width: '28%', position: 'relative' }}>

                    {/*<Input
                        label='CEP'
                        placeholder='Ex: 82600100'
                        type='number'
                        max={8}
                        value={zipcode > 0 ? zipcode : null}
                        onChange={setZipcode}

                    />*/}

                </div>
            </div>

            <div className='wc-two'>
                <div style={{ width: '68%' }}>
                    <Input
                        label='Endereço'
                        placeholder='Ex: Av. Paulista'
                        value={address}
                        onChange={setAddress}

                    />
                </div>
                <div style={{ width: '28%', position: 'relative' }}>
                    <Input
                        label='Número'
                        placeholder='Ex: 1299'
                        type='number'
                        value={number}
                        onChange={setNumber}

                    />
                </div>
            </div>
            {/* <BrokenlistTouchable list={cities} removeOne={handleRemoveOne} />
            <WcMoreCities addOneCity={addOneCity} />*/}
            <Slide
                value={remote}
                text='Você atende por videochamada?'
                onChange={setRemote}
            />
        </>
    )
}

export default WcLocation