import { IoIdCard } from 'react-icons/io5'
import RegBorderBut from './RegBorderBut';
import RegBtnI from './RegBtnI';
import { Link } from 'react-router-dom';

function RegVerifyAccount() {

    const u = JSON.parse(window.localStorage.getItem('serss-user') || '{}');

    return (

        <div className='RegC-card'>
            <div style={{display:'flex'}}>
                <span className='center' style={{ backgroundColor: 'dodgerblue', height: '80px', width: '80px', borderRadius: '100%', position:'relative' }}>
                    <IoIdCard size={40} color='white' />
                </span>
            </div>
            <div className='RegC-title'>
                <h4>Como adquirir?</h4>
                <br />
                <p>
                    O perfil verificado é oferecido a todos que comprovarem sua identidade. É necessário nos enviar:
                    <ul>
                        <br />
                        <li><strong>•</strong> Foto frente e verso do <strong>RG</strong></li>
                        <br />
                        <li><strong>•</strong> Foto de um <strong>comprovante de residência</strong> atualizado</li>
                    </ul>
                </p>
            </div>
            <RegBorderBut
                title='Perfil verificado'
                icon={<IoIdCard />}
                text='Comprovar identidade'
                button={
                    <Link to='/verify/profile' style={{width:'100%'}}>
                        <RegBtnI />
                    </Link>
                }
            />
            <div className='RegC-title' style={{ marginTop: '20px' }}>
                <p>* Se preferir pode enviar direto para nosso e-mail <strong>suporte@servicess.com.br.</strong></p>
                <p>* <strong>Importante!</strong>  Sua foto de perfil deve estar em comformidade com a do documento.</p>
            </div>
        </div>


    )
}

export default RegVerifyAccount;