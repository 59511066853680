import DcTitle from "./DcTitle"
import '../../../../css/card156.css'

function C({ details = [] }: any) {
  return (
    <div className='DC-card DC-card-C card156' style={{paddingBottom: '4px'}}>
      <DcTitle 
        text='Detalhes'
      />
      <div className='DC-card-C-content'>
        <ul>
          {
            details.map((d: any, idx: number) => (
              <li key={idx}>
                <p style={{ width: '100%' }}>{d.key}</p>
                <h4 style={{ width: '100%', marginTop: '6px' }}>{d.value}</h4>
              </li>
            ))
          }
        </ul>
      </div>
    </div>
  );
}

export default C