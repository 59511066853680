import { IoArrowForward, IoChevronBack, IoCreateOutline, IoTrashBinOutline } from 'react-icons/io5'
import txts from '../txts.json'
import SocProBtn from './SocProBtn'
import { useContext, useRef } from 'react'
import ServiceOrdersContext from '../context'
import { handleSocDescLength, handleSocMsg } from '../helpers/helper'
import { useLocation } from 'react-router-dom'

function SocDescription() {
  const context = useContext(ServiceOrdersContext)
  const focusRef = useRef<HTMLTextAreaElement>(null)

  const location: any = useLocation();
  const hash = location.hash;

  const handleFocusInput = (e: Event) => {
    e.preventDefault()
    if (focusRef.current) {
      focusRef.current.focus();
    }
  }

  const handleSocDGoback = (e: Event) => {
    e.preventDefault()
    context?.changeOutlet('')
  }

  const handleAddOrFinish = (e: Event) => {
    e.preventDefault();

    hash ? context.submit(e) : context?.changeOutlet('')
  }

  return (
    <div className='soc soc-description'>
      <div className='soc-green-top' />
      <div className='soc-white-card-wrap center' style={{ top: '70px' }}>
        <div className='soc-f'>
          <form
            className='card156 soc-f-btn-margin-top-p-relative' style={{ maxWidth: '95vw' }}>
            <div style={{ padding: '15px', borderBottom: '1px solid var(--border)' }}>
              <strong className='soc-h3' style={{ color: 'var(--dark)' }}>
                {txts.text_input.title[0]}
                &nbsp;
                {'o profissional'}
                &nbsp;
                {txts.text_input.title[1]}
              </strong>
            </div>
            {
              context?.message && <div
                style={{
                  padding: '0 20px',
                  borderTop: '10px solid white',
                }}
              >
                <strong>{handleSocMsg(context?.message)}</strong>
              </div>
            }
            <textarea
              ref={focusRef}
              autoFocus
              value={context?.description}
              onChange={(e) => context?.setDesc(e.target.value)}
              id='soc-text-input'
              className='soc-text-input'
              style={{ height: '100px' }}
              placeholder={'Descrição do serviço...'}
            />
            <div
              style={{
                height: '50px',
                width: 'calc(100% - 8px)',
                marginLeft: '4px',
                display: 'flex',
                alignItems: 'center',
                overflowX: 'scroll'
              }}
            >
              {context?.message && <SocDescFast t='Limpar' clear />}
              {txts.fast_desc.map((it, idx) => <SocDescFast key={idx} t={it} />)}
            </div>
            <div className='soc-description-absolute-btns'>
              <div>
                <button
                  onClick={(e: any) => handleSocDGoback(e)}
                  className='card156 soc-d-btn2'
                >
                  <span className='center'><IoChevronBack size={20} /></span>
                </button>
                <button
                  onClick={(e: any) => handleFocusInput(e)}
                  className='card156 soc-d-btn2'
                  id='soc-description-btn'
                >
                  <span className='center'><IoCreateOutline size={20} /></span>
                </button>
              </div>
              {
                handleSocDescLength(context) &&
                <button
                  onClick={(e: any) => handleAddOrFinish(e)}
                  className='card156 soc-description-main-btn'
                  style={{
                    backgroundColor: 'white',
                    color: 'var(--dark)'
                  }}
                >
                  <strong>{hash ? 'Solicitar orçamento' : 'Confirmar'}</strong>
                  {
                    hash &&
                    <span className='center'>
                      <IoArrowForward size={20} />
                    </span>
                  }
                </button>
              }
            </div>
          </form>

        </div>
      </div>
      <div style={{ marginTop: '200px' }} />
      {(hash) && <SocProBtn />}
    </div>
  )
}

const SocDescFast = ({ t, clear }: { t: string, clear?: boolean }) => {

  const context = useContext(ServiceOrdersContext)

  const handleSocMessage = (e: Event) => {
    e.preventDefault();
    context?.setMessage(clear ? '' : t)
  }

  return (
    <span
      onClick={(e: any) => handleSocMessage(e)}
      className='center'
      style={{
        fontSize: '13px',
        backgroundColor: 'var(--border)',
        padding: '9px 25px',
        margin: '0 0 0 10px',
        borderRadius: '100px',
        whiteSpace: 'nowrap',
        color: 'var(--dark)'

      }}
    >
      {clear && <span className='center' style={{ marginRight: '6px' }}><IoTrashBinOutline /></span>}
      {t}
    </span>
  )
}


export default SocDescription