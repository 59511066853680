import {
    BrowserRouter as Router,
    Routes,
    Route
} from "react-router-dom";

//Docs:
import TermsOfUse from "./pages/docs/TermsOfUse/TermsOfUse";
import PrivacyPolicy from "./pages/docs/PrivacyPolicy/PrivacyPolicy";
//Pages:
import Home from "./pages/Home";
import Login from "./pages/Login";
import P404 from "./pages/P404";
import Search from "./pages/Search";
import Signin from "./pages/Signin";
import ResetPassword from "./pages/Validations/ResetPassword";
import Validation from "./pages/Validations/Validation";
import Details from "./pages/Details";
import Edit from "./pages/Work/Edit";
import Create from "./pages/Work/Create";
import Registration from "./pages/Registration";
import Categories from "./pages/Categories";
import ProfessionalProfile from "./pages/Perfil/ ProfessionalProfile";
import SelfProfile from "./pages/Perfil/ SelfProfile";
import Subscription from "./pages/Subscription";
import Central from "./pages/Central";
import ChatPage from "./pages/ChatPage";
import VerifyProfile from "./pages/VerifyProfile";
//Hiring - Nested:
import ServiceOrder from "./pages/ ServiceOrder";
import SocDescription from "./components/Containers/ServiceOrderContainer/layouts/SocDescription";
import SocMain from "./components/Containers/ServiceOrderContainer/layouts/SocMain";
import SocDateAndHours from "./components/Containers/ServiceOrderContainer/layouts/SocDateAndHours";
import SocLocation from "./components/Containers/ServiceOrderContainer/layouts/SocLocation";
// Service Orders - Nested:
import ServiceOrdersList from "./pages/ServiceOrdersList";
import SocServiceOrdersListMain from "./components/Containers/ServiceOrdersListContainer/nested/SocServiceOrdersListMain";
import SocServiceOrdersListBudgets from "./components/Containers/ServiceOrdersListContainer/nested/SocServiceOrdersListBudgets";
import SocServiceOrdersListResponse from "./components/Containers/ServiceOrdersListContainer/nested/SocServiceOrdersListResponse";

function RoutesControl() {
    return (
        <Router>
            <Routes>
                <Route
                    path="*"
                    element={<P404 />}
                />
                <Route
                    path="/"
                    element={<Home />}
                />
                <Route
                    path="/login"
                    element={<Login />}
                />
                <Route
                    path="/search"
                    element={<Search />}
                />
                <Route
                    path="/signin"
                    element={<Signin />}
                />
                <Route
                    path="/details/:work_id"
                    element={<Details />}
                />
                <Route
                    path="/central"
                    element={<Central />}
                />
                <Route
                    path="/validation"
                    element={<Validation />}
                />
                <Route
                    path="/reset/password/:token"
                    element={<ResetPassword />}
                />
                <Route
                    path="/profile"
                    element={<SelfProfile />}
                />
                <Route
                    path="/profile/:uid"
                    element={<ProfessionalProfile />}
                />
                <Route
                    path="/work/create"
                    element={<Create />}
                />
                <Route
                    path="/work/edit/:work_id"
                    element={<Edit />}
                />
                <Route
                    path="/registration"
                    element={<Registration />}
                />
                <Route
                    path="/categories"
                    element={<Categories />}
                />
                <Route
                    path="/terms"
                    element={<TermsOfUse />}
                />
                <Route
                    path="/politica_de_privacidade"
                    element={<PrivacyPolicy />}
                />
                <Route
                    path="/verify/profile"
                    element={<VerifyProfile />}
                />
                <Route
                    path="/subscription"
                    element={<Subscription />}
                />

                <Route
                    path="/hiring"
                    element={<ServiceOrder />}
                >
                    <Route
                        path=""
                        element={<SocMain />}
                    />
                    <Route
                        path="description"
                        element={<SocDescription />}
                    />
                    <Route
                        path="date"
                        element={<SocDateAndHours />}
                    />
                    <Route
                        path="location"
                        element={<SocLocation />}
                    />
                </Route>
                <Route
                    path="/service_orders"
                    element={<ServiceOrdersList />}
                >
                    <Route
                        path="list"
                        element={<SocServiceOrdersListMain />}
                    />
                    <Route
                        path="budgets"
                        element={<SocServiceOrdersListBudgets />}
                    />
                    <Route
                        path="response"
                        element={<SocServiceOrdersListResponse />}
                    />
                </Route>
                <Route
                    path="/chat"
                    element={<ChatPage />}
                />
            </Routes>
        </Router>
    );
}

export default RoutesControl;