import { IoTrashOutline } from 'react-icons/io5'
import { Link, useNavigate } from 'react-router-dom'
import api from '../../../config/connection/axios'
import './styles.css'
import { useContext } from 'react'
import WindowAlertContext from '../../../context/windowAlert.context'

const ServiceButtons = ({ _style = 'onlyButtons', id }: { _style?: 'onlyButtons' | 'fixed', id: any }) => {

  const navigate = useNavigate();
  const windowAlert = useContext(WindowAlertContext);

  const handleWorkDelete = async () => {
    const u = JSON.parse(window.localStorage.getItem('serss-user') || '{}')

    await api.post(
      '/firebase/banners/delete',
      {
        work_id: id
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${u.token}`
        }
      }
    )
      .then(async () => await api.delete(
        `/works/delete/${id}`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${u.token}`
          }
        }
      )
        .then(() => {
          navigate('/')
        })
        .catch((err) => console.error(err)))
      .catch((error: Error) => console.error(error));
  }

  return (
    <div className={`ServiceButtons--${_style}`} style={{ marginTop: '30px' }}>
      <div style={{ width: '20%' }}>
        <button
          className='CB-edit-button'
          style={{ backgroundColor: '#222D36', color: 'white' }}
          onClick={() => windowAlert?.confirm(
            {
              strong: "Remover anúncio",
              p: "Deseja deletar esse anúncio?"
            },
            handleWorkDelete
          )}
        >
          <IoTrashOutline />
        </button>
      </div>
      <div style={{ width: '74%' }}>
        <Link to={`/work/edit/${id}`} className='center' style={{ width: '100%' }}>
          <button className='CB-edit-button '>Editar serviço</button>
        </Link>
      </div>
    </div>
  )
}

export default ServiceButtons