import './styles.css'
import works from '../../../constants/homeWorks.json'

import Avatar from "../../Images/Avatar"
import SeeButton from '../../Buttons/SeeButton'
import Banner from '../../Images/Banner'
import { Link } from 'react-router-dom'
import Icon from '../../Icon'
import { IoLocationSharp } from 'react-icons/io5'

interface CardsCase_ {
    box?: 1 | 2,
    content?: 0 | 1 | 2 | 3 | 4,
    text: string
}

function CardsCase({ box = 1, text, content }: CardsCase_) {
    return (
        <div id='CardsCase'>
            <aside>
                <strong>{text}</strong>
            </aside>
            <ul className={`case-box--${box}`}>
                {
                    works[content ? content : box - 1].map((it, idx) => (
                        <Link to={`/details/${it.id}`} className={`${box === 1
                            ? `case-drawer--${idx}`
                            : 'case-drawer-border-top'}`}
                            key={idx}
                        >
                            <li
                                className={` case-li case-li--${box} ${box === 2 ? '' : 'center column'}`}

                            >
                                <div className={`center case-image--${box}`}>
                                    {
                                        box === 1 &&
                                        (
                                            <div style={{ width: '66px', height: '66px', marginBottom: '8px' }}>
                                                <Avatar borderRadius='10px' uid={it.uid} size={10} />
                                            </div>
                                        )

                                    }
                                    {
                                        box === 2 &&
                                        (
                                            <div className='case-banner'>
                                                <Banner
                                                    uid={it.uid}
                                                    wid={it.id}
                                                />
                                            </div>
                                        )
                                    }
                                </div>
                                <div className={`case-infos--${box}`}>
                                    <h5>{box === 1 && it.profession}</h5>
                                    <h5>{box === 2 && it.title}</h5>
                                    <div style={{ height: '40px' }}>
                                        <p className='case-desc-ellipsis'>
                                            {it.description
                                                ? it.description
                                                : 'Acesse esse anúncio para saber mais.'
                                            }
                                        </p>
                                    </div>
                                    <span
                                        className={`center case-location case-location--${box}`}
                                    >
                                        <Icon style={{ marginRight: '4px' }}>
                                            <IoLocationSharp />
                                        </Icon>
                                        {it.city}
                                    </span>
                                </div>
                            </li>
                        </Link>
                    ))
                }
            </ul>
            <SeeButton
                to='search?search='
                text='Ver mais'
            />
        </div>
    )
}

export default CardsCase