import { useLocation } from "react-router-dom";
import Chat from "../Chat";

function ChatPage() {
  const location: any = useLocation();

  return (
    <Chat
      simpleReceiver={location.state}
    />
  )
}

export default ChatPage;
