import { useEffect } from 'react';
import ClearButton from '../../../../Buttons/ClearButton';
import './styles.css'


function AddMachineList({ list = [''], setList }: { list: Array<string>, setList: any }) {

    const handleChange = (value: string, idx: number) => {
        setList((l: any) => {
            let x = l.map((it: string, i: number) => i === idx ? value : it)
            if (x.length < 8 && x[x.length - 1].length) x.push('');
            return x;
        })
    }
    const handleDelete = (idx: number) => {
        setList((l: any) => l.filter((it: any, i: number) => i !== idx))
    }

    useEffect(() => {
        if (list.length < 8 && list[list.length - 1] !== '') setList([...list, ''])
    }, [])

    return (
        <div className='WcDescriptions'>

            <div id='AddMachine'>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    {list.map((it, idx) => (
                        <MachineInput
                            key={idx}
                            value={list[idx]}
                            idx={idx}
                            onChange={handleChange}
                            deleteItem={handleDelete}
                        />
                    ))}
                </div>
            </div>
        </div>
    )
}

const MachineInput = ({ value, onChange, idx, deleteItem }: { value: string, onChange: (e: string, idx: number) => void, idx: number, deleteItem: any }) => (
    <div style={{ position: 'relative' }}>
        <input
            style={{
                width: '96%',
                height: '44px',
                //borderRadius: '20px',
                borderBottom: '1px solid #eee',
                textIndent: '20px'
            }}

            value={value}
            onChange={(e: any) => onChange(e.target.value, idx)}
            placeholder='+ Item da lista'
        />
        {value && <ClearButton handleClear={() => deleteItem(idx)} />}
    </div>
)

export default AddMachineList