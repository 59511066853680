import { useState } from "react"
import { IoAddSharp, IoCall } from "react-icons/io5"
import Icon from "../../Icon"
import ChangePhone from "../../modals/ChangePhone";
import './styles.css'

function AddPhoneNumber({ className, updatePhoneState }: { className: string, updatePhoneState: (n: string) => void }) {

    const [visible, setVisible] = useState<boolean>(false);

    const handleVisible = () => {
        setVisible(true)
    }

    return (
        <div className={`AddPhoneNumberWrapper--${className}`}>
            {visible &&
                <ChangePhone
                    visible={visible}
                    setVisible={setVisible}
                    updatePhoneState={updatePhoneState}
                />
            }
            <button className='AddPhoneNumber center' onClick={handleVisible}>
                <div style={{ marginRight: '10px', position: 'relative' }}>
                    <Icon>
                        <IoCall />
                    </Icon>
                    <div style={{ position: 'absolute', top: '-5px', right: '-5px' }}>
                        <IoAddSharp />
                    </div>
                </div>
                Adicionar número
            </button>
        </div>
    )
}

export default AddPhoneNumber