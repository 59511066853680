
import './styles.css';
import Footer from "../../../components/Footer";
import ThinHeader from '../../../components/Headers/ThinHeader';
import TermsContent from './TermsContent';


export default function TermsOfUse() {
    window.scrollTo(0, 0);

    return (
        <div>
            <ThinHeader />
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ width: "80%", marginBottom: '80px' }}>
                    <TermsContent />
                </div>
            </div>
            <Footer />
        </div>
    );
}