interface SocAcceptBtn_ {
    onClick:any,
}

function SocAcceptBtn({onClick}: SocAcceptBtn_) {
    return (
        <button
            onClick={onClick}
            style={{
                width: '100%',
                backgroundColor: 'var(--green)',
                height: '40px',
                color: 'white',
                borderRadius: '8px',
                textShadow: '-1px 1px 4px #00000060',
                boxShadow: '0 8px 12px -8px var(--hover)',
                fontWeight: 'bold'
            }}
        >
            Aceitar
        </button>
    )
}

export default SocAcceptBtn