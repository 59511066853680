import { TextArea_ } from '../inputs.interfaces';

import '../styles.css';

export function TextArea({
    label, placeholder,
    space, value, style, onChange
}: TextArea_) {
    return (
        <label
            style={{
                marginTop: `${space !== undefined ? space : 18}px`,
            }}
            className='Label'
        >
            <p>{label}&nbsp;</p>
            <textarea
                style={style}
                className='I Input TextArea'
                placeholder={placeholder}
                value={value}
                onChange={(e: any) => onChange(e.target.value)}
            />
        </label>
    )
}