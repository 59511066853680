import ThinHeader from "../components/Headers/ThinHeader"
import LoginContainer from "../components/Containers/LoginContainer"
function Login() {
  return (
    <>
      <ThinHeader />
      <LoginContainer />
    </>
  )
}

export default Login
