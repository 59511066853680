import { generateDaysLeftOfMonth_ } from "../interface";

function getDayName(dateStr: Date) {
  return dateStr.toLocaleDateString("pt-BR", { weekday: 'short' })
}

function getMonthName(dateStr: Date) {
  return dateStr.toLocaleString('pt-BR', { month: 'long' })
}

const generateDaysLeftOfMonth = (): generateDaysLeftOfMonth_[] => {
  const dataObj = new Date();

  const year = dataObj.getFullYear();
  const today = dataObj.getDate();
  const currentMonth = new Date(year, dataObj.getMonth() + 1, 0);
  const nextMonth = new Date(year, dataObj.getMonth() + 2, 0);
  const currentMonthName = getMonthName(currentMonth);
  const nextMonthName = getMonthName(nextMonth);
  const currentMonthNumber = dataObj.getMonth();
  const nextMonthNumber = dataObj.getMonth() + 1;
  const finalDayOfCurrentMonth = currentMonth.getDate();
  const finalDayOfNextMonth = nextMonth.getDate();

  const currentMonthAvalible = [];
  for (let i = today; i <= finalDayOfCurrentMonth; i++) {
    const dayData = new Date(year, currentMonthNumber, i);
    currentMonthAvalible.push({
      name: getDayName(dayData),
      number: dayData.getDate()
    })
  }

  const nextMonthAvalible = [];
  for (let i = 1; i <= finalDayOfNextMonth; i++) {
    const dayData = new Date(year, nextMonthNumber, i);
    nextMonthAvalible.push({
      name: getDayName(dayData),
      number: dayData.getDate()
    })
  }

  return [
    {
      monthName: currentMonthName,
      monthDays: currentMonthAvalible
    },
    {
      monthName: nextMonthName,
      monthDays: nextMonthAvalible
    }

  ];
}

export default generateDaysLeftOfMonth