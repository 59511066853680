import { useEffect, useState } from "react"

//import Select from "../../../Form/Inputs/layouts/Select"
import Avatar from "../../../Images/Avatar";
import { IoCalendarOutline, IoChatboxOutline, IoDocumentTextOutline, IoLocationOutline, IoPersonOutline, IoTimerOutline } from "react-icons/io5";
import api from "../../../../config/connection/axios";
import SocNavBar from "./SocNavBar";
import SocSOLModal from "./SocSOLModal";
import SocAcceptBtn from "./SocAcceptBtn";
import { SocList_ } from "../interface";
import SocDateCalendar from "./SocDateCalendar";


type SocBudgetsSwitcher_ = 'Aguardando respota' | 'Agendados'

interface dataSelected_ {
  data: false | SocList_,
  action: JSX.Element | null
}

function SocServiceOrdersListBudgets() {
  const [linkBuyMercadoPago, setLinkBuyMercadoPago] = useState<string>('')
  const [spinner, setSpinner] = useState<boolean>(false)
  //const [modalVisible, setModalVisible] = useState<boolean>(false)
  const [dataSelected, setDataSelected] = useState<dataSelected_>({ data: false, action: null })
  const [resultList, setResultList] = useState<Array<SocList_>[]>([[], []])

  /*const options: SocBudgetsSwitcher_[] = ['Agendados', 'Aguardando respota'];

  const [hideFinishedOnes, setFOnes] = useState<SocBudgetsSwitcher_>('Agendados')
  */

  const handleModalVisibleWithData = (dat: SocList_ | false, action: JSX.Element) => {
    setDataSelected({
      data: dat,
      action
    })
  }

  useEffect(() => {
    const u = JSON.parse(window.localStorage.getItem('serss-user') || '{}')

    const handleListSubmit = () => {
      api.get(
        '/service/orders/list/responses',
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${u.token}`
          }
        }
      ).then((resp) =>  setResultList(resp.data) )
    }

    handleListSubmit()
  }, [])

  const handleAcceptThisOrder = async (event: Event, so_u_id: string) => {
    event.preventDefault()

    setSpinner(true);

    const u = JSON.parse(window.localStorage.getItem('serss-user') || '{}')

    await api.post(
      '/pix/generate/payment/so',
      {
        so_unique_id: so_u_id
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${u.token}`
        }
      }
    )
      .then(r => {
        setSpinner(false);
        const result = r.data;
        setLinkBuyMercadoPago(result.linkBuyMercadoPago)
      })
  }

  return (
    <>
      <SocNavBar />
      {dataSelected['data'] && <SocSOLModal
        list={dataSelected['data']}
        action={dataSelected['action']}
        setModalVisible={handleModalVisibleWithData}
      />}
      {
        (linkBuyMercadoPago) &&
        <div style={{ width: '100vw', height: '1200px' }}>
          <iframe
            src={linkBuyMercadoPago}
            width="100%"
            height='100%'
            title="link_buy"
          />
        </div>
      }
      {(!linkBuyMercadoPago) && <>
        <div className="center column" style={{ marginBottom: '30px' }}>
          <div style={{ width: '92vw' }}>
            <div
              className='center soc-title-pro'
              style={{

                borderRadius: '100px',
                width: 'fit-content',

                marginBottom: '8px',
              }}
            >

              <strong>Contratar</strong>
            </div>
            <p className='soc-p soc-f-p'>Essas são suas contratações, aqui você encontra a lista de profissionais que você solicitou orçamento.</p>
            <p style={{ marginBottom: '15px', }} className='soc-p soc-f-p'>Você deve analizar e comparar os orçamentos, e depois realizar o pagamento para efetivar a contração. </p>
          </div>

          {/*<div style={{ width: '93vw' }}>
            <Select
              style={{ marginTop: '-50px' }}
              list={options}
              value={hideFinishedOnes}
              onChange={setFOnes}
            />
          </div>*/}
        </div>
        <div className="center">
          <div
            style={{
              width: '92vw',
              display: 'grid',
              gridTemplateColumns: 'auto auto',
              gap: '40px 4px',
            }}
          >
            {
              resultList[0].map((it, key) =>
                <SocBudgetsCard
                  list={it}
                  setModalVisible={handleModalVisibleWithData}
                  handleAcceptThisOrder={handleAcceptThisOrder}
                  paid
                />
              )
            }
            {

              resultList[1].map((it, key) =>
                <SocBudgetsCard
                  list={it}
                  setModalVisible={handleModalVisibleWithData}
                  handleAcceptThisOrder={handleAcceptThisOrder}
                />
              )
            }
          </div>
        </div>
      </>}
    </>
  )
}

interface SocBudgetsCard_ {
  list: SocList_,
  handleAcceptThisOrder: (e: any, so_u_id:string) => void
  setModalVisible: (dat: SocList_, action: JSX.Element) => void,
  paid?: boolean
}

const SocBudgetsCard = ({ paid, list, handleAcceptThisOrder, setModalVisible }: SocBudgetsCard_) => {

  const handleSelectedData = () => {
    if (list.price && !paid) setModalVisible(list, <X1 handleAcceptThisOrder={handleAcceptThisOrder} so_unique_id={list.so_unique_id} />)
    if (!list.price && !paid) setModalVisible(list, <X2 />)
    if (paid) setModalVisible(list, <X3 list={list} />)

  }

  return (<div
    style={{
      minHeight: '200px',
      width: '100%'
    }}
  >
    <div
      style={{
        height: '50px',
        position: 'relative',
        width: '100%',
      }}
      className='card156'
    >
      <div style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        padding: '0 10px 0 10px',
      }}
      >
        <div style={{ width: '30px', height: '30px', paddingRight: '8px' }}>
          <Avatar uid={' '} size={10} />
        </div>
        <div style={{ maxWidth: '100%' }}>
          <strong className="soc-ellipsis" style={{ fontSize: '12px' }}>
            {`${list.work_title}`}
          </strong>
        </div>
      </div>
    </div>
    <button
      onClick={handleSelectedData}
      style={{
        margin: '4px 0 12px 0',
        padding: '20px 0',
        width: '100%',

        display: 'flex',
        flexDirection: 'column',
      }}
      className='card156'
    >
      <div className="center">
        <div style={{ width: 'calc(100% - 20px)' }}>
          <div className='soc-w-bottom' style={{ marginTop: '0', paddingTop: '5px', borderTop: 'none', flexDirection: 'column' }}>
            <div style={{ display: 'flex' }}>
              <span className="center" style={{ marginRight: '4px', fontSize: '13px' }}>
                <IoPersonOutline />
              </span>
              <div>
                <p>{list.prof_name}</p>
              </div>
            </div>
            {
              list.ser_response &&
              <div style={{ marginTop: '10px', backgroundColor: '#00000010', borderRadius: '2px', padding: '5px', display: 'flex', alignItems: 'center' }}>
                <p style={{ marginRight: '6px' }} className="center">
                  <IoChatboxOutline />
                </p>
                <p className='soc-ellipsis' style={{ textAlign: 'start' }}>
                  {list.ser_response}
                </p>
              </div>
            }
          </div>
          <div className='soc-w-bottom' style={{ marginTop: '14px', paddingTop: '14px' }}>
            <div className="center">
              <span className="center" style={{ marginRight: '4px', fontSize: '13px' }}>
                <IoCalendarOutline />
              </span>
              <div style={{ display: 'flex', textAlign: 'left' }}>
                {
                  (list.loc_location || list.loc_place)
                    ? <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <div style={{ display: 'flex' }}>
                        <p>{list.date_week}</p>
                        <p>{list.date_day}</p>
                      </div>
                      <p style={{ fontWeight: 'bold' }}>
                        <i>{list.date_hours}</i>
                      </p>
                    </div>
                    : <p>Endereço a combinar</p>
                }
              </div>
            </div>
          </div>
          <div className='soc-w-bottom' style={{ marginTop: '14px', paddingTop: '14px' }}>
            <div className="center">
              <span className="center" style={{ marginRight: '4px', fontSize: '13px' }}>
                <IoLocationOutline />
              </span>
              <div style={{ display: 'flex', textAlign: 'left' }}>
                {
                  (list.loc_location || list.loc_place)
                    ? <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <p>{list.loc_place}</p>
                      <p style={{ fontWeight: 'bold' }}><i>{list.loc_location}</i></p>
                    </div>
                    : <p>Localização a combinar</p>
                }
              </div>
            </div>
          </div>
          {
            (list.price && !paid)
              ? <div className='soc-w-bottom' style={{ marginTop: '14px', paddingTop: '14px', width: '100%', flexDirection: 'column' }}>
                <div style={{ backgroundColor: '#00ae7410', marginBottom: '10px', borderRadius: '2px', padding: '4px 0' }}>
                  <p style={{ color: 'var(--green)' }}>Aceite para contratar ✓</p>
                </div>
                <div style={{ width: '100%' }}>
                  <div style={{ width: '100%', display: 'flex', marginBottom: '18px', fontSize: '13px' }}>
                    <div className="center">
                      <span className="center" style={{ marginRight: '4px' }}>
                        <IoDocumentTextOutline />
                      </span>
                      <p>Orçamento:</p>
                    </div>
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'flex-end', width: '95%', marginTop: '-10px' }}>
                    <strong >R$ {list.price}</strong>
                  </div>
                </div>
              </div>
              : <div className='soc-w-bottom' style={{ marginTop: '14px', paddingTop: '14px' }}>
                <div className="center">
                  <span className="center" style={{ marginRight: '4px', fontSize: '13px' }}>
                    <IoDocumentTextOutline />
                  </span>
                  <p>Agardando orçamento</p>
                </div>
              </div>
          }
        </div>
      </div>
    </button>
    <div className="center">
      {(list.price && !paid) && <X1 handleAcceptThisOrder={handleAcceptThisOrder} so_unique_id={list.so_unique_id} />}
      {(!list.price && !paid) && <X2 />}
      {paid && <X3 list={list} />}
    </div>
  </div>
  )
}

const X1 = ({ handleAcceptThisOrder, so_unique_id }: { handleAcceptThisOrder: (e:any, a:string)=>void, so_unique_id: string }) => <SocAcceptBtn
  onClick={(e: any) => handleAcceptThisOrder(e, so_unique_id)}
/>

const X2 = () => <div
  style={{
    width: '100%',
    backgroundColor: 'var(--border)',
    height: '40px',
    borderRadius: '8px',
    fontSize: '12px',
    display: 'flex',
    alignItems: 'center',
    color: 'dodgerblue'
  }}
>
  <span className="center" style={{ margin: '0 3px 0 8px' }}>
    <IoTimerOutline size={16} />
  </span>
  <strong>Aguardando resposta</strong>
</div>

const X3 = ({ list }: { list: SocList_ }) => <div style={{ width: '100%', display: 'flex', padding: '8px', borderRadius: '8px', backgroundColor: '#00000015' }}>
  <SocDateCalendar list={list} size={50} font={14} />
</div>


export default SocServiceOrdersListBudgets