import { CheckBox_ } from "../inputs.interfaces";
import '../styles.css'

export default function Checkbox({ label, space, text, value, onChange }: CheckBox_) {
    return (
        <label
            style={{
                marginTop: `${space !== undefined ? space : 0}px`
            }}
            className='Label'
        >
            <p>{label}&nbsp;</p>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <input
                    type='checkbox'
                    value={value}
                    onChange={(e) => onChange(e.target.checked)}

                />
                <span className='Text'>{text}</span>
            </div>
        </label>
    );
}