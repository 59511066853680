import '../ChatHeader/styles.css';

import ChatGoBack from '../ChatGoBack';
import Chat_ from '../../chat-interfaces/chat.interface';
import Avatar from '../../../components/Images/Avatar';
import { screenName_ } from '../../chat-types/screenName.types';


interface ChatChatTop_ {
  receiver: string | null | undefined,
  name?: string
  handleScreen: (
    r: Chat_['simpleReceiver'] | null,
    sn: screenName_
  ) => void
}

function ChatChatTop({ receiver, handleScreen, name }: ChatChatTop_) {


  return (
    <div className='cheader-gradient-wrap' style={{ height: '100px' }}>
      <div className='cheader-gradient'>
        <div

          style={{ position: 'relative', height: 'calc(100% - 20px)', display: 'flex' }}>
          <div
            style={{ height: '100%', margin: '0 18px 0 12px' }}
            className='center'
          >
            <ChatGoBack 
            onClick={()=>handleScreen(null, 'list')}
            icon='back'
            />
          </div>
          <div className='center'>
            <div
              style={{
                position: 'relative',
                height: '46px',
                width: '46px',
                border: '2px solid white',
                borderRadius: '18px'
              }}
            >
              <Avatar uid={receiver} size={14} borderRadius='18px' />
            </div>
            <div
              style={{ marginLeft: '13px' }}
            >
              <strong>
                {name}
              </strong>
              {/*<p style={{ margin: 0, opacity: .85 }}>
                profession
            </p>*/}
            </div>
          </div>

        </div>
      </div>
    </div>
  )
}
export default ChatChatTop;