import { useContext } from "react"
import WindowAlertContext from "../../context/windowAlert.context"

import ImagePicker from "../Form/ImagePicker"

const Album = ({
    premium,
    banners,
    addBanner,
    removeBanner
}: {
    premium: Date | null,
    banners: any,
    addBanner: any,
    removeBanner: any
}) => {

    const windowAlert = useContext(WindowAlertContext);

    return (
        <ul className="center column" style={{ borderRadius: '18px', overflow: 'hidden' }}>
            <div style={{ display: 'grid', gridTemplateColumns: 'auto auto auto' }}>
                {
                    new Array(premium ? 6 : 2).fill(0).map((it, idx) =>
                        <ImagePicker
                            windowAlert={windowAlert?.confirm}
                            banner={banners[idx]}
                            addBanner={addBanner}
                            removeBanner={removeBanner}
                            idx={idx}
                            key={idx}
                        />
                    )
                }
            </div >
        </ul>
    )
}

export default Album;