import { useRef, useState } from 'react'
import './styles.css'

import SubPremium from './layouts/content/SubPremium';
import SafeDiv from '../../SafeDiv';
import { SubB0, SubB1, SubB2 } from './layouts/SubButtons';
import SubEmphasis from './layouts/content/SubEmphasis';
import SubAdvertising from './layouts/content/SubAdvertising';

function SubscriptionContainer({ handleSubSubmit, spinner }: { handleSubSubmit: (event: Event, wid: number, price: number) => void, spinner:boolean }) {

    const [switcher, setSwitcher] = useState<0 | 1 | 2>(0);

    const subScrollRef = useRef(null);


    function scrollWin(end: boolean = false) {
        if (subScrollRef.current) {
            const subScroll: any = subScrollRef.current;
            const subScrollWidth = subScroll.offsetWidth;
            const position = end ? (-subScrollWidth * 3) : (subScrollWidth - 20)
            subScroll.scrollBy(position, 0);
        }
    }

    return (
        <SafeDiv
            center
            column
            style={{
                minHeight: '100vh', backgroundColor: '#d1d0d6',
                paddingBottom: '50px' //remove me
            }}
        >
            <div className='SubTitle center'>
                <div>
                    <h2>QUERO SER PREMIUM!</h2>
                    {/*<p>Nossas soluções são projetadas para criar destaque entre seus concorrentes, e sua escolha só reforça sua dedicação em oferecer o melhor aos seus clientes, estamos constantemente atualizando e melhorando nosso produto, o que significa que você terá acesso a novas ferramentas e recursos para aprimorar seu anúncio.</p>*/}
                    <p>O pagamento é gerenciado pelo <span style={{ textAlign: 'center', width: '22px', height: '16px', position: 'absolute' }}><img style={{ height: '16px', position: 'absolute', top: 0, left: 0 }} src={require('../../../assets/mercado-pago.png')} /></span><strong style={{ color: 'dodgerblue', marginLeft: '28px' }}>Mercado Pago</strong> e a confirmação é imediata, assim que aprovado no aplicativo do seu banco seu <strong>Perfil  Premium</strong> estará disponível.</p>
                </div>
            </div>
            {
                (window.innerWidth >= 960) &&
                <div className='SubscriptionContainer'>
                    <div style={{ display: 'flex' }}>
                        <SubB0
                            setSwitcher={setSwitcher}
                            switcher={switcher}
                        />
                        <SubB1

                            switcher={(switcher)}
                        />
                        <SubB2

                            switcher={switcher}
                        />
                    </div>
                    <div className='SubContentArea'>
                        {
                            switcher === 0 &&
                            <SubPremium
                                handleSubSubmit={handleSubSubmit}
                                spinner={spinner}
                            />
                        }
                        {switcher === 1 && <SubEmphasis />}
                        {switcher === 2 && <SubAdvertising />}
                    </div>
                </div>
            }

            {
                (window.innerWidth < 960) &&
                <div
                    ref={subScrollRef}
                    id='subScroll'
                    style={{
                        overflowX: 'scroll',
                        display: 'grid',
                        gridTemplateColumns: 'auto auto auto',
                        width: '100vw'
                    }}
                >
                    <div className='SubscriptionContainer'>
                        <div style={{ display: 'flex', opacity: 1 }}>
                            <SubB0
                                setSwitcher={setSwitcher}
                                switcher={0}
                            />
                        </div>
                        <SubPremium
                            handleSubSubmit={handleSubSubmit}
                            spinner={spinner}
                            scrollWin={scrollWin}
                        />
                    </div>

                    {/* <div className='SubscriptionContainer'>
                        <div style={{ display: 'flex' }}>
                            <SubB1
                                setSwitcher={setSwitcher}
                                switcher={1} />
                        </div>
                        <SubEmphasis
                            scrollWin={scrollWin}
                        />
                    </div>
                    <div className='SubscriptionContainer'>
                        <div style={{ display: 'flex' }}>
                            <SubB2
                                setSwitcher={setSwitcher}
                                switcher={2}
                            />
                        </div>
                        <SubAdvertising
                            scrollWin={scrollWin}
                        />
                    </div>*/}

                </div>
            }
        </SafeDiv>
    )
}

export default SubscriptionContainer;