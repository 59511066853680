import { IoChevronForward } from "react-icons/io5";
import { Link } from "react-router-dom";
import "./styles.css";

function Profession({
  bki,
  name,
  search,
}: {
  bki: string;
  name: string;
  search: string;
}) {
  return (
    <Link
      to={`/search?search=${search}`}
      id="Profession"
      style={{
        backgroundImage: `url(${bki})`,
      }}
    >
      <div className="Prof-gradient">
        <div className="Prof-name">
          <h2>
            {name}
            <span>
              <IoChevronForward />
            </span>
          </h2>
        </div>
      </div>
    </Link>
  );
}

export default Profession;
