import { IoBusiness, IoCall, IoMedal, IoPeople, IoTrophy } from 'react-icons/io5'
import '../../styles.css'
import SubNextButton from '../SubNextButton'
import SubWritting from './SubWritting';
import { useEffect, useState } from 'react';
import api from '../../../../../config/connection/axios';

function SubEmphasis({ scrollWin }: { scrollWin?: any }) {

  const [works, setWorks] = useState<Array<Object>>([])
  const [selectedWork, setSelectedWork] = useState<{} | any>({});


  useEffect(() => {
    const fetchData = async (uid: string) => {
      if (uid) {
        await api.get(`/cards/belongs/${uid}`)
          .then(r => {
            const result = r.data;
            setWorks(result || [])
            setSelectedWork(result[0] || {})
            //setSpinner(false)
          })
      }
    }

    const u = JSON.parse(window.localStorage.getItem('serss-user') || '{}')
    fetchData(u.uid)
  }, [])

  return (
    <div className='SubContentArea' >
      {
        !!scrollWin &&
        <SubNextButton
          scrollWin={scrollWin}
          text='Patrocinadores'
          icon={<IoBusiness />}
        />
      }
      <SubWritting
        handleSubSubmit={() => { }}
        spinner={false}

        selectedWork={selectedWork}
        setSubVisible={() => { }}

        swWorkTitle={selectedWork.title}
        swTitle='Destaque'
        swList={[
          {
            icon: <IoMedal />,
            text: 'Seja o destaque da cidade'
          },
          {
            icon: <IoTrophy />,
            text: 'Uma aréa exclusiva para você'
          },
          {
            icon: <IoPeople />,
            text: 'Mais visibilidade'
          },
          {
            icon: <IoCall />,
            text: 'Receba mais chamadas'
          }
        ]}
        swPricing={[
          {
            text: ' 3 meses',
            price: ' 129,90 '
          },
          {
            text: ' 6 meses',
            price: ' 139,90 '
          },
          {
            text: ' 12 meses',
            price: ' 149,90 '
          }
        ]}
        swTextings={[
          {
            title: 'Destaque na Multidão:',
            text: <p>Usaremos táticas para garantir que seu serviço se destaque em meio à concorrência, atraindo mais atenção e interesse.</p>
          },
          {
            title: 'Tempo para Crescer:',
            text: <p>Livre-se das complexidades do marketing e tenha mais tempo para expandir suas ofertas e aprimorar a qualidade do que você oferece.</p>
          }
        ]}
      />
    </div>
  )
}

export default SubEmphasis;