import ThinHeader from "../components/Headers/ThinHeader"
import SigninContainer from "../components/Containers/SigninContainer"
import Footer from "../components/Footer"
import { useLocation } from "react-router-dom";
import DoubtsButton from "../components/Buttons/DoubtsButton";

function Signin() {
  window.scrollTo(0, 0);

  const location = useLocation();
  const state: any = location.state;
  const mail = state !== null ? state.mail : ''

  return (
    <>
      <ThinHeader button={<DoubtsButton />} />
      <SigninContainer mail={mail} />
      <Footer />
    </>
  )
}

export default Signin
