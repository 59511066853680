import { IoDiamond } from 'react-icons/io5'

function DiamondAbsolut({ clean = false, icon }: { clean?: boolean, icon?: any }) {
    return (
        <div
            className='center'
            style={{
                position: 'absolute',
                backgroundColor: clean ? 'trnasparent' : '#222D3670',
                color: 'white',
                zIndex: 3,
                top: 10,
                left: 10,
                padding: 10,
                borderRadius: '8px',
            }}
        >
            {icon ? icon : <IoDiamond />}
        </div>
    )
}

export default DiamondAbsolut;