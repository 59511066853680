import SocServiceOrdersListDetails from './SocServiceOrdersListDetails'
import Modal from '../../../Modal'
import SocAcceptBtn from './SocAcceptBtn'
import { SocList_ } from '../interface'
interface SocSOLModal_ {
    setModalVisible: any,
    list: SocList_,
    action: JSX.Element | null
}
function SocSOLModal({ setModalVisible, list, action }: SocSOLModal_) {
    return (
        <Modal
            visible={true}
            setVisible={setModalVisible}
            closeBtn={true}
            padding={'0px 0px'}
            children={
                <div
                    style={{
                        width: '100%',
                        minHeight: '60vw',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between'

                    }}
                >
                    <div>
                        <SocServiceOrdersListDetails
                            list={list}
                        />
                    </div>
                    <div
                        className='center'
                        style={{
                            width: '100%',
                            height: '70px',
                        }}
                    >
                        <div style={{
                            width: '90%'
                        }}>
                            {action}
                        </div>
                    </div>
                </div>
            }
        />
    )
}

export default SocSOLModal