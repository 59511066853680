
import SafeDiv from "../../SafeDiv"
import '../ServiceOrderContainer/styles.css'

import '../../mobile/NavigationBar/styles.css'
import './styles.css'
import '../../../css/card156.css'
import { Outlet } from "react-router-dom"

function ServiceOrdersListContainer() {
    return (
        <SafeDiv>  
            <Outlet />
            <div style={{ height: '110px' }} />
        </SafeDiv>
    )
}



export default ServiceOrdersListContainer