import { IoArrowBack, IoClose } from 'react-icons/io5';
import '../ChatHeader/styles.css'

function ChatGoBack({ onClick, icon }: { onClick: any, icon: 'close' | 'back' }) {
    return (
        <span
            onClick={onClick}
            className={`chatgoback center ${icon === 'close' ? 'chatgoback-close' : ''}`}
        >
            {/*<IoChevronBack size={20} />*/}
            {icon === 'close' && <IoClose size={20} />}
            {icon === 'back' && <IoArrowBack size={20} />}
        </span>
    )
}

export default ChatGoBack