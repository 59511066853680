import ServiceOrdersListContainer from "../components/Containers/ServiceOrdersListContainer"
import ThinHeader from "../components/Headers/ThinHeader"

function ServiceOrdersList() {
    return (
        <>
            <ThinHeader />
            <ServiceOrdersListContainer />
        </>
    )
}

export default ServiceOrdersList