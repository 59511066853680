import { useEffect, useState } from 'react'
//import { IoChevronBack, IoChevronForward } from 'react-icons/io5'
import bannersStorage from '../../../constants/firebase/bannersStorage'
import './styles.css'

interface Banner_ {
    uid?: any,
    wid?: any,
    current?: string,
    type?: 'default' | 'main' | 'details',
    handleOnBannerError?: () => void,
    link?: string,

    idx?: undefined | number,
    hideWrapper?: any,
    premium?: boolean
}
function Banner({ link = '', uid, wid, type = 'default', handleOnBannerError, idx, hideWrapper, current, premium }: Banner_) {

    const [banner, setBanner] = useState<string>('')

    const handleBanner = () => {
        if (uid && wid)
            setBanner(bannersStorage.others(uid, wid, idx))
        else if (link)
            setBanner(link)
    }

    useEffect(() => {
        handleBanner()
    }, [uid, link]);

    return (
        <>
            {/*current === 'card' && <BannerNext side='right' />*/}
            {/*current === 'card' && <BannerNext side='left' />*/}
            <div
                className={`banners-image-wrap center ${type !== 'default' && 'banners-image-wrap-' + type}`}
            >

                {(banner.length) ?
                    <img
                        className={`banners-img ${type !== 'default' && 'banners-img-' + type}`}
                        src={banner}
                    /> : <></>
                }
            </div>
            {(banner.length) ?
                <img
                    className='banners-blur'
                    src={banner}
                    onError={() => {
                        handleOnBannerError && handleOnBannerError()
                        setBanner('')
                        hideWrapper && hideWrapper()
                    }}
                /> : <></>
            }
        </>
    )
}
/*
const BannerNext = ({ side }: { side: 'left' | 'right' }) => {
    return (
        <button
            style={{
                position: 'absolute',
                left: side === 'left' ? 0 : 'auto',
                right: side === 'right' ? 0 : 'auto',
                background: `linear-gradient(to ${side}, transparent, #222d3640)`,
                height: '100%',
                zIndex: 9

            }}
            className='center'
            onClick={(e: Event | any) => e.preventDefault()}
        >
            <span className='center'>
                {
                    side === 'left'
                        ? <IoChevronBack size={24} color='white' />
                        : <IoChevronForward size={24} color='white' />
                }
            </span>
        </button>
    )

}
*/
export default Banner;