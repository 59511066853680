import Banner from "../Banner"
import './styles.css'

interface ImagesCardSlider_ {
    hideWrapper: any,
    setHideWrapper: any,
    user_uid: string,
    id: string,
    alias: string,
    type: 'card' | 'details',
    premium: boolean
}

function ImagesCardSlider({ type, alias, hideWrapper, setHideWrapper, user_uid, id, premium }: ImagesCardSlider_) {
    return (
        <>
            {
                window.innerWidth < 960 &&
                <div className={`wrapper-${alias}`}>
                    {
                        !hideWrapper.zero && <div id={alias} className={`center ${(hideWrapper.one && hideWrapper.two && hideWrapper.three) ? `full-${alias}` : ''}`}>
                            <Banner
                                uid={user_uid}
                                wid={id}
                                current={'card'}
                                premium={premium}
                                type="main"
                                hideWrapper={() => setHideWrapper({ ...hideWrapper, zero: true })}
                            />
                        </div>
                    }
                    {/*
                        type === 'details' && <>
                            {
                                !hideWrapper.one &&
                                <div id={alias} className='center'>
                                    <Banner
                                        uid={user_uid}
                                        wid={id}
                                        current={'card'}
                                        premium={premium}
                                        idx={1}
                                        main={true}
                                        hideWrapper={() => setHideWrapper({ ...hideWrapper, one: true })}

                                    />
                                </div>
                            }
                            {
                                !hideWrapper.two &&
                                <div id={alias} className='center'>
                                    <Banner
                                        uid={user_uid}
                                        wid={id}
                                        current={'card'}
                                        premium={premium}
                                        idx={2}
                                        main={true}
                                        hideWrapper={() => setHideWrapper({ ...hideWrapper, two: true })}

                                    />
                                </div>
                            }
                            {
                                !hideWrapper.three &&
                                <div id={alias} className='center'>
                                    <Banner
                                        uid={user_uid}
                                        wid={id}
                                        current={'card'}
                                        premium={premium}
                                        idx={3}
                                        main={true}
                                        hideWrapper={() => setHideWrapper({ ...hideWrapper, three: true })}

                                    />
                                </div>
                            }
                        </>
                        */}
                </div>
            }
        </>
    )
}

export default ImagesCardSlider;